/**
 * "Возврат заявок"
 */
angular.module('uetp')
    .component('returnClaimsProtocol', {
        templateUrl: 'app/components/procedures/returnClaims/returnClaims.html',
        bindings: {
            procedureData: '<',
            protocolData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', '$uibModal', 'authSrv',
            'httpSrv', 'storageSrv', 'vfSrv', 'needConfirmSrv', 'alertSrv','dictionarySrv', 'dialogSrv', 'protocolSrv','constants',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, $uibModal, authSrv,
                      httpSrv, storageSrv, vfSrv, needConfirmSrv, alertSrv, dictionarySrv, dialogSrv, protocolSrv, constants) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                $scope.constants = constants;
                this.$onInit = function () {
                    $scope.isViewOnly = $stateParams.isViewOnly;
                    $scope.procedure = $scope.ctrl.procedureData.data.result;
                    $scope.protocol = $scope.ctrl.protocolData.data.result;
                    $scope.protocolForm = {form:{}};
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.supplyInfoMessage = false;
                    $scope.operatorSupplyMsg = true;
                    $scope.winnerPaysInfoMessage = false;
                    $scope.winnerPaysMsg = true;

                    $scope.claims ={'exist':false};
                    
                    if (!$scope.isViewOnly) {
                         $scope.auth = authSrv.getAuth();
                      	 $scope.maxSizeAttachments = $scope.auth.maxSizeAttachmentsOrg;
                    	 $scope.maxCountAttachments = $scope.auth.maxCountAttachmentsOrg;
                    	 const customerName = ($scope.procedure.customer.customerType === 'division' && $scope.procedure.customer.parent) ?
                                           $scope.procedure.customer.parent.name : $scope.procedure.customer.name;
                    	 $scope.protocol.document.additionalInfo = "В связи с рассмотрением Комиссией ФАС России по контролю в сфере закупок жалобы на действия (бездействия) " + customerName + " при проведении "
                             + $scope.procedure.type.name + " в электронной форме № " + $scope.procedure.number + " подлежат отмене протоколы. \n" +
                           "Конкурсные заявки, поданные на участие в " + $scope.procedure.type.name + " в электронной форме № " + $scope.procedure.number + " прекращают свое действие с даты отмены протокола и подлежат возврату. \n"+
                           "Настоящим уведомляем, что Ваша заявка, поданная на участие в " + $scope.procedure.type.name + " в электронной форме № " + $scope.procedure.number + ", согласно предписанию ФАС России переведена в статус \"Возвращена заказчиком\". \n"+
                           "Уведомление об отмене протокола по " + $scope.procedure.type.name + " в электронной форме № " + $scope.procedure.number + " размещено на странице закупки.";
                        initSupplyInfoMessageFn();
                        initWinnerPaysInfoMessageFn();
                    }
                };

                $scope.deleteAttach = deleteAttachFn;
                $scope.signSendData = signSendDataFn;
                $scope.uploadSuccess = uploadSuccessFn;
                $scope.downloadFile = downloadFileFn;
                $scope.cancel = cancelFn;
                $scope.fromStageChange = fromStageChangeFn;
                $scope.initSupplyInfoMessage = initSupplyInfoMessageFn;
                $scope.closeSupplyMsg = closeSupplyMsgFn;
                
                function uploadSuccessFn(result) {
                    if (!$scope.protocol.document.attachment) {
                        $scope.protocol.document.attachment = []
                    }
                    angular.forEach(result, function (item) {
                        $scope.protocol.document.attachment.push({
                            fileName: item.fileName,
                            id: item.id
                        });
                    })
                }

                function deleteAttachFn(index) {
                    $scope.protocol.document.attachment.splice(index, 1);
                }

                function cancelFn() {
                    if (_getForm().$dirty) {
                        needConfirmSrv.call(_openProcedure, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        _openProcedure();
                    }
                }

                function _focusFirstInvalid(form) {
                    $scope.submitted = true;
                    if (form.$error && form.$error.required && form.$error.required[0]
                        && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                        desktopSrv.scollTop();
                        form.$error.required[0].$$element[0].focus();
                    }
                    if (form.length > 0 && form[0].$$element && form[0].$$element[0]) {
                        desktopSrv.scollTop();
                        form[0].$$element[0].focus();
                    }
                }

                function _openProcedure() {
                    $state.go('openProcedure', {procType: $scope.procedure.type.id.toLowerCase(), procId: $scope.procedure.id},{location :'replace'});
                }

                $scope.getParticipant = function (apply) {
                    if (apply.participant) {
                        var participant = apply.participant;
                        var inn = '';
                        if (participant.orgUL) {
                            inn = participant.orgUL.inn
                        } else if (participant.orgFL) {
                            inn = participant.orgFL.inn
                        } else if (participant.orgIP) {
                            inn = participant.orgIP.inn
                        } else if (participant.orgNonResident) {
                            inn = participant.orgNonResident.code
                        }
                        return inn + ', ' + participant.name
                    }
                    return 'Участник ' + apply.applicationNumber;
                };
                
                /**
                 * Подпись и отправка
                 */
                function signSendDataFn() {
                    if ($scope.auth.certId || $scope.auth.withoutCertEnabled) {
                        if (validateForm()) {
                            $stateParams.protocolId = $scope.protocol.id;
                            var copy = angular.copy( $scope.protocol);
                            protocolSrv.saveProtocolFn($stateParams, copy, 'RETURN_CLAIMS').then(function (response) {
                                if (response.data.success) {
                                    $stateParams.noticeId = response.data.result[1]; 
                                    protocolSrv.signAndSend($stateParams, copy, $scope.auth.certId).then(function (response) {
                                        if (response.data.success) {
                                            setTimeout(function () {
                                                _openProcedure();
                                            }, 1000);

                                        } else {
                                            $scope.alertObjRejectProcedure = alertSrv.getAlertObj(response);
                                        }
                                        $scope.ajaxActive = false;
                                    }, function (reason) {
                                        if (typeof reason === 'string') {
                                            $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn(reason);
                                        } else {
                                            $scope.alertObjRejectProcedure = alertSrv.getAlertObj(reason);
                                        }
                                        $scope.ajaxActive = false;
                                    });
                                } else {
                                    $scope.alertObjRejectProcedure = alertSrv.getAlertObj(response);
                                }
                            })
                        }
                    } else {
                        $scope.ajaxActive = false;
                        $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                    }
                }

                function downloadFileFn(file) {
                    var url = '/fs/file/' + $scope.procedure.type.id.toLowerCase() + '/' + $scope.procedure.id + '/files/' + file.id;
                    httpSrv.downloadFile(url, file.filename);
                }

                function _getForm() {
                    return $scope.protocolForm.form;
                }

                function validateForm() {
                    if (_getForm().$invalid) {
                        $scope.submitted = true;
                        if (_getForm().$error.required) {
                            $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', _focusFirstInvalid, _getForm());
                        }
                        else {
                            var errorArr = Object.keys(_getForm().$error).length > 0 ? _getForm().$error[Object.keys($scope.protocolForm.form.$error)[0]] : [];
                            if (errorArr.length > 0 && errorArr[0].$$element && errorArr[0].$$element[0]) {
                                $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn('Неправильно заполнено поле. ', 'Перейти', _focusFirstInvalid, errorArr);
                            }
                        }
                        return false;
                    }
                    if (!$scope.protocol.document.lots && $scope.protocol.document.lots.protocolLotApplication && 
                        $scope.protocol.document.lots.protocolLotApplication.protocolLotApplications ) {
                        $scope.alertObjRejectProcedure = alertSrv.getErrorMsgWithBtn("Отсутствует список лотов для переноса даты начала торгов!");
                        return false;
                    }
                    

                    return true;
                }
                
                function fromStageChangeFn(claim) {
                	return $scope.protocol.document.fromStage !== 'stage2' || claim.stage === 'stage2';
                }

                function closeSupplyMsgFn() {
                    $scope.operatorSupplyMsg = false;
                }

                $scope.closeWinnerPaysMsg = function () {
                    $scope.winnerPaysMsg = false;
                }

                function initSupplyInfoMessageFn() {
                    if(!$scope.isViewOnly && $scope.cabType() === 'organizer' && !['prequalification', 'unlimitedprequalification'].includes($scope.procedure.type.id) && $scope.operatorSupplyMsg &&
                        $scope.protocol.document.fromStage !== 'stage2' && $scope.procedure.lots.some(lot => lot.applicationDepositNeeded === 'operatorSupply' && $scope.protocol.claimLots && $scope.protocol.claimLots[lot.ordinalNumber] > 0)) {
                        $scope.supplyInfoMessage = 'При публикации уведомления о возврате заявок будет прекращено блокирование денежных средств, заблокированных на счете Оператора КОМИТА ЭТП для обеспечения заявок. Блокирование будет прекращено для всех участников, подавших заявки.';
                    } else $scope.supplyInfoMessage = false;
                }

                function initWinnerPaysInfoMessageFn() {
                    if(!$scope.isViewOnly && $scope.cabType() === 'organizer' && $scope.winnerPaysMsg &&
                        $scope.protocol.document.fromStage !== 'stage2' && $scope.procedure.lots.some(lot => lot?.winnerPayment>0 && $scope.protocol.claimLots && $scope.protocol.claimLots[lot.ordinalNumber] > 0)) {
                        $scope.winnerPaysInfoMessage = 'При публикации уведомления о возврате заявок будет прекращено блокирование денежных средств, заблокированных на счете Оператора КОМИТА ЭТП для обеспечения вознаграждения оператора, взимаемого с победителя. Блокирование будет прекращено для всех участников, подавших заявки.';
                    } else $scope.winnerPaysMessage = false;
                }
            }
        ]
    });

