/**
 * @author dmitrys
 */
angular.module('uetp').directive("inputfiles", [function () {
    return {
        restrict: 'E',
        templateUrl: 'app/directives/inputfiles/inputfiles.html',
        scope: {
            myfile: "@",
            url: '@',
            callback: "=",
            type: "@",
            bankGuaranteeType: "@",//передаем тип вложения через отдельный параметр, чтобы не смешивать наши типы с банковскими
            extraParam: "=", // Дополнительные данные для callback
            additionalFields: "=",
            succsesMsg: "@",
            fileMultiple: "@",
            maxFileSize: "@",//todo будет удалено в 1.21
            alertObj: '=',
            disabled: '@',
            fnDisabled: '&',
            attachmentsLength: '@',//todo будет удалено в 1.21
            streaming:'@',//загрузка отдельными запросами
            onFilenameError: '&',
            maxsize: '@',
            fileNameReg: '@',
            onPreUpload: '=',
            onError: '&',
            onSizeError: '&',
            onSizeEmpty: '&?'
        },
        transclude: true,
        link: function (scope, element, attributes) {

            scope.msie = typeof window.document.documentMode === "number"; //is IE
            var file = element.querySelectorAll('#file');

            file[0].disabled = (scope.disabled === "true");

            if (scope.fileMultiple == 'true' && !scope.msie) {
                file[0].setAttribute("multiple", true);
            }
            if(!scope.streaming)
                scope.streaming = false;
            element.bind("click", function (event) {
                if(scope.fnDisabled) {
                    file[0].disabled = scope.fnDisabled();
                    if(file[0].disabled) {
                        event.stopPropagation();
                        event.preventDefault();
                    }
                }
            })
            element.bind("change", function (changeEvent) {

                var files = changeEvent.target.files,
                    errorMsg = '',
                    maxSizeAttachments = 5242880,
                    maxCountAttachments,
                    auth = scope.authSrv.getAuth(),
                    maxFileSize = scope.maxFileSize,
                    maxMultiUpload = auth.maxmultiupload,
                    allFiles = scope.attachmentsLength * 1 + files.length;
                if (files.length === 0) {
                    return;
                }
                // добавлена обработка ошибок, старое поведение пока оставляем
                if(file[0].files[0].size===0 && scope.onSizeEmpty){
                      scope.$apply(function () {
                          scope.onSizeEmpty({ files: file[0].files });
                      });
                      this.value='';
                      return;
                }

                if(scope.maxsize && scope.maxsize < file[0].files[0].size){
                      scope.$apply(function () {
                          scope.onSizeError({ files: file[0].files, maxsize: scope.maxsize });
                      });
                      this.value='';
                      return;
                }

                if(scope.fileNameReg && !file[0].files[0].name.match(new RegExp(scope.fileNameReg, "i"))){
                    scope.$apply(function () {
                      scope.onFilenameError({ files: file[0].files });
                    });
                    this.value='';
                    return;
                }

                if(scope.onPreUpload) {
                    scope.$apply(function () {
                        scope.onPreUpload(function (files) {
                            scope.myfile = files ?? file[0].files;
                            scope.submit(scope.myfile);
                            changeEvent.target.value = '';
                        }, changeEvent);
                    });
                    return;
                }
                
                if (auth.orgRole === 'participant') {
                    maxSizeAttachments = auth.maxSizeAttachments;
                    maxCountAttachments = auth.maxCountAttachments
                } else if (auth.orgRole === 'organizer') {
                    maxSizeAttachments = auth.maxSizeAttachmentsOrg;
                    maxCountAttachments = auth.maxCountAttachmentsOrg
                }
                if (scope.type === 'regExplanation') {
                    maxSizeAttachments = auth.maxSizeAttachmentsForExplanationRequest;
                }
                if (scope.type === 'bankGuarantee') {
                    maxSizeAttachments = auth.maxSizeAttachmentsBguarantee;
                }
                if (scope.type === 'bankGuaranteeLoko') {
                    maxSizeAttachments = auth.maxSizeAttachmentsBguaranteeLoko;
                }
                if (scope.type === 'bankGuaranteeLokoChat') {
                    maxSizeAttachments = auth.maxSizeAttachmentsBguaranteeLoko;
                }
                if (scope.type === 'bankGuaranteeDecisionLoko') {
                    maxSizeAttachments = auth.maxSizeAttachmentsBguaranteeLoko;
                }
                if (scope.type === 'rfGuarantee') {
                    maxSizeAttachments = auth.maxSizeAttachmentsRfGuarantee;
                }
                if (scope.type === 'eisXml') {
                    maxSizeAttachments = auth.maxSizeEisXml;
                }
                if (scope.type === 'regExplanationOrg'||scope.type === 'answerExplanationOrg') {
                    maxSizeAttachments = auth.maxSizeAttachments;
                    maxCountAttachments = auth.maxCountAttachments
                }
                if (scope.type === 'purchaseContract'||scope.type === 'ContractRelated') {
                    maxSizeAttachments = auth.maxSizeAttachmentsContract;
                    maxCountAttachments = auth.maxCountAttachmentsContract
                }

                if (files.length > maxMultiUpload) {
                    errorMsg = 'За один раз можно добавить не больше ' + maxMultiUpload + ' вложений. Вы выбрали ' + files.length
                } else if ((scope.type === 'procedures' || scope.type === 'purchaseContract'||scope.type === 'ContractRelated') && allFiles > maxCountAttachments) {
                    errorMsg = 'Невозможно добавить выбранные файлы, так как общее количество вложений превысит максимально разрешенное - ' + maxCountAttachments + '.'
                } else {
                    angular.forEach(files, function (file) {
                        if (file.size === 0) {
                            errorMsg = 'Файл ' + file.name + ' не добавлен.<br />' +
                                'Невозможно добавить пустой файл.';
                            file.err = errorMsg;
                        }
                        if (maxSizeAttachments < file.size && scope.type !== 'noticeImport') {
                            if (scope.type === 'procedures' || scope.type === 'answerExplanation'
                                || scope.type === 'accreditation_other' || scope.type === 'accreditation_maxSum'
                                || scope.type === 'accreditation_chief' || scope.type === 'accreditation_proxy'
                                || scope.type === 'accreditation_founder' || scope.type === 'accreditation_egrul'
                                || scope.type === 'rejectProcedure'|| scope.type === 'regExplanation'
                                || scope.type === 'cancelLots' || scope.type === 'additionalProtocol'
                                || scope.type === 'regExplanationOrg'||scope.type === 'answerExplanationOrg' 
                                || scope.type === 'purchaseContract'||scope.type === 'ContractRelated'
                                || scope.type === 'eisXml') {
                                errorMsg = 'Файл ' + file.name + ' не добавлен.<br />' +
                                    'Превышен максимально разрешенный размер файла ('
                                    + (maxSizeAttachments / 1048576).toFixed(2) + ' Мб)!<br />' +
                                    'Размер файла ' + file.name + ': ' + (file.size / 1048576).toFixed(2) + ' Мб.';
                                file.err = errorMsg;
                            }
                            else {
                                errorMsg = 'Файл ' + file.name + ' не добавлен.<br />' +
                                    'Максимальный размер файла ('
                                    + (maxSizeAttachments / 1048576).toFixed(2) + ' Мб)!<br />'
                            }

                        }
                        if (maxFileSize < file.size) {
                            errorMsg = 'Файл ' + file.name + ' не добавлен.<br />' +
                                'Превышен максимально разрешенный размер файла ('
                                + (maxFileSize / 1048576).toFixed(2) + ' Мб)!<br />' +
                                'Размер файла ' + file.name + ': ' + (file.size / 1048576).toFixed(2) + ' Мб.';
                            file.err = errorMsg;
                        }
                        if (file.name && file.name.toLowerCase().endsWith('.exe')) {
                            errorMsg = 'Файл ' + file.name + ' не добавлен.<br />' +
                                'Добавление файлов с расширением *.exe запрещено!';
                            file.err = errorMsg;
                        }
                    });
                }
                var allError = true;
                var allErrorMsg = '';
                angular.forEach(files, function (file) {
                    if(!file.err) allError = false;
                    else allErrorMsg +=file.err;
                });
                if ((errorMsg && files.length === 1) || (errorMsg && allFiles > maxCountAttachments) || files.length > maxMultiUpload) {
                    scope.cancelSubmit(errorMsg);
                }else if (allError) {
                    scope.cancelSubmit(allErrorMsg);
                } else {
                    scope.myfile = files;
                    scope.submit(scope.myfile);
                }
                //сбрасываем файл
                changeEvent.target.value = '';
            });

            if ('accept' in attributes) {
                attributes.$observe('accept', function uploadButtonAcceptObserve(value) {
                file.attr('accept', value);
              });
            }

        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'upload', 'alertSrv', 'authSrv',
            function ($scope, $rootScope, $state, $stateParams, upload, alertSrv, authSrv) {
                $scope.authSrv = authSrv;
                var errorArr;
                function alertObjEvent(data) {
                    if (typeof $scope.alertObj === 'function') {
                        $scope.alertObj(data)
                    } else {
                        $scope.alertObj = data;
                    }
                }
                $scope.submit = function (files) {
                    errorArr = [];
                    var successArr = [];
                    angular.forEach(files, function (file) {
                        if (file.err) {
                            errorArr.push(file);
                        } else {
                            successArr.push(file);
                        }
                    });

                    var data = null;
                    if ($scope.msie) {
                        //загрузка одного файла
                        data = {aFile: files[0]};
                        if($scope.additionalFields) {
                            const val = $scope.additionalFields();
                            Object.keys(val).forEach(k=>data.append(k, val[k]));
                        }
                        loadFile(data).then(
                            function successCallback(response) {
                                if (response.data.success) {
                                    if ($scope.callback) {
                                        if ($scope.type === 'certificate') {
                                            $scope.callback(response, $scope.type);
                                        } else if ($scope.type === 'bankGuarantee' || $scope.type === 'rfGuarantee' || $scope.type === 'bankGuaranteeLoko'){
                                            //передаем тип банковского вложения
                                            $scope.callback(response.data.result, $scope.bankGuaranteeType, $scope.extraParam);
                                        } else if ($scope.type === 'nomenclatureImport'){
                                            $scope.callback(response.data);
                                        } else {
                                            $scope.callback(response.data.result, $scope.type);
                                        }
                                    }
                                }
                                initMess(response);
                            },
                            function errorCallback(response) {
                                alertObjEvent(alertSrv.getAlertObj(response));
                            }
                        );
                    } else {
                        //Поочередная загрузка отдельными запросами
                        if($scope.streaming && $scope.streaming !== "false") {
                            new Promise(function (resolve) {
                                var responseArray = [];
                                var fileCount = successArr.length;
                                angular.forEach(successArr, function (file) {
                                    data = {aFile: file};
                                    loadFile(data).then(
                                        function successCallback(response) {
                                            if (response.data.success) {
                                                if ($scope.callback) {
                                                    if ($scope.type === 'certificate') {
                                                        $scope.callback(response, $scope.type);
                                                    } else if ($scope.type === 'bankGuarantee' || $scope.type === 'rfGuarantee' || $scope.type === 'bankGuaranteeLoko'){
                                                        //передаем тип банковского вложения
                                                        $scope.callback(response.data.result, $scope.bankGuaranteeType, $scope.extraParam);
                                                    } else if ($scope.type === 'nomenclatureImport'){
                                                        $scope.callback(response.data);
                                                    } else {
                                                        $scope.callback(response.data.result, $scope.type);
                                                    }
                                                }
                                            } else if(response.data.errorCode==="001"){
                                                $state.go('home');
                                            }
                                            responseArray.push(response);
                                            checkCollectResponses();
                                        },
                                        function errorCallback() {
                                            //responseArray.push(response);
                                            checkCollectResponses();
                                        }
                                    );
                                });
                                /*
                                * Отправили все файлы -> выходим
                                * */
                                function checkCollectResponses() {
                                    fileCount--;
                                    if(fileCount===0){
                                        resolve(responseArray);
                                    }
                                }
                            }).then(function successCallback(response) {
                                let resp = response[0];
                                if (response.length > 1)
                                    for (let i = 1; i < response.length; i++) {
                                        resp.data.result.push(response[i].data.result);
                                    }
                                initMess(resp);
                            });
                        }else{
                            //мульти загрузка одним запросом
                            var fd = new FormData();
                            angular.forEach(successArr, function (file) {
                                fd.append('file[]', file);
                            });
                            if($scope.additionalFields) {
                                const val = $scope.additionalFields();
                                Object.keys(val).forEach(k=>fd.append(k, val[k]));
                            }
                            data = fd;
                            loadFile(data).then(
                                function successCallback(response) {
                                    if (response.data.success) {
                                        if ($scope.callback) {
                                            if ($scope.type === 'certificate') {
                                                $scope.callback(response, $scope.type);
                                            } else if ($scope.type === 'bankGuarantee' || $scope.type === 'rfGuarantee' || $scope.type === 'bankGuaranteeLoko') {
                                                //передаем тип банковского вложения
                                                $scope.callback(response.data.result, $scope.bankGuaranteeType, $scope.extraParam);
                                            } else if (['nomenclatureImport','mchdImport'].includes($scope.type)) {
                                                $scope.callback(response.data);
                                            } else {
                                                $scope.callback(response.data.result, $scope.type);
                                            }
                                        }
                                    } else if ($scope.callback && $scope.type === 'mchdImport') {
                                        $scope.callback(response.data);
                                    }
                                    initMess(response);
                                },
                                function errorCallback(response) {
                                    alertObjEvent(alertSrv.getAlertObj(response));
                                }
                            );
                        }
                    }
                    function loadFile(data) {
                        var url = $scope.url.startsWith('/') ? ('rest'
                            + $scope.url) : ('rest/' + $scope.url);
                        return upload({
                            url: url,
                            method: 'POST',
                            headers: {
                                'Content-Type': 'multipart/form-data; charset=utf-8'
                            },
                            isFile:true,
                            data: data
                        });
                    }
                    function initMess(response) {
                        var message = null, haveError = false;
                        if (response.data && response.data.success) {
                            if ($scope.type === 'certificate') {
                                if(response.data.errorCode==='406_3')
                                    message = response.data.errorMessage;
                                else
                                    message = 'Сертификат добавлен!<br/>Для завершения нажмите кнопку Сохранить изменения.';
                            } else if ($scope.type === 'noticeImport') {
                                message = '';
                                if (response.data.result.length) {
                                    angular.forEach(response.data.result, function (res) {
                                        message += (res + '<br/>');
                                    });
                                }
                            } else if ($scope.type === 'importBuhReport' && response.data.message) {
                                message = response.data.message;
                            } else if ($scope.type === 'nomenclatureImport') {
                                if(response.data.errorMessage) {
                                    message = response.data.errorMessage;
                                    haveError = true;
                                } else {
                                    return;
                                }
                            } else if ($scope.type === 'mchdImport') {
                                return;
                            } else if (response.data.result && response.data.result.length === 1) {
                                message = 'Файл добавлен';
                                if (errorArr.length) {
                                    angular.forEach(errorArr, function (file) {
                                        message += '<br/><br/>' + file.err;
                                        haveError = true;
                                    });
                                }
                            } else if (response.data.result && response.data.result.length != 1){
                                message = 'Добавлено файлов: ' + response.data.result.length;
                                if (errorArr.length) {
                                    angular.forEach(errorArr, function (file) {
                                        message += '<br/><br/>' + file.err;
                                        haveError = true;
                                    });
                                }
                            }
                        } else if (response.data && !response.data.success && $scope.type === 'mchdImport') {
                            return;
                        }
                        if (haveError) {
                            alertObjEvent(alertSrv.getErrorMsg($scope.succsesMsg || message))
                        } else {
                            alertObjEvent(alertSrv.getAlertObj(response, $scope.succsesMsg || message || response.data.message));
                        }
                    }
                };
                $scope.cancelSubmit = function (msg) {
                    alertObjEvent(alertSrv.getErrorMsg(msg));
                    $scope.$apply()
                }
            }]
    }
}]);
