angular.module('uetp').component('paymentOrder', {
    templateUrl: 'app/components/paymentOrder/paymentOrder.html',
    bindings: {
        paymentOrderData: '<'
    },
    controller: ['$scope', '$rootScope', '$state', 'constants', '$stateParams', 'httpSrv', 'paymentOrderSrv', 'invoiceSrv',
        'alertSrv', '$filter','desktopSrv','dictionarySrv', 'profileSrv', 'dialogSrv',
        function ($scope, $rootScope, $state, constants, $stateParams, httpSrv, paymentOrderSrv, invoiceSrv, alertSrv, $filter, desktopSrv, dictionarySrv, profileSrv, dialogSrv) {
            this.$onInit = function () {
                //todo вернуть, заглушка для двух рестов
                //$scope.paymentOrder = this.paymentOrderData.paymentOrder;
                //загулшка
                if(!this.paymentOrderData.paymentOrder)
                    $scope.paymentOrder = this.paymentOrderData;
                else
                    $scope.paymentOrder = this.paymentOrderData.paymentOrder;
                $scope.paymentOrderExt = {};
                $scope.paymentOrderExtExist = false;
                $scope.isUlBudget = isUlBudgetFn($scope.paymentOrder.recipient);
                $scope.isFlBudget = isFlBudgetFn($scope.paymentOrder.recipient);

                $scope.paymentOrderExt = this.paymentOrderData.extension || {};
                $scope.paymentOrderExtExist = !!this.paymentOrderData.extension;

                $scope.nds = this.paymentOrderData.nds;
                $scope.coveringLetter = $scope.paymentOrder.coveringLetters;

                $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                $scope.downloadFile = httpSrv.downloadFile;
                $scope.downloadCoveringLetter = downloadCoveringLetterFn;
                $scope.paymentOrderService = paymentOrderSrv;
                $scope.invoiceSrv = invoiceSrv;
                $scope.initInc = initIncFn;
                $scope.successPay = successPayFn;
                $scope.processRefillorRefund = processRefillorRefundFn;
                $scope.processDelayProcessing = processDelayProcessingFn;
                $scope.processRejectOrReply = processRejectOrReplyFn;
                $scope.processAcceptPayment = processAcceptPaymentFn;
                $scope.manualProcessing = manualProcessingFn;
                $scope.selectProcessType = selectProcessTypeFn;
                $scope.createRefundPay = createRefundPayFn;
                $scope.closeAlert = closeAlertFn;
                $scope.personalAccountsList = personalAccountsListFn;
                $scope.showParticipantInvoices = showParticipantInvoicesFn;
                $scope.getParticipantInnList = getParticipantInnListFn;
                $scope.incrementInvoice = incrementInvoiceFn;
                $scope.checkForManualHandle = checkForManualHandleFn;
                $scope.setSummNDS = setSummNDSFn;
                $scope.nextStep = nextStepFn;
                $scope.getRequestStatus = getRequestStatusFn;
                $scope.confirmPayment = confirmPaymentFn;
                $scope.checkClaimedLetter = checkClaimedLetterFn;
                $scope.createCoveringLetter = createCoveringLetterFn;
                $scope.showDialogRefundOperation = showDialogRefundOperationFn;
                $scope.loadOperationsInfo = loadOperationsInfoFn;
                $scope.changeSort = changeSortFn;
                $scope.clickRow = clickRowFn;
                $scope.goBack = goBackFn;
                $scope.accChangeHandler = accChangeHandlerFn;
                $scope.bikChangeHandler = bikChangeHandlerFn;
                $scope.recipientReqChangeHandler = recipientReqChangeHandlerFn;
                $scope.refreshDict = refreshDictFn;
                $scope.checkInvoices = checkInvoicesFn;
                $scope.checkNDSPercent = checkNDSPercentFn;
                $scope.submitted = true;
                $scope.countSelectedInvoice = 0;
                $scope.diplayedInfo = {};
                $scope.showInfo = info => $scope.diplayedInfo[info] = !$scope.diplayedInfo[info];
                $scope.changeOkato = () => {
                    $scope.modelView.oktmo = $scope.modelView.oktmo === '0' ? null : '0';
                };
                $scope.selectAllInvoice = selectAllInvoiceFn;

                $scope.modelView = {
                    typeProcess: '', // manualTransfer, fillRefund, other
                    choiceSelect: false, // индикатор отображения выбора обработки
                    btnSuccess: false, // индикатор для отображения кнопок подтвердить и отменить
                    refundSumm: false, // индикатор что п/п сменился на возврат ДС
                    visibleStatus: true, // индикатор отображения статуса
                    processAcc: {}, // новый счет (manualTransfer)
                    isErrorAcc: '', // ошибка по новому счету с сервера
                    participantInnSelect: '', // выбранный инн участника
                    invoiceSum: 0, // сумма выбраных счетов
                    invoicesAllSelected: false, // общий чекбокс для выделения счетов
                    acc:null,// счет (coveringLetter)
                    clickConfirm:false, // кликнули подтвердить (fix даблкликов)
                    showBtn:true//отображение кнопок управления (Далле, Назад, ОК, Подтверждаю и тд)
                };
                $scope.alerts = {
                    alertPanel: '',
                    alertPanelText: ''
                };
                $scope.paginator = {
                    itemsPerPage: 20,
                    page: 0
                };
                $scope.disableForm = true;
                $scope.personalAccounts = [];
                $scope.invoiceList = [];
                $scope.participantInnList = [];
                $scope.constants = constants;
                $scope.process = false; // обработка запроса
                $scope.state = $state.current.name;
                $scope.form = {mainForm: {}};
                $scope.cabType = desktopSrv.getCabType;
                $scope.downloadFilePopup = function (url) {
                    $scope.downloadFile(url)
                    setTimeout(function () {
                        $state.reload();
                    }, 1000)
                };

                $scope.showManualHandle = checkForManualHandleFn($scope.paymentOrder);
                $scope.showCreateCoveringLetter = checkForCreateCoveringLetter($scope.paymentOrder);
                $scope.showPersonalAccountList = function(search, addData) {
                   personalAccountsListFn(search, addData);
                }
                loadOperationsInfoFn();
                loadPayerStatuses();
            };

            function _clearModelView(choiceSelect) {
                $scope.modelView = {
                    typeProcess: '', // manualTransfer, fillRefund, other
                    choiceSelect: choiceSelect, // индикатор отображения выбора обработки
                    btnSuccess: false, // индикатор для отображения кнопок подтвердить и отменить
                    refundSumm: false, // индикатор что п/п сменился на возврат ДС
                    visibleStatus: true, // индикатор отображения статуса
                    processAcc: {}, // новый счет (manualTransfer)
                    isErrorAcc: '', // ошибка по новому счету с сервера
                    participantInnSelect: '', // выбранный инн участника
                    invoiceSum: 0, // сумма выбраных счетов
                    invoicesAllSelected: false, // общий чекбокс для выделения счетов
                    showNDS:false, // когда ндс не определены в п/п, открываем выбор
                    acc:null,// счет (coveringLetter)
                    clickConfirm:false, // кликнули подтвердить (fix даблкликов)
                    showBtn:true//отображение кнопок управления (Далле, Назад, ОК, Подтверждаю и тд)
                };
            }

            /**
             * Выбераем типа обработки
             */
            function selectProcessTypeFn() {
                $scope.modelView.btnSuccess = false;
                $scope.modelView.showBtn = true;
                delete $scope.modelView.summNDS;
                delete $scope.modelView.procNDS;
                delete $scope.modelView.comment;
                delete $scope.modelView.showNDS;
                delete $scope.modelView.participantInnSelect;
                delete $scope.modelView.dealParticipant;

                $scope.modelView.processAcc = [];
                $scope.invoiceList = [];
                if($scope.modelView.typeProcess === 'manualTransfer' && $scope.paymentOrder.accountCode){
                    personalAccountsListFn($scope.paymentOrder.accountCode).then( function(result) {
                        if (result.length > 0) {
                            $scope.modelView.processAcc = result[0];
                            getOrganizationInfoByAccountFn($scope.paymentOrder.accountCode).then(function (pResult) {
                                if(pResult.data.success){
                                    $scope.modelView.processAcc.owner = pResult.data.result;
                                }
                            });
                        }
                    });
                    nextStepFn(2);
                }else {
                    $scope.modelView.processAcc = null;
                }
                if ($scope.modelView.typeProcess === 'fillRefund') {
                    $scope.modelView.otherInfo = 'Возврат ошибочно поступивших денежных средств в размере ' + $scope.paymentOrder.summ + ' руб.';
                    nextStepFn(2);
                } else if ($scope.modelView.typeProcess === 'linkedInvoice' || $scope.modelView.typeProcess === 'linkedInvoiceByEmail') {
                    getParticipantInnListFn($scope.paymentOrder.payer.inn, true);
                }else if($scope.modelView.typeProcess === 'coveringLetter' && $scope.paymentOrder.accountCode){
                    $state.go('coveringLetterAcc', {id:$scope.paymentOrder.id, acc:$scope.paymentOrder.accountCode});
                }else if($scope.modelView.typeProcess === 'checkAndReSend'){
                    const data = angular.copy($scope.paymentOrder);
                    $scope.modelView.purpose = data.purpose;
                }else if($scope.modelView.typeProcess === 'fixRecipient') {
                    const data = angular.copy($scope.paymentOrder);
                    $scope.modelView.dealParticipant = data.recipient;
                    if(!$scope.modelView.dealParticipant.kpp) {
                        $scope.modelView.dealParticipant.kpp = 0;
                    }

                    $scope.modelView.dealParticipantPurpose = data.purpose;
                    if($scope.paymentOrderExtExist || $scope.isUlBudget || $scope.isFlBudget)
                        prepareFixRecipientExtView();

                    let param = {
                        search: $scope.modelView.dealParticipant.bicBank,
                        itemsPerPage: 1000,
                        page: 0
                    };
                    dictionarySrv.loadPaginatedDict($scope, 'BIK', param, 'rBIK').then(function (res) {
                        if (res.items.length > 0) {
                            $scope.modelView.dealParticipant.bank = res ? `${res.items[0].participant}${res.items[0].locality ? ', ' + res.items[0].locality : ''}` : $scope.modelView.dealParticipant.bank;
                            $scope.form.mainForm.dealParticipantBicBank.$setValidity("bicBank", true);
                            $scope.modelView.dealParticipant.bankAcc = res.items[0].accounts;
                            delete $scope.bicNotFound;
                        } else {
                            $scope.form.mainForm.dealParticipantBicBank.$setValidity("bicBank", false);
                            $scope.bicNotFound = 'Банк с реквизитами ' + $scope.modelView.dealParticipant.bicBank + ' ' + $scope.modelView.dealParticipant.bank + ' отсутствует в актуальном справочнике БИК'
                            $scope.modelView.dealParticipant.bicBank = "";
                        }
                    });
                }
            }

            function prepareFixRecipientExtView(){
                $scope.modelView.paymentOrderExt = angular.copy($scope.paymentOrderExt);
                $scope.modelView.oktmo = $scope.paymentOrderExt.oktmo;

                if($scope.isUlBudget) {
                    $scope.modelView.paymentOrderExt.payerStatus = $scope.modelView.paymentOrderExt.payerStatus || '08';
                    $scope.modelView.oktmo = $scope.modelView.oktmo || '0';
                    $scope.modelView.paymentOrderExt.basisCode = $scope.modelView.paymentOrderExt.basisCode || '0';
                    $scope.modelView.paymentOrderExt.periodCode = $scope.modelView.paymentOrderExt.periodCode || '0';
                    $scope.modelView.paymentOrderExt.numberCode = $scope.modelView.paymentOrderExt.numberCode || '0';
                    $scope.modelView.paymentOrderExt.dateCode = $scope.modelView.paymentOrderExt.dateCode || '0';
                }

                if($scope.isFlBudget)
                    $scope.modelView.paymentOrderExt.purposeCode = $scope.modelView.paymentOrderExt.purposeCode || '2';
            }

            function checkForManualHandleFn(paymentOrder){
                var value = false;
                if((['02','03','08','06','12','99'].indexOf(paymentOrder.processResult) !== -1 || (paymentOrder.processResult == '09')) && initIncFn() > 0){
                    value = true;
                }
                return value;
            }

            function checkForCreateCoveringLetter(paymentOrder) {
                var value = false;
                if($scope.cabType() === 'accountant' && ['02','03','12'].indexOf(paymentOrder.processResult) !== -1 && !$scope.checkClaimedLetter()){
                    value = true;
                }
                return value;
            }
            /*
            * Установить поле сумма ндс по выбраному проценту
            * */
            function setSummNDSFn(summ) {
                if($scope.modelView.procNDS) {
                    $scope.modelView.summNDS = parseFloat(parseFloat(summ * $scope.modelView.procNDS.value / $scope.modelView.procNDS.subvalue).toFixed(2));
                    if ($scope.modelView.procNDS.value) {
                        $scope.modelView.otherInfo = 'Возврат ошибочно поступивших денежных средств в размере ' + $scope.paymentOrder.summ + ' руб. в т.ч. НДС ' + $scope.modelView.procNDS.value + '% ' + $scope.modelView.summNDS + ' руб.';
                    } else {
                        $scope.modelView.otherInfo = 'Возврат ошибочно поступивших денежных средств в размере ' + $scope.paymentOrder.summ + ' руб. Без налога (НДС).'
                    }
                }
            }
            /*
            * След шаг при ручной обработке, для кнопок "далее" если требуется больше одного действия
            * */
            function nextStepFn(step) {
                if (['manualTransfer','fillRefund','linkedInvoice', 'linkedInvoiceByEmail'].includes($scope.modelView.typeProcess)) {

                    const isLinkedInvoice = ['linkedInvoice', 'linkedInvoiceByEmail'].includes($scope.modelView.typeProcess);
                    if (step === 2) {
                        //проверяем ндс в платежке (0, 18, 20)
                        if ($scope.nds === null) {
                            //если нет отображаем поля для выбора ндс
                            $scope.modelView.showNDS = true;
                            //отображаем alert панель о том, что не смогли однозначно определить НДС у п/п
                            $scope.showNdsAlert = true;
                        } else {
                            //если есть ндс и выбран 1 счет - пропускаем
                            if(isLinkedInvoice
                                && $scope.countSelectedInvoice===1) {
                                $scope.successPay(true);
                            }else{
                                //отображаем выбор ндс
                                $scope.modelView.showNDS = true;
                                //отображаем alert панель о том, что не смогли однозначно определить НДС у п/п
                                //для операций "привязать счет" сообщение отображается также при выборе нескольких
                                //счетов для привязки
                                $scope.showNdsAlert = (!$scope.nds && $scope.nds !== 0) || isLinkedInvoice;

                            }
                        }

                        //проставляем все поля
                        const subm = $scope.nds === 18 ? 118 : 120;
                        $scope.modelView.otherInfo = 'Возврат ошибочно поступивших денежных средств в размере ' + $scope.paymentOrder.summ + ' руб.'
                        if($scope.nds || $scope.nds===0) {
                            if(!$scope.modelView.procNDS)  $scope.modelView.procNDS = {};
                            $scope.modelView.procNDS.value = $scope.nds;
                            $scope.modelView.procNDS.subvalue = subm;
                            
                            if ($scope.nds === 0) {
                                $scope.modelView.summNDS = 0;
                                $scope.modelView.otherInfo += ' Без налога (НДС).';
                                $scope.modelView.procNDS.name = 'без НДС';
                            } else {
                                $scope.modelView.summNDS = isLinkedInvoice ?
                                    parseFloat(parseFloat(($scope.paymentOrder.summ - $scope.modelView.invoiceSum) * $scope.nds / subm).toFixed(2)) :
                                    parseFloat(parseFloat($scope.paymentOrder.summ * $scope.nds / subm).toFixed(2))
                                $scope.modelView.otherInfo += ' в т.ч. НДС ' + $scope.nds + '% ' + $scope.modelView.summNDS + ' руб.';
                                $scope.modelView.procNDS.name = $scope.nds + '%';
                            }
                        }
                    }
                }
            }

            /**
             * Начать выбор типа обработки
             */
            function initIncFn() {
                $scope.typeProcess = [];
                if ($scope.paymentOrder.processResult === '08') {
                    $scope.typeProcess = [
                        {type: 'canceledPaymentOrder', name: 'Отклонить п/п'},
                        {type: 'reSend', name: 'Отправить повторно'}
                    ]

                } else if ($scope.paymentOrder.processResult === '06') {
                    $scope.typeProcess = [
                        {type: 'changeSystemSend', name: 'Пометить как отправленное из другой системы'}
                    ];
                } else if($scope.paymentOrder.processResult === '09' && $scope.paymentOrder.type === 'outgoing') {
                        $scope.typeProcess = [
                            {type: 'canceledPaymentOrder', name: 'Отклонить п/п'},
                            {type: 'checkAndReSend', name: 'Проверить назначение и отправить п/п'},
                            {type: 'reSend', name: 'Отправить повторно'},
                            {type: 'fixRecipient', name: 'Исправить реквизиты получателя и отправить п/п'}
                        ];
                } else if ($scope.paymentOrder.processResult === '99') {
                    if($scope.paymentOrder.type === 'outgoing') {
                        $scope.typeProcess = [
                            {type: 'canceledPaymentOrder', name: 'Отклонить п/п'},
                            {type: 'checkAndReSend', name: 'Проверить назначение и отправить п/п'},
                            {type: 'reSend', name: 'Отправить повторно'},
                            {type: 'fixRecipient', name: 'Исправить реквизиты получателя и отправить п/п'}
                        ];
                    } else {
                        $scope.typeProcess.push({type: 'reSend', name: 'Отправить повторно'});
                    }
                } else if ($scope.paymentOrder.processResult === '02'){
                    if(!$scope.checkClaimedLetter()){
                        $scope.typeProcess.push({type: 'manualTransfer', name: 'Ручное зачисление на ЛС'});
                        $scope.typeProcess.push({type: 'fillRefund', name: 'Возврат д/с (возврат в банк)'});
                        $scope.typeProcess.push({type: 'acceptPayment', name: 'Принять п/п'});
                        $scope.typeProcess.push({type: 'coveringLetter', name: 'Запросить уточнение платежа'});
                    }
                } else if ($scope.paymentOrder.processResult === '03'){
                    $scope.typeProcess = [
                        {type: 'delayProcessing', name: 'Отложить обработку п/п'}
                    ];

                    if(!$scope.checkClaimedLetter()){
                        $scope.typeProcess.push({type: 'manualTransfer', name: 'Ручное зачисление на ЛС'});
                        $scope.typeProcess.push({type: 'fillRefund', name: 'Возврат д/с (возврат в банк)'});
                        $scope.typeProcess.push({type: 'linkedInvoice', name: 'Привязать счет'});
                        $scope.typeProcess.push({type: 'linkedInvoiceByEmail', name: 'Привязка п/п подтвержденного по почте к счету'});
                        $scope.typeProcess.push({type: 'coveringLetter', name: 'Запросить уточнение платежа'});
                    }
                } else if ($scope.paymentOrder.processResult === '12'){
                    //Статус "Отложено"
                    if(!$scope.checkClaimedLetter()){
                        $scope.typeProcess.push({type: 'manualTransfer', name: 'Ручное зачисление на ЛС'});
                        $scope.typeProcess.push({type: 'fillRefund', name: 'Возврат д/с (возврат в банк)'});
                        $scope.typeProcess.push({type: 'linkedInvoice', name: 'Привязать счет'});
                        $scope.typeProcess.push({type: 'linkedInvoiceByEmail', name: 'Привязка п/п подтвержденного по почте к счету'});
                        $scope.typeProcess.push({type: 'coveringLetter', name: 'Запросить уточнение платежа'});
                    }
                }

                return $scope.typeProcess.length;
            }
            /*
            * Проверка есть ли запросы со статусом запрошено
            * */
            function checkClaimedLetterFn() {
                var value = false;
                angular.forEach($scope.coveringLetter, function (item) {
                    if (item.state==='claimed') {
                        value =  true;
                        return;
                    }
                });
                return value;
            }
            /**
             * Открыть кнопки для подтверждения двейстия пользователя
             * @param result
             */
            function successPayFn(result) {
                closeAlertFn();
                $scope.modelView.btnSuccess = result;
                if (!result) {
                    _clearModelView(true);
                }
            }

            /**
             * Создание п/п на возврат денежных средств (fillRefund)
             */
            function createRefundPayFn() {
                $scope.modelView = {
                    typeProcess: '',
                    choicePanel: false,
                    btnSuccess: false,
                    refundSumm: true,
                    visibleStatus: false,
                    showBtn:true
                };

                function changePayer() {
                    var payer = angular.copy($scope.paymentOrder.payer);
                    $scope.paymentOrder.payer = angular.copy($scope.paymentOrder.recipient);
                    $scope.paymentOrder.recipient = payer;
                }

                changePayer();
            }

            /**
             * Ручное зачисление на ЛС или Иное
             */
            function processRefillorRefundFn() {
                $scope.modelView.clickConfirm = true;
                var partUrl = '';
                var data = angular.copy($scope.paymentOrder);
                var PaymentOrderData = {};
                    PaymentOrderData.paymentOrder = angular.copy($scope.paymentOrder);
                if ($scope.modelView.typeProcess === 'manualTransfer') {
                    //обернули в paymentOrder
                    PaymentOrderData.processAcc = $scope.modelView.processAcc.id;
                    PaymentOrderData.ndsSumm = $scope.modelView.summNDS;
                    PaymentOrderData.nds = $scope.modelView.procNDS.value;
                    PaymentOrderData.paymentOrder.comment = $scope.modelView.comment;
                    if(PaymentOrderData.paymentOrder.coveringLetters)
                        delete PaymentOrderData.paymentOrder.coveringLetters;
                    if(PaymentOrderData.paymentOrder.accountCode)
                        delete PaymentOrderData.paymentOrder.accountCode;
                    data = PaymentOrderData;
                    partUrl = 'processRefill/'
                } else if ($scope.modelView.typeProcess === 'fillRefund') {
                    PaymentOrderData.ndsSumm = $scope.modelView.summNDS;
                    PaymentOrderData.nds = $scope.modelView.procNDS.value;
                    PaymentOrderData.paymentOrder.purpose = $scope.modelView.otherInfo;
                    data = PaymentOrderData;
                    if(data.coveringLetters)
                        delete data.coveringLetters;
                    if(data.accountCode)
                        delete data.accountCode;
                    partUrl = 'processRefund/'
                } else {
                    console.log('Не указан тип обработки!')
                }
                httpSrv.http({
                    method: 'POST',
                    url: '/accounts/paymentOrder/' + partUrl,
                    data: data
                }).then(function successCallback(response) {
                    if (response.data.success) {
                        _reloadForm();
                    } else {
                        // 415 - Указанный лицевой счет не существует
                        if (response.data.errorCode === '415') {
                            $scope.form.mainForm.numacc.$setValidity('numacc', false);
                            initAlertPanel(response.data.message, 'errorRequest');
                        }
                        // 800 - Для этого платежного поручения есть запрос подтверждения платежа
                        if (response.data.errorCode === '800') {
                            initAlertPanel(response.data.message, 'errorRequest');
                        }
                        // 802 - Для этого платежного поручения операция недоступна
                        if (response.data.errorCode === '802') {
                            initAlertPanel(response.data.message, 'errorRequest');
                            $scope.modelView.showBtn = false;
                        }
                        $scope.modelView.clickConfirm = false;
                    }
                }, function errorCallback(response) {
                    console.log(response);
                    $scope.modelView.clickConfirm = false;
                });

            }
            /*
            * Отложить обработку п/п
            * */
            function processDelayProcessingFn(message) {
                $scope.modelView.clickConfirm = true;
                $scope.process = true;
                var data = {message: message};
                httpSrv.http({
                    method: 'POST',
                    url: '/accounts/paymentOrder/delayProcessing/' + $scope.paymentOrder.id,
                    data: data
                }).then(function successCallback(response) {
                    if (response.data.success) {
                        _reloadForm();
                    } else {
                        // 802 - Для этого платежного поручения операция недоступна
                        if (response.data.errorCode === '802') {
                            initAlertPanel(response.data.message, 'errorRequest');
                            $scope.modelView.showBtn = false;
                        }
                        $scope.modelView.clickConfirm = false;
                        console.log(response);
                    }
                }, function errorCallback(response) {
                    $scope.process = false;
                    $scope.modelView.clickConfirm = false;
                    console.log(response);
                });
            }
            /**
             * Ручная обработка
             */
            function manualProcessingFn() {
                $scope.modelView.clickConfirm = true;
                $scope.process = true;
                var url = '';
                var data = {};
                if ($scope.modelView.typeProcess === 'changeSystemSend') {
                    data.comment = $scope.modelView.otherInfo;
                    data.id = $scope.paymentOrder.id;
                    url = '/accounts/paymentOrder/processHand/'
                } else if ($scope.modelView.typeProcess === 'linkedInvoice' || $scope.modelView.typeProcess === 'linkedInvoiceByEmail') {
                    data.paymentOrder = {};
                    data.paymentOrder.id = $scope.paymentOrder.id;
                    data.invoices = _getCheckedInvoicesTable();
                    data.ndsSumm = $scope.modelView.summNDS;
                    data.nds = $scope.modelView.procNDS.value;
                    url = '/accounts/tariff/attachInvoices';
                } else {
                    console.log('Не указан тип обработки!')
                }
                httpSrv.http({
                    method: 'POST',
                    url: url,
                    data: data
                }).then(function successCallback(response) {
                    if (response.data.success) {
                        _reloadForm();
                    } else {
                        $scope.modelView.clickConfirm = false;
                        // 415 - Указанный лицевой счет не существует
                        if (response.data.errorCode === '415') {
                            $scope.form.mainForm.numacc.$setValidity('numacc', false);
                            initAlertPanel(response.data.message, 'errorRequest');
                        }
                        // 800 - Для этого платежного поручения есть запрос подтверждения платежа
                        if (response.data.errorCode === '800') {
                            initAlertPanel(response.data.message, 'errorRequest');
                        }
                        // 801 - Платежное поручение уже привязано к счету. Повторная привязка не возможна.
                        if (response.data.errorCode === '801') {
                            $scope.showManualHandle = false;
                            $scope.modelView.choiceSelect = false;
                            $scope.modelView.typeProcess = 'manualHandleError';
                            initAlertPanel(response.data.message, 'errorRequest');
                        }
                        // 802 - Для этого платежного поручения операция недоступна
                        if (response.data.errorCode === '802') {
                            initAlertPanel(response.data.message, 'errorRequest');
                            $scope.modelView.showBtn = false;
                        }
                    }
                }, function errorCallback(response) {
                    $scope.process = false;
                    $scope.modelView.clickConfirm = false;
                    console.log(response);
                });

            }
            function _getCheckedInvoicesTable() {
                var result = [];
                angular.forEach($scope.invoiceList, function (invoice) {
                    if (invoice.selectedInvoice) {
                        result.push(invoice.id);
                    }
                });
                return result;
            }

            function processRejectOrReplyFn() {
                $scope.modelView.clickConfirm = true;
                let partUrl = '';
                let data = angular.copy($scope.paymentOrder);
                if(data.coveringLetters)
                    delete data.coveringLetters;
                if(data.accountCode)
                    delete data.accountCode;
                if ($scope.modelView.typeProcess === 'canceledPaymentOrder') {
                    data.comment = $scope.modelView.otherInfo;
                    partUrl = 'processReject/';
                } else if ($scope.modelView.typeProcess === 'reSend') {
                    partUrl = 'processReply/';
                } else if($scope.modelView.typeProcess === 'checkAndReSend'){
                    data.purpose = $scope.modelView.purpose;
                    partUrl = 'checkDestinationAndSend/';
                } else if($scope.modelView.typeProcess === 'fixRecipient'){
                    data = {};
                    data.paymentOrder = angular.copy($scope.paymentOrder);
                    data.paymentOrder.purpose = $scope.modelView.dealParticipantPurpose;
                    data.paymentOrder.recipient = $scope.modelView.dealParticipant;
                    if($scope.paymentOrderExtExist || $scope.isUlBudget || $scope.isFlBudget)
                        data.extension = preparePaymentOrderExt();
                    partUrl = 'fixRecipientAndSend/';
                }
                httpSrv.http({
                    method: 'POST',
                    url: '/accounts/paymentOrder/' + partUrl,
                    data: data
                }).then(function successCallback(response) {
                    if (response.data.success) {
                        _reloadForm()
                    }else{
                        // 802 - Для этого платежного поручения операция недоступна
                        if (response.data.errorCode === '802') {
                            initAlertPanel(response.data.message, 'errorRequest');
                            $scope.modelView.showBtn = false;
                        }
                        $scope.modelView.clickConfirm = false;
                    }
                }, function errorCallback(response) {
                    $scope.modelView.clickConfirm = false;
                    console.log(response);
                });

            }
            /*
            * Подвтердить "Принять п/п"
            * */
            function processAcceptPaymentFn() {
                $scope.modelView.clickConfirm = true;
                httpSrv.http({
                    method: 'GET',
                    url: '/accounts/paymentOrder/acceptPayment/'+$scope.paymentOrder.id
                }).then(function successCallback(response) {
                    if (response.data.success) {
                        _reloadForm()
                    }else{
                        $scope.modelView.clickConfirm = false;
                        // 800 - Для этого платежного поручения есть запрос подтверждения платежа
                        if (response.data.errorCode === '800') {
                            initAlertPanel(response.data.message, 'errorRequest');
                        }
                        // 802 - Для этого платежного поручения операция недоступна
                        if (response.data.errorCode === '802') {
                            initAlertPanel(response.data.message, 'errorRequest');
                            $scope.modelView.showBtn = false;
                        }
                    }
                }, function errorCallback(response) {
                    $scope.modelView.clickConfirm = false;
                    console.log(response);
                });
            }
            function _reloadForm() {
                $state.transitionTo($state.current, $stateParams, {
                    reload: true, inherit: false, notify: true
                });
            }

            function closeAlertFn() {
                $scope.alerts.alertPanel = '';
                $scope.alerts.alertPanelText = '';
            }

            function initAlertPanel(text, type) {
                $scope.alerts.alertPanel = type;
                $scope.alerts.alertPanelText = text;
            }

            function personalAccountsListFn(search, addData) {
                return new Promise(function (resolve, reject) {
                    if (search) {
                        var itemsPerPage = 10;
                        $scope.accountsPagination = {
                            itemsPerPage: itemsPerPage,
                            page: addData ? ($scope.accountsPagination.page + 1) : 0,
                            search: search
                        };
                        if($scope.paymentOrder.operator && $scope.paymentOrder.operator.accountType)
                            $scope.accountsPagination.searchByField = {accountType: $scope.paymentOrder.operator.accountType};
                        if(addData && $scope.accountTotal < ($scope.accountsPagination.page) * itemsPerPage)
                            return;

                        httpSrv.http({
                            method: 'POST',
                            url: '/accounts/paymentOrder/personalAccountsList',
                            data: $scope.accountsPagination
                        }).then(function successCallback(response) {
                            if (response.data.success){
                                $scope.accountTotal = response.data.total;
                                return updateAccountOwnersInfo(response.data.items)
                            }
                            else
                                reject(response);
                        }).then(function successCallback(response) {
                            if(!addData)
                                $scope.personalAccounts = [];
                            Array.prototype.push.apply($scope.personalAccounts, response);
                            resolve(response);
                        }, function errorCallback(response) {
                            reject(response);
                            console.log(response);
                        });
                    } else {
                        $scope.personalAccounts = [];
                        resolve($scope.personalAccounts)
                    }
                })
            }

            function updateAccountOwnersInfo(accounts){
                if(!accounts)
                    return;
                var orgIds=accounts.map(function(account){
                    return account.ownerId;
                });
                return paymentOrderSrv.getOrganizationsInfoByList(orgIds).then(function successCallback(response) {
                    if (response.data.success) {
                        var orgMap = response.data.result;
                        accounts.forEach(function(account){
                            account.owner = orgMap[account.ownerId] || {};
                        });
                        return accounts;
                    }
                }, function errorCallback(response) {
                    console.log(response);
                });
            }

            function getOrganizationInfoByAccountFn(accountId) {
                var url = '/auth/user/getOrganizationInfoByAccount/' + accountId;
                return httpSrv.http({
                    method: 'GET',
                    url: url
                })
            }

            /**
             * Показать список участников
             * @param inn
             * @param isSetDefault - выставить по умолчанию значение
             */
            function getParticipantInnListFn(inn, isSetDefault) {
                if (inn !== "0") {

                    if (!inn) {
                        inn = $scope.paymentOrder.payer.inn;
                        isSetDefault = false;
                    }

                    if (inn) {
                        paymentOrderSrv.getParticipantByInn(inn).then(function successCallback(response) {
                            if (response.data.success) {
                                if (isSetDefault) {
                                    if (response.data.items && response.data.items.length > 0) {
                                        var list = $filter('filter')(response.data.items, {'inn': inn});
                                        $scope.modelView.participantInnSelect = (list && list.length > 0) ? angular.copy(list[0]) : '';
                                    } else {
                                        $scope.participantInnList = []
                                    }
                                } else {
                                    $scope.participantInnList = response.data.items;
                                }

                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    } else {
                        $scope.participantInnList = []
                    }
                }
            }

            /**
             * Показать список счетов у участника для ручной обработки типа "Привязать счет"
             * @param participant
             */
            function showParticipantInvoicesFn(participant) {
                if (participant) {
                    paymentOrderSrv.getInvoicesParticipantByOrder($scope.paymentOrder, participant, $scope.modelView.typeProcess).then(function successCallback(response) {
                        if (response.data.success) {
                            _clearInvoicesLogic();
                            $scope.invoiceList = response.data.result;
                            if (response.data.result.length === 0) {
                                $scope.alertObjInvoices = alertSrv.getErrorMsg('У данного участника не найдены счета!');
                            }
                        } else {
                            $scope.invoiceList = [];
                            $scope.alertObjInvoices = alertSrv.getErrorMsg('Произошла ошибка получения счетов!');
                        }
                    }, function errorCallback(response) {
                        $scope.invoiceList = [];
                        $scope.alertObjInvoices = alertSrv.getErrorMsg('Произошла ошибка получения счетов!');
                        console.log(response);
                    });
                } else {
                    _clearInvoicesLogic();
                    $scope.invoiceList = []
                }
            }

            /**
             * Заполнение суммы списания по выбору счетов для ручной обработки типа "Привязать счет"
             */
            function incrementInvoiceFn() {
                checkInvoicesFn();
                $scope.modelView.invoiceSum = 0;
                $scope.countSelectedInvoice = 0;
                //сбрасываем и вычисляем заново ставку НДС исходя из выбранных счетов
                $scope.nds = $scope.paymentOrder.nds || 0;
                angular.forEach($scope.invoiceList, function (inv) {
                    if (inv.selectedInvoice) {
                        $scope.modelView.invoiceSum += (inv.paymentOrderSum - inv.sumOrderPayments);
                        $scope.countSelectedInvoice++;
                    }

                    //Если НДС по тарифу выбранного счета больше текущего, устанавливаем НДС по тарифу из счета
                    if(inv.selectedInvoice && $scope.nds < inv.tariff.nds) {
                        $scope.nds = inv.tariff.nds;
                    }
                })
            }
            function _clearInvoicesLogic() {
                $scope.modelView.invoiceSum = 0;
                $scope.modelView.invoicesAllSelected = false;
            }

            function checkInvoicesFn() {
                const arr = $scope.invoiceList.filter(item => item.selectedInvoice);
                $scope.modelView.invoicesAllSelected = arr.length === $scope.invoiceList.length;
            }

            function checkNDSPercentFn(percent) {
                return percent === 18 || percent === 20;
            }

            /* икнока в заголовке*/
            function getRequestStatusFn(items) {
                return paymentOrderSrv.getRequestStatus(items,  $scope.cabType)
            }
            /*
            * Создать сопроводительное письмо
            * */
            function createCoveringLetterFn() {
                if($scope.paymentOrder.accountCode){
                    $state.go('coveringLetterAcc', {id:$scope.paymentOrder.id, acc:$scope.paymentOrder.accountCode});
                }else{
                    $state.go('coveringLetter', {id:$scope.paymentOrder.id});
                }
            }
            function clickRowFn(row) {
                //проверка на тип пользователя: оператор/бухгалтер/поддержка
                var isOperator = $scope.cabType() === 'admincab' ||
                                    $scope.cabType() === 'accountant' ||
                                    $scope.cabType() === 'inspector' ||
                                    $scope.cabType() === 'support';
                $state.go('invoiceView', {id: row.id, isOperator:isOperator,params: {clearConfig: true}});
            }
            /*
            * Создать ответ на запрос
            * */
            function confirmPaymentFn(data) {
                $state.go('confirmPayment',{id:data.id,paymentOrderId:$scope.paymentOrder.id});
            }
            function goBackFn() {
                if ($stateParams.params && $stateParams.params.participantId) {
                    $state.go('participantView', {id: $stateParams.params.participantId, activeProfileTab: 'paymentOrderView'});
                    return;
                }
                if ($scope.cabType() === 'participant') {
                    $state.go('tariffsMenu', {activeProfileTab: $stateParams.params && $stateParams.params.activeTab ? $stateParams.params.activeTab : 'paymentOrdersTable'});
                }else{
                    history.back();
                }
            }
            function accChangeHandlerFn(selectObj) {
                var selected = selectObj.$select.selected;
                if(selected) {
                    personalAccountsListFn(selected.id).then(function (result) {
                        if (result.length > 0) {
                            $scope.modelView.processAcc = result[0];
                            getOrganizationInfoByAccountFn(selected.id).then(function (pResult) {
                                if (pResult.data.success) {
                                    $scope.modelView.processAcc.owner = pResult.data.result;
                                }
                            });
                        }
                    });
                }
            }

            function showDialogRefundOperationFn(operation) {
                profileSrv.showDialogRefundOperation($scope, operation);
            }

            function loadOperationsInfoFn() {
                let filter = {};
                filter["paymentOrder.id"] = $scope.paymentOrder.id;
                filter.accountId = $scope.paymentOrder.accountCode;
                $scope.paginator.searchByField = filter;
                profileSrv.loadCashFlowInfo(angular.copy($scope.paginator)).then(function (response) {
                    if (!response.data.success) {
                        dialogSrv.showDialogMsg($scope, response.data.message);
                        return;
                    }
                    $scope.operations = response.data.items;
                    $scope.totalPages = Math.ceil(response.data.total / $scope.paginator.itemsPerPage);
                });
            }

            function downloadCoveringLetterFn(coveringLetter) {
                let type = (coveringLetter.type === 'specify') ? "Уточнение назначения платежа"
                    : (coveringLetter.type === 'third') ? "Подтверждение платежа" : "Зачисление на ЛС платежа";
                let fileName =type+'_'+$scope.paymentOrder.numb+'_от_'+$filter('date')($scope.paymentOrder.orderDate, 'dd.MM.yyyy')+'.zip';
                $scope.downloadFile('/accounts/coveringLetters/downloadCoveringLetter/'+coveringLetter.id,fileName);
            }

            function changeSortFn($event) {
                let th = $event.currentTarget,
                    orderBy = th.getAttribute('st-sort'),
                    orderAsc = th.classList.contains("sortAsc");

                [].forEach.call(document.querySelectorAll(".sortColumn"), function (column) {
                    column.classList.remove("sortDesc");
                    column.classList.remove("sortAsc");
                });
                orderAsc = !orderAsc;
                th.classList.add(orderAsc ? "sortAsc" : "sortDesc");
                if (orderBy) {
                    $scope.paginator.page = 0;
                    $scope.paginator.orderBy = orderBy;
                    $scope.paginator.orderAsc = orderAsc;
                    loadOperationsInfoFn();
                }
            }

            /**
             * Заполнить Номер корреспондентского счета банка из выбранного банка
             */
            function bikChangeHandlerFn(selectObj, dataObj) {
                const selected = selectObj.$select.selected;
                dataObj.bank = selected ? selected.participant : "";
                dataObj.bankAcc = selected ? selected.accounts : "";

                if(!selected) return;
                
                const param = {
                    search: dataObj.bicBank,
                    itemsPerPage: 1000,
                    page: 0
                };

                dictionarySrv.loadPaginatedDict($scope, 'BIK', param, 'rBIK').then(function (res) {
                    if (res.items.length > 0) {
                        dataObj.bank = res ? `${res.items[0].participant}${res.items[0].locality ? ', ' + res.items[0].locality : ''}` : dataObj.bank;
                        $scope.form.mainForm.dealParticipantBicBank.$setValidity("bicBank", true);
                        delete $scope.bicNotFound;
                    } else {
                        $scope.form.mainForm.dealParticipantBicBank.$setValidity("bicBank", false);
                        $scope.bicNotFound = 'Банк с реквизитами ' + dataObj.bicBank + ' ' + dataObj.bank + ' отсутствует в актуальном справочнике БИК'
                    }
                });
            }

            function recipientReqChangeHandlerFn(dataObj) {
                $scope.isUlBudget = isUlBudgetFn(dataObj);
                $scope.isFlBudget = isFlBudgetFn(dataObj);
                if($scope.paymentOrderExtExist || $scope.isUlBudget || $scope.isFlBudget)
                    prepareFixRecipientExtView();
            }

            function preparePaymentOrderExt(){
                let result = angular.copy($scope.modelView.paymentOrderExt);
                result.oktmo = $scope.modelView.oktmo;
                if(!$scope.isUlBudget){
                    ['payerStatus', 'kbk', 'oktmo', 'basisCode', 'periodCode', 'numberCode', 'dateCode', 'typeCode'].forEach(prop => {
                        delete result[prop];
                    });
                }
                if(!$scope.isFlBudget)
                    delete result.purposeCode;
                return result;
            }

            /**
             * Обновление справочника
             * @param object - тип справочника
             * @param value - введенные значения
             * @param refreshScrolling - обновление данных при прокрутке
             */
            function refreshDictFn(object, value, refreshScrolling, needToAdd, searchValue) {
                const searchByField = {};
                if(!value) {
                    value = searchValue;
                }
                if (object === 'rFIAS_AREA') {
                    searchByField.nonEmptyField = 'oktmo';
                    searchByField.livestatus = '1';
                }
                dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, searchByField, needToAdd)
            }

            function isUlBudgetFn(recipient) {
                return recipient.inn && recipient.acc &&
                        recipient.inn.length === 10  && (recipient.acc.startsWith('405')||recipient.acc.startsWith('406'));
            }

            function isFlBudgetFn(recipient) {
                return recipient.inn && recipient.acc &&
                        recipient.inn.length === 12  && recipient.acc.startsWith('40817');
            }

            function loadPayerStatuses() {
                httpSrv.http({
                    method: 'GET',
                    url: '/accounts/paymentOrder/getPayerStatuses',
                }).then(function successCallback(response) {
                    if (response.data.success) {
                        $scope.payerStatuses = response.data.result;
                    }
                }, function errorCallback(response) {
                    $scope.process = false;
                    console.log(response);
                });
            }

            /*
            * Выбрать все счета
            * */
            function selectAllInvoiceFn(value) {
                angular.forEach($scope.invoiceList, function (data) {
                    data.selectedInvoice = value;
                });
                $scope.checkInvoices();
            }
        }
    ]
}).filter('paymentOrderSum', function () {
    return function (input) {
        var sumStr = '' + input;
        sumStr = sumStr.replace(".", "-").replace(",", "-");
        if (sumStr.indexOf("-") === -1)
            sumStr += " =";
        else {
            var fraction = sumStr.substring(sumStr.indexOf("-") + 1);
            if (fraction.length === 1) {
                fraction += "0";
            }
            sumStr = sumStr.substring(0, sumStr.indexOf("-") + 1) + fraction;
        }
        return sumStr;
    };
});
