/**
 * @author: sergeyu
 * Сервис для компонентов Процедуры
 */
angular.module('uetp').service('proceduresSrv', ['$state', '$stateParams', 'httpSrv', '$rootScope', 'cryptography', 'desktopSrv','dictionarySrv','propertiesService',
    function ($state, $stateParams, httpSrv, $rootScope, cryptography, desktopSrv,dictionarySrv,propertiesService) {
        var service = this,
            tableSort = {},
            procedureData = {},
            stateViewObj = { // вывод статусов на клиент. Статусы отличаются от приходящих
                protocols: {
                    'canceled': 'Недействующая',
                    '??': 'Недействующая',
                    'published': 'Действующая',
                    'incorrect': 'Отменена'
                },
                docs: {
                    'canceled': 'Отменена',
                    '??': 'Недействующая',
                    'published': 'Действующая'
                },
                expls: {
                    '??': 'Недействующая',
                    'published': 'Действующая'
                },
                notifications: {
                    'canceled': 'Отменена',
                    '??': 'Недействующая',
                    'published': 'Действующая'
                }
            };
        const eis_states = [
            {obrabotka_eis: 'Подготовка к отправке'},
            {to_send_eis: 'Ожидает отправки в ЕИС'},
            {accepted_eis: 'Отправлено в ЕИС'},
            {partly_accepted_eis: 'Отправлено в ЕИС'},
            {nerazmeshen_eis: 'Ошибка'},
            {utvergden: 'Не настроена интеграция с ЕИС'},
            {deleted_eis: 'Удалено в ЕИС'},
            {published_eis: 'Опубликовано в ЕИС'},
            {failure: 'Ошибка'}
        ];
        this.getEisState = function(state) {
            const s = eis_states.find(s=>s[state]);
            return s ? s[state]:'';
        }

        /**
         * Получить Участника по его ID
         */
        this.getProcById = function (type, id) {

            var url;
            var fz = dictionarySrv.getFzbyProcType(type);
            if(fz==='44'){
                /**
                 * 44 ФЗ
                 */
                url = '/' + type + '/' + id;
            }else  if(fz==='223'){
                /**
                 * 223 ФЗ
                 */
                url = '/' + type + '/' + id
            }
            return httpSrv.http({
                method: 'GET',
                url: url
            });
        };
        /**
         * Получить Участника по его ID
         */
        this.getProcEISByNumber = function (id, host, scope) {
            var searchByField  = {};
                searchByField.id = id;
                var param = {
                    itemsPerPage: 20,
                    orderAsc: false,
                    orderBy: "publish_date_first",
                    page: 0,
                    searchByField: searchByField
                };
            return this.searchPurchases(scope, param)
        };

        this.setProcedureData = function (data) {
            procedureData = data;
        };
        this.getProcedureData = function () {
            return procedureData;
        };
        this.setTableSort = setTableSortFn;
        this.openLogin = openLoginFn;
        this.getChangeViewState = function (statusObj, type) {
            if (statusObj) {
                var result = stateViewObj[type][statusObj.id];
                if (result) {
                    return result;
                }
                return statusObj.name;
            }
        };

        function setTableSortFn(state, obj) {
            tableSort[state] = obj;
        }

        function openLoginFn(param) {
            $rootScope.$emit('openLoginEvent', param);
        }

        this.getAuctionByProcedure = function () {
            // var socket = io('http://web-uetp' ,{
            //     path: '/openauction/auctionSocket'
            // });
            // socket.on('connect', function(){console.log(arguments)});
            // socket.on('event', function(data){console.log(arguments)});
            // socket.on('disconnect', function(){console.log(arguments)});
            return httpSrv.http({
                method: 'GET',
                url: $stateParams.procType + '/getAuctionByProcedure/' + $stateParams.procId
            })
        };
        this.getAuctionsProgressByProcedure = function () {
            return httpSrv.http({
                method: 'GET',
                url: $stateParams.procType + '/getAuctionsProgressByProcedure/' + $stateParams.procId
            })
        };
        this.getProcedure = function () {
            return httpSrv.http({
                method: 'GET',
                url: '/' + $stateParams.procType + '/' + $stateParams.procId
            });
        };
        this.getRebiddingsByProcedure = function (data) {
            return httpSrv.http({
                method: 'POST',
                url: $stateParams.procType + '/' + $stateParams.procId + '/rebiddings',
                data: data
            })
        };
        this.getClaimsByProcedure = function (data) {
            return httpSrv.http({
                method: 'POST',
                url: $stateParams.procType + '/getAllClaims/' + $stateParams.procId ,
                data: data
            })
        };
        this.getPriceOffersByProcedure = function () {
            return httpSrv.http({
                method: 'GET',
                url: $stateParams.procType + '/getPriceOffers/' + $stateParams.procId
            })
        };
        this.getClaimsStatistics = function () {
            return httpSrv.http({
                method: 'GET',
                url: $stateParams.procType + '/getClaimsStatistics/' + $stateParams.procId
            })
        };
        this.getStatisticsByProcedure = function (data) {
            return httpSrv.http({
                method: 'POST',
                url: $stateParams.procType + '/getViewStatistics/' + $stateParams.procId ,
                data: data
            })
        };
        this.downloadClaimArchive = function (stateParams, claimId) {
            var url  = 'fs/file/' +  stateParams.procType + '/' + stateParams.procId + '/claims/' + claimId + '/archive';
            httpSrv.downloadFile(url);
        };

        this.downloadClaimCert = function (stateParams, claimId) {
            const url  = `${stateParams.procType}/downloadClaimCert/${claimId}`;
            httpSrv.downloadFile(url);
        };

        this.getAuctionsProgressByRebidding = function (rebiddingId) {
        	 return httpSrv.http({
                 method: 'GET',
                 url: $stateParams.procType + '/getAuctionsProgressByRebidding/' + rebiddingId
             })
        };
        this.signAndSendBid = function (auctionId, bid, certId, type) {
            return service.sendBid(auctionId, bid).then(function (response) {
                if (response.data.success) {
                    return cryptography.generateSignature(response.data.result, certId).then(function (value) {
                        var requestData = {message: value};
                        return service.signBid(auctionId, bid, requestData, type);
                    }, function (reason) {
                        if (typeof reason === 'string') {
                            return Promise.reject("Ошибка подписи документа. " + reason);
                        } else {
                            return Promise.reject("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                        }
                    })
                } else if(!response.data.success && ['427','429','430_2'].includes(response.data.errorCode)) {
                    return Promise.reject(response);
                } else {
                    return Promise.resolve(response);
                }
            })

        };
        this.sendBid = function (auctionId, bid) {
            return httpSrv.http({
                method: 'POST',
                url: $stateParams.procType.toLowerCase() + '/bid/' + auctionId + '/' + bid
            })
        };
        this.signBid = function (auctionId, bid, data, type) {
            return httpSrv.http({
                method: 'POST',
                url: `${$stateParams.procType.toLowerCase()}/signBid/${type}/${auctionId}/${bid}`,
                data: data
            })
        }
        this.startAuction = function (auctionId) {
            return httpSrv.http({
                method: 'GET',
                url: $stateParams.procType.toLowerCase() + '/startAuction/' + auctionId
            })
        };
        this.getPublicTradeProgress = function (auctionId) {
            return httpSrv.http({
                method: 'GET',
                url: $stateParams.procType.toLowerCase() + '/getPublicTradeProgress/' + $stateParams.procId + '/' + auctionId
            })
        };
        this.getAuctionAndTradeProgress = function (auctionId) {
            return httpSrv.http({
                method: 'GET',
                ignoreLoadingBar: true,
                url: $stateParams.procType.toLowerCase() + '/getAuctionAndTradeProgress/' + auctionId
            })
        };
        this.getRebiddingById = function (rebiddingId) {
        	 return httpSrv.http({
                 method: 'GET',
                 url: $stateParams.procType + '/' + $stateParams.procId + '/rebidding/'+rebiddingId
             })
        };
        this.addParticipant = function (inn, procType) {
            return httpSrv.http({
                method: 'POST',
                url: (procType ? procType.toLowerCase() + '/addParticipantNotice/' +  $stateParams.id : $stateParams.procType.toLowerCase() + '/addParticipant/'+  $stateParams.procId),
                data: inn
            })
        };
        this.getParticipantsDoc = function (id_notice, procType) {
            return httpSrv.http({
                method: 'GET',
                url: (procType?procType:$stateParams.procType.toLowerCase()) + '/getParticipantsDoc/' + id_notice
            })
        };
        this.deleteParticipant = function (inn, procType) {
            return httpSrv.http({
                method: 'POST',
                url: (procType?procType + '/deleteParticipantNotice/' + $stateParams.id : $stateParams.procType.toLowerCase() + '/deleteParticipant/' + $stateParams.procId ),
                data: inn
            })
        };
        this.notifyParticipant = function (inn, procType) {
            return httpSrv.http({
                method: 'POST',
                url: (procType?procType.toLowerCase():$stateParams.procType.toLowerCase()) + '/notifyParticipant/' + $stateParams.procId,
                data: inn
            })
        };
        this.existParticipantByInn = function (inn, procType) {
            return httpSrv.http({
                method: 'POST',
                url: (procType?procType.toLowerCase():$stateParams.procType.toLowerCase()) + '/existParticipantByInn',
                data: inn
            })
        };
        this.createNoticeNewVersion = function (procType, procId) {
            return httpSrv.http({
                method: 'PUT',
                url: procType.toLowerCase() + '/' + procId + '/createNoticeNewVersion'
            })
        };
        this.getProcedureName = function (id, type, sendedStatusCode, procType, stage, submissionClosePKODateTimeEnds, procedure) {
            var procedureNames = {
                published: "Закупка опубликована",
                claiming: "Подача заявок",
                opening: "Работа комиссии",
                ratingFirstClaimParts: "Работа комиссии",
                ratingSecondClaimParts: "Работа комиссии",
                preBiddings: "Проведение торгов",
                biddings: "Проведение торгов",
                summarizing: "Работа комиссии",
                finished: "Закупка завершена",
                rebidding: "Проведение переторжки",
                preRebidding: "Ожидание переторжки",
                reject: "Закупка прекращена",
                PLANNED:'Планируется',
                PUBLISHED:'Опубликована',
                CANCELLED:'Отменена',
                SUBMISSION_OF_APPLICATION:'Подача заявок',
                COMMISSION_WORK:'Работа комисcии',
                COMPLETED:'Завершена',
                hidden:'Скрыта'
            };

            var procedureNamesOrg = {
                published: "Закупка опубликована",
                claiming: "Подача заявок",
                opening: "Вскрытие заявок",
                ratingFirstClaimParts: "Рассмотрение заявок",
                ratingSecondClaimParts: "Рассмотрение заявок",
                preBiddings: "Проведение торгов",
                biddings: "Проведение торгов",
                summarizing: "Подведение итогов",
                finished: "Закупка завершена",
                rebidding: "Проведение переторжки",
                preRebidding: "Ожидание переторжки",
                reject: "Закупка прекращена"
            };
            
            var procedureNamesParticipantsDoc = {
            		noneParticipants: "Подача заявок. Отсутствуют адресаты",
            		someNotSended: "Подача заявок. Доставлено не всем адресатам",
            		allSended: "Подача заявок"
                };

            if(['openauction','saleauction','limitedauction'].includes(procType)) {
                procedureNamesOrg.opening = "Рассмотрение заявок";
            }

            if(['requestproposals', 'limitedrequestproposals'].includes(procType) && procedure?.simplifiedProcedure) {
                procedureNames.claiming = "Подача заявок";
                procedureNamesOrg.claiming = "Подача заявок";
                procedureNamesOrg.ratingFirstClaimParts = "Рассмотрение заявок";
                procedureNamesOrg.opening = "Вскрытие заявок";
                procedureNamesParticipantsDoc = {
                    noneParticipants: "Подача заявок. Отсутствуют адресаты",
                    someNotSended: "Подача заявок. Доставлено не всем адресатам",
                    allSended: "Подача заявок"
                };
            } else if((['requestproposals', 'limitedrequestproposals', 'open2stagecontest'].includes(procType))){
                procedureNames.claiming = "Подача заявок (" + (!stage || stage.id === 'stage1' ? "первый этап" : "второй этап") + ")";
                procedureNamesOrg.claiming = "Подача заявок (" + (!stage || stage.id === 'stage1' ? "первый этап" : "второй этап") + ")";
                procedureNamesOrg.ratingFirstClaimParts = "Рассмотрение заявок, " + (!stage || stage.id === 'stage1' ? "первый этап" : "второй этап");
                procedureNamesOrg.opening = "Вскрытие заявок (" + (!stage || stage.id === 'stage1' ? "первый этап" : "второй этап") + ")";
                procedureNamesParticipantsDoc = {
                    noneParticipants: "Подача заявок (" + (!stage || stage.id === 'stage1'? "первый этап" : "второй этап") + "). Отсутствуют адресаты",
                    someNotSended: "Подача заявок (" + (!stage || stage.id === 'stage1'? "первый этап" : "второй этап") + "). Доставлено не всем адресатам",
                    allSended: "Подача заявок (" + (!stage || stage.id === 'stage1'? "первый этап" : "второй этап") + ")"
                };
            } else if(procType==='unlimitedprequalification') {
            	if(submissionClosePKODateTimeEnds && Date.now() < submissionClosePKODateTimeEnds ){
                    procedureNamesOrg.ratingFirstClaimParts = "Подача заявок";
                    procedureNames.ratingFirstClaimParts = "Подача заявок";
            	} else{
            		procedureNamesOrg.ratingFirstClaimParts = "Работа комиссии";
                    procedureNames.ratingFirstClaimParts = "Работа комиссии";
            	}
            } else if(procType==='openpublicoffer') {
                procedureNames.preBiddings = "Подача предложений";
                procedureNames.biddings = "Подача предложений";
                procedureNamesOrg.preBiddings = "Подача предложений";
                procedureNamesOrg.biddings = "Подача предложений";
            }

           return type === 'my' ?
               (id === 'claiming' && sendedStatusCode ? procedureNamesParticipantsDoc[sendedStatusCode] : procedureNamesOrg[id]) :
               procedureNames[id];
        };

        this.getActualProtocolName = function (data, procedure) {
            if (data.type.id === 'REZ' && ['openauction','saleauction','limitedauction'].includes(procedure.type.id))
                return 'Протокол проведения аукциона';
            else if (data.type.id === 'REZ' && 'openpublicoffer'===procedure.type.id)
                return 'Протокол проведения открытого публичного предложения';
            else if(data.type.id === 'PART1' && (procedure.type.id === 'requestproposals' || procedure.type.id === 'limitedrequestproposals')) {
                return data.stage && data.stage == 'stage2' ? 'Протокол рассмотрения и оценки окончательных предложений' : 'Протокол запроса предложений';
            } else if(data.type.id === 'OPEN' &&  procedure.type.id === 'limitedrequestproposals') {
                return data.stage && data.stage=='stage2' ? 'Протокол вскрытия окончательных предложений' : 'Протокол вскрытия заявок';
            }
            else return data.name;
        };

        this.procedureNameType = function (procedure) {
            var cabType = desktopSrv.getCabType();
            if (cabType === 'organizer' || (cabType === 'cbz' && service.isRZDOrChildRZD(procedure))) {
                return 'my';
            } else {
                return 'public';
            }
        };

        this.isRZDOrChildRZD = function (procedure) {
            if(procedure.customer && angular.isDefined(procedure.customer.rzdCustomer))
                return procedure.customer.rzdCustomer;
            else return false;
        };

        this.isEisProcedure = function (procedure) {
            return !(procedure.section === 'commercial' || (procedure.type && procedure.type.id === 'simplecontest'));
        };

        this.is223Competitive = function (procedure) {
            return procedure && procedure.section==='223' && !procedure.nonCompetitive && !(procedure.type && procedure.type.id === 'simplecontest');
        }

        this.fixUrlETP = function (url) {
            if (url.indexOf("https://") === -1 && url.indexOf("http://") === -1) {
                return "http://" + url;
            }
            return url;
        };
        /**
         * Анализ обеспечения закупки или контракта и выставления признака
         * @param procedure - куда выставляем признак
         * @param timeZone - таймзона
         */
        this.fillTimeZone = function (procedure, timeZone) {
            if (timeZone === null) {
                procedure.timeZoneGmt = 3;
                procedure.timeZoneFront = null;
            } else {
                procedure.timeZoneGmt = timeZone + 3;
                procedure.timeZoneFront = timeZone === 0 ? ''
                    : timeZone > 0 ? '+' + timeZone : timeZone;
            }
        };
        /**
         * Анализ требуется ли обеспечение закупки или контракта и выставления признака
         * @param procedure - куда выставляем признак
         */
        this.needSupplySum = function (procedure) {
            var result = false;
            angular.forEach(procedure.lots, function (lot) {
                if (lot.applicationSupplySumm || lot.contractSupplySumm) {
                    result = true;
                }
            });
            procedure.needSupplySum = result;
        }
        /**
        * Запросы к searchPurchases
         * @param $scope - $scope
         * @param param - параметры для поиска
         * @param isEis признак ЕИС
        * */
        this.searchPurchases = function ($scope, param, isEis = false) {
            return new Promise(function (resolve, reject) {
                propertiesService.getProperty("otherProcedures.url").then(function (host) {
                    httpSrv.requestXHR($scope, {
                        method: 'POST',
                        url: `${host}/rest/other-purchases/proc/searchPurchases${isEis?'Eis':''}`,
                        data: param
                    }).then(function (response) {
                        resolve(response);
                    }, function (reason) {
                        console.log(reason);
                        reject(reason);
                    });
                }, function (reason) {

                    reject(reason);
                });
            })
        }

        /**
         * Смена статусов протоколов и закупок для оператора-админа
         * @returns {*|Promise|Promise<any>}
         */
        this.changeState = function (url) {
            return httpSrv.http({
                method: 'GET',
                url: url
            })
        };

        this.excludePurchase = function(purchases) {
            var result = [];
            angular.forEach(purchases, function(purchase){
                if(purchase.id!=='offerplacement') result.push(purchase);
            });
            return result;
        }

        this.downloadPaymentOrderFn = function (procedureId, lotId) {
            httpSrv.downloadFile('/auth/tariff/paymentOrder/' + procedureId + '/' + lotId, 'payment_order');
        }
    }
]);