/**
 * @author: ivank
 * Профиль пользователя. Аккредитация
 */
angular.module('uetp')
    .component('accreditationEditor', {
        templateUrl: 'app/components/profile/accreditation/accreditationEdit/accreditation.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', '$log', '$q', 'dictionarySrv',
            'alertSrv', 'cryptography', 'httpSrv', 'dialogSrv','needConfirmSrv','tariffsSrv','$filter', 'propertiesService',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, $log, $q, dictionarySrv,
                      alertSrv, cryptography, httpSrv, dialogSrv,needConfirmSrv,tariffsSrv,$filter, propertiesService) {

                var ctrl = this;
                $scope.ctrl = ctrl;

                this.$onInit = function () {
                    $scope.submitted = true;
                    $scope.cabType = desktopSrv.getCabType();
                    $scope.modelViewRequisite = {subjectMsp: false};
                    $scope.options = {};
                    $scope.accred = {
                        otherTariffCodes :[]
                    };
                    $scope.otherTariffsSelected =[];
                    $scope.active = {};
                    $scope.form = {registerForm: {}};
                    $scope.userCertificates = {};
                    $scope.authData = authSrv;
                    $scope.authUser = authSrv.getAuth();
                    $scope.typeRequisition = '';
                    $scope.reason = {};
                    $scope.reasonRevoke = {};
                    $scope.processing = false;
                    $scope.certError = authSrv.isCertificateError;
                    $scope.mchdError = authSrv.getMchdCheckResult;
                    if ($stateParams.params) {
                        $scope.notApproved = $stateParams.params.notApproved;
                    }
                    $scope.docs={
                        accreditation_egrul:{},
                        accreditation_founder:{},
                        accreditation_proxy:{},
                        accreditation_chief:{},
                        accreditation_maxSum:{},
                        accreditation_other:{},
                        accreditation_egrip:{},
                        accreditation_passport:{}
                    };
                    $scope.viewModel = {
                        accredCommer:false,
                        accredTariffSelected:{}
                    };

                    $scope.user = angular.copy(authSrv.getUser());
                    $scope.oldFullName = $scope.user.fullName;
                    $scope.isLicenseSuccess = $scope.user.approvalReglament || $scope.user.consentProcessingDate;


                    $scope.tariffs = {};
                    $scope.tariffsOther = {};
                    if (!$stateParams.id) {
                        _initTypeOrg($scope.user.typeOrg);
                        $scope.disableForm = false;
                        //tmp
                        const accredType = ($scope.$parent.$parent.model && $scope.$parent.$parent.model.showPanel == 'requisites') ? "requisites_renew" : "accreditation";
                        if (accredType === 'requisites_renew') {
                            $scope.docTitle = "Изменение реквизитов";
                            fillAccreditation(true);
                            $scope.accred.type = accredType;
                            setCheckBoxValues();

                        } else {
                            $scope.docTitle = "Аккредитация участника";
                            checkPreviousAccreditation();
                        }
                        checkMchd();
                    } else {
                        $scope.disableForm = true;
                        propertiesService.getProperty("mchd.url").then((host) => {
                            if (!host.endsWith('/')) host += '/';
                            $scope.mchdUrl = host;
                            loadAccreditationById($stateParams.id);
                            checkMchd();
                        });

                    }
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    dictionarySrv.resetPaginatedDict($scope);
                    fillRequisitesFn();
                    _initModelView(!$stateParams.id);
                    function checkMchd() {
                        profileSrv.mchdLoginCertCheck().then(checkRes => {
                            if (!checkRes.data.success && ['427','429'].includes(checkRes.data.errorCode)) {
                                authSrv.updateMchdCheckResult(checkRes.data.errorMessage);
                                $rootScope.$emit('mchdError', true, checkRes.data.errorMessage);
                            }
                        });
                    }
                };
                $scope.goBack = goBackFn;
                $scope.register = registerFn;
                $scope.attachDoc = attachDocFn;
                $scope.deleteAttach = deleteAttachFn;
                $scope.downloadFile = downloadFileFn;
                $scope.processAccreditation = processAccreditationFn;
				$scope.processChange = processChangeFn;
				$scope.changeAccred = changeAccredFn;
                $scope.getDocByType = getDocByTypeFn;
                $scope.checkApplications = checkApplicationsFn;
                $scope.checkSubjectMSP = checkSubjectMSPFn;
                $scope.changeOksm = changeOksmFn;
                $scope.changeOkato = changeOkatoFn;
                $scope.cancel = cancelFn;
                $scope.reglamentCheck = reglamentCheckFn;
                $scope.checkOrgName = checkOrgNameFn;
                $scope.revokeAccred = revokeAccredFn;
                $scope.titleTarifs = titleTarifsFn;
                $scope.fastAccreditation = fastAccreditationFn; // ускоренная аккредитация
                $scope.selectTariffs = selectTariffsFn;
                $scope.refreshDict = refreshDictFn;
                $scope.changeNrType = changeNrTypeFn;
                $scope.updateOtherTariffCodes = updateOtherTariffCodesFn;
                $scope.downloadDocsArchive = profileSrv.downloadDocsArchive;
                $scope.getMchdStateDesc = profileSrv.getMchdStateDesc;
                /**
                 * Вывод сообщения при подтверждении аккредитации по кнопке "подписать и отправить"
                 * @returns {string}
                 */
                $scope.getSignSendTextConf = function () {
                    if ($scope.accred.type === 'requisites_renew') {
                        return "Подписать и отправить"
                    } else {
                    	
                    	if($scope.showTariffs && ($scope.viewModel.accredTariffSelected.id && $scope.viewModel.accredCommer || $scope.otherTariffsSelected.length > 0)) {
                    		
                    		if($scope.viewModel.accredTariffSelected.id && $scope.viewModel.accredCommer && $scope.otherTariffsSelected.length == 0){
                    			return `Подписать и отправить заявку на аккредитацию и на подключение тарифного плана "${$scope.viewModel.accredTariffSelected.name}" (${$scope.viewModel.accredTariffSelected.code})`
                    		}else if((!$scope.viewModel.accredTariffSelected.id || !$scope.viewModel.accredCommer) && $scope.otherTariffsSelected.length > 0){
                    			if($scope.otherTariffsSelected.length == 1){
                    				return `Подписать и отправить заявку на аккредитацию и на подключение тарифного плана "${$scope.otherTariffsSelected[0].name}" (${$scope.otherTariffsSelected[0].code})`
                         		}else{
                         			let text = "";
                         			$scope.otherTariffsSelected.forEach(function(tariff){
                         				if(text){
                         					text += ', ';
                         				}
                         				text += `"${tariff.name}" (${tariff.code})`;
                                    });
                         			
                         			return "Подписать и отправить заявку на аккредитацию и на подключение тарифных планов " + text;
                        		}
                    		}else if($scope.viewModel.accredTariffSelected.id && $scope.viewModel.accredCommer && $scope.otherTariffsSelected.length > 0){
                    			let text = `"${$scope.viewModel.accredTariffSelected.name}" (${$scope.viewModel.accredTariffSelected.code })`;
                     			$scope.otherTariffsSelected.forEach(function(tariff){
                     				if(text){
                     					text += ', ';
                     				}
                     				text += `"${tariff.name}" (${tariff.code})`;
                                });
                     			
                     			return "Подписать и отправить заявку на аккредитацию и на подключение тарифных планов " + text;
                    		}else{
                    		   return "Подписать и отправить заявку на аккредитацию";
                    		}
                    	}else{
                    		return "Подписать и отправить заявку на аккредитацию";
                    	}
                    }
                };
                $scope.commaTitle = 
                	"Выберите интересующий Вас тарифный план, заполните и отправьте заявление на аккредитацию, и при положительном результате обработки заявления на аккредитацию на указанный адрес электронной почты будет направлен пакет документов для оплаты выбранного Вами тарифного плана.\n" +
                    "Подключение тарифного плана производится автоматически по факту поступления денежных средств на расчетный счет Оператора.\n" +
                    "\n" +
                    "С подробным описанием тарифных планов можно ознакомиться на сайте в соответствующем разделе \"Тарифы и услуги - Тарифные планы\".";

                $scope.commaTitleOther =
                    "Выберите интересующий Вас тарифный план или услугу, заполните и отправьте заявление на аккредитацию, и на указанный адрес электронной почты будет направлен пакет документов для оплаты.\n"+
                    "\n" +
                    "С подробным описанием тарифных планов можно ознакомиться на сайте в соответствующем разделе \"Тарифы и услуги\".";

                /**
                 * Загрузка тарифов
                 */
                function tariffsLoad() {
                    tariffsSrv.getTariffsProfile().then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.tariffs = response.data.items.filter(function (item) {
                                return item.group === 'tariff';
                            });
                            var checkState = $scope.tariffs.filter(function (item) {
                                return item.state && true;
                            });
                            // если аккредитация не первичная или есть тарифы - скрываем выбор
                            if ($scope.accred.type === "accreditation_renew" || checkState.length > 0) {
                                $scope.showTariffs = false;
                                delete $scope.tariffs;
                            } else {
                                $scope.viewModel.accredCommer = true;
                                $scope.showTariffs = true;
                            }
                            $scope.tariffsOther = response.data.items.filter(function (item) {
                                return (item.group === 'accreditation'||item.group === 'service')&&!($scope.user.fastAccreditation && item.group === 'accreditation');
                            });
                            var otherTarifCodes=[];
                            $scope.tariffsOther.forEach(function(tariff){
                                otherTarifCodes.push(tariff.code);
                            });
                            tariffsSrv.checkTariffsInvoiceWait(otherTarifCodes).then(function(response){
                                if (response.data.success){
                                    $scope.tariffsOther = $scope.tariffsOther.filter(function (item) {
                                        return response.data.result.indexOf(item.code)==-1;

                                    });
                                    if($scope.tariffsOther.length==0){
                                        $scope.showOtherTariffs = false;
                                        delete $scope.tariffsOther;
                                    }
                                    else{
                                        $scope.showOtherTariffs = true;
                                    }
                                }
                            });


                        }
                    }, function errorCallback(response) {
                        console.log(response)
                    });
                }
                function selectTariffsFn($event, row) {
                    const select = $event.target.checked;
                    for(var i=0;i<$scope.tariffs.length;i++){
                        $scope.tariffs[i].select = false;
                    }
                    if(select) {
                        row.select = true;
                        $scope.viewModel.accredTariffSelected = row;
                    } else
                        $scope.viewModel.accredTariffSelected={};
                }

                /**
                 * Подсказка для тарифов
                 */
                function titleTarifsFn(row) {
                    var row2 = (row.enablePrice>0)?($filter('currency')(row.enablePrice)+", без учета НДС"):("бесплатно"),
                    row3 = (row.tariffPrice>0)?($filter('currency')(row.tariffPrice)+", НДС не облагается"):("бесплатно");
                    if(row.tariffPrice>0){
                        var ndsSum = row.tariffPrice*row.nds/100;
                        row3 = (row.nds>0)?($filter('currency')(row.tariffPrice + ndsSum)+", в том числе НДС " + row.nds + "%, " + $filter('currency')(ndsSum) ):($filter('currency')(row.tariffPrice)+", НДС не облагается");
                    } else {
                        row3 = "бесплатно";
                    }
                    return row.description+
                        "\nПодключение тарифа: "+row2
                        +"\nСтоимость тарифа: "+row3;
                }
                $scope.rejectObjects = {
                    setStatus: function (id, state) {
                        $scope.reason.show = true;
                        $scope.reason.id = id;
                        $scope.reason.state = state;
                    },
                    hideSetStatus: function () {
                        reasonReset();
                    },
                    sendSetStatus: function () {
                        processAccreditationFn($scope.reason.id, $scope.reason.state, $scope.reason.set.message || '');
                    }
                };
                $scope.revokeObjects = {
                    setStatus: function (id, state) {
                        $scope.reasonRevoke.show = true;
                        $scope.reasonRevoke.id = id;
                        $scope.reasonRevoke.state = state;
                    },
                    hideSetStatus: function () {
                        reasonReset();
                    },
                    sendSetStatus: function () {
                        revokeAccredFn($scope.reasonRevoke);
                    }
                };

                function _initDict(dictList, reload) {
                    angular.forEach(dictList, function (name) {
                        dictionarySrv.getDictionaryRemote(name, reload);
                    })
                }

                function revokeAccredFn(accredObj){
                    var dataObj = {
                        id:accredObj.id,
                        state:accredObj.state,
                        revokeReason:accredObj.set.message || ''
                    }
                    profileSrv.revokeAccred(dataObj).then(function(response){
                        if (response.data.success) {
                            goBackFn();
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message, function(){
                                $state.reload();
                            });
                        }
                    }, function(response){
                        console.log(response);
                    })

                }

                function reglamentCheckFn(){
                    $scope.isLicenseSuccess = !$scope.isLicenseSuccess;
                }

                function changeOksmFn(object, select){
                    $scope.accred.document.orgInfo[object].region = '';
                    $scope.accred.document.orgInfo[object].regionName = '';
                    $scope.accred.document.orgInfo[object].countryName = select.$select.selected?select.$select.selected.ncm:"";
                }
                function changeOkatoFn(object, select){
                    $scope.accred.document.orgInfo[object].regionName = select.$select.selected?(select.$select.selected.formalname+" "+select.$select.selected.shortname):"";
                }

                function getDocByTypeFn(type){
                    return $scope.docs[type] || {};
                }
                function setDocByTypeFn(type, doc){
                    return $scope.docs[type] = doc;
                }

                function fillDocsByType(docArray){
                    if (!Array.isArray(docArray)) return;
                    docArray.forEach(function(el){
                        setDocByTypeFn(el.documentType, el)
                    })
                }
                function checkApplicationsFn(){
                    var docTypes = {
                        1:["accreditation_egrul", "accreditation_founder", "accreditation_proxy", "accreditation_chief", "accreditation_maxSum", "accreditation_other"],
                        2:["accreditation_passport", "accreditation_chief", "accreditation_egrip","accreditation_other" ],
                        4:[],
                        3:["accreditation_passport", "accreditation_other"]
                    };

                    for (var idx in $scope.docs){
                        if (docTypes[$scope.active.type].indexOf(idx)==-1) continue;
                        if (idx!='accreditation_other' && !$scope.docs[idx].id) return false
                    }
                    return true;
                }

                function downloadFileFn(accredId, attachId, attachName) {
                    httpSrv.downloadFile('/fs/file/downloadAttachment/' + accredId + '/' + attachId, attachName);
                }

                function loadAccreditationById(id) {
                    profileSrv.getAccreditationById(id).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.accred = response.data.result;

                            _initTypeOrg($scope.accred.owner.typeOrg);
                            if ($scope.accred.document.documents) {
                                fillDocsByType($scope.accred.document.documents.document);
                            }

                            if ($scope.accred.document.orgInfo.legalEntity) {
                                if ($scope.accred.document.orgInfo.legalEntity.mspType) $scope.modelViewRequisite.subjectMsp = true;
                            } else if ($scope.accred.document.orgInfo.individualPerson) {
                                if ($scope.accred.document.orgInfo.individualPerson.mspType) $scope.modelViewRequisite.subjectMsp = true;
                            }


                            $scope.docTitle = $scope.accred.typeDescription;
                            if(['accreditation', 'accreditation_renew'].includes($scope.accred.type)) {
                                $scope.docTitle += (' от ' + $filter('date')(new Date($scope.accred.created), 'dd.MM.yyyy HH:mm','UTC+3') + ' (MSK)');
                            }
                            setCheckBoxValues();
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message, function(){
                                $state.go("profile");
                            });

                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    })
                }

                function deleteAttachFn(type, object) {

                    profileSrv.deleteAccredAttachment($scope.docs[type].id).then(function (response) {
                        if (!response.data.success) {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                        var index = object.indexOf($scope.docs[type]);
                        if (index > -1) {
                            object.splice(index, 1);
                        }
                        $scope.docs[type] = {};
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }


                function attachDocFn(data, type) {
                    var fileItem = data[0],
                        document = {
                            id: fileItem.id,
                            fileName: fileItem.fileName,
                            fileHash: fileItem.hash,
                            documentType: type,
                            fileSize: fileItem.size
                        };
                    if (!$scope.accred.document.documents.document) $scope.accred.document.documents.document = [];
                    var j = getIdxByFileType($scope.accred.document.documents.document, type);
                    if (j == -1) {
                        $scope.accred.document.documents.document.push(document);
                    } else {
                        $scope.accred.document.documents.document[j] = document;
                    }
                    setDocByTypeFn(type, document);
                    if ($scope.form.registerForm) $scope.form.registerForm.$setDirty();

                    function getIdxByFileType(array, type){
                        var idx = -1;
                        if (!array || !array.length) return idx;
                        for (var i = 0; i< array.length; i++){
                            if (array[i].documentType == type) return i;
                        }
                        return idx;
                    }
                }

                function checkPreviousAccreditation(){
                    profileSrv.isOrgFirstAccred().then(function(response) {
                        if (response.data.success) {
                            if (response.data.result === true) {
                                return profileSrv.getAccreditationPrefillData().then(function(response) {
                                    if (response.data.success) {
                                        $scope.accred.document = response.data.result;
                                        //  UETP-8964 ФИО и email должны соответствовать текущему пользователю
                                        if($scope.accred.document.signaturePerson.email!==$scope.user.email)
                                            $scope.accred.document.signaturePerson.email = $scope.user.email;
                                        const fio = $scope.accred.document.signaturePerson.fio;
                                        if(fio.firstName!==$scope.user.firstName || fio.lastName!==$scope.user.lastName || fio.middleName!==$scope.user.middleName) {
                                            fio.firstName = $scope.user.firstName;
                                            fio.lastName = $scope.user.lastName;
                                            fio.middleName = $scope.user.middleName;
                                        }

                                        if (response.data.result.documents.document)
                                            $scope.accred.document.documents.document = [];
                                    } else {
                                        fillAccreditation(false);
                                    }
                                    $scope.accred.type = "accreditation";
                                });
                            } else {
                                fillAccreditation(true);
                                $scope.accred.type = "accreditation_renew";
                            }
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message);
                        }
                    }).then(function() {
                        checkingOrgName();
                        if ($scope.cabType === 'participant') tariffsLoad();
                        setCheckBoxValues();
                    });
                }
                function setCheckBoxValues(){
                    $scope.isChecked = angular.equals($scope.accred.document.orgInfo.regAddress, $scope.accred.document.orgInfo.realAddress) || !$scope.accred.document.orgInfo.realAddress || !$scope.accred.document.orgInfo.realAddress.country;
                    $scope.msp = !!(($scope.accred.document.orgInfo.legalEntity && $scope.accred.document.orgInfo.legalEntity.mspType) || ($scope.accred.document.orgInfo.individualPerson && $scope.accred.document.orgInfo.individualPerson.mspType));
                }
                function fillAccreditation(isRenew){
                    $scope.accred={
                        document:{
                            orgInfo:{
                                contactInfo:{
                                    email: $scope.user.email,
                                    phone: $scope.user.phone,
                                    urlShop: $scope.user.urlShop
                                }
                            },
                            signaturePerson:{
                                fio:{
                                    lastName: $scope.user.lastName,
                                    firstName: $scope.user.firstName,
                                    middleName: $scope.user.middleName
                                },
                                email:$scope.user.email,
                                cert:$scope.user.certId
                            },
                            documents:{
                                document:[]
                            }
                        },
                        otherTariffCodes :[]
                    };

                    $scope.otherTariffsSelected = [];
                    if ($scope.user.regAddress) {
                        $scope.accred.document.orgInfo.regAddress = {
                            country: $scope.user.regAddress.oksmCode,
                            countryName: $scope.user.regAddress.oksmName,
                            postIndex: $scope.user.regAddress.zip,
                            region: $scope.user.regAddress.regionOKATOCode,
                            regionName: $scope.user.regAddress.regionOKATOName,
                            area: $scope.user.regAddress.area,
                            city: $scope.user.regAddress.settlement,
                            street: $scope.user.regAddress.street,
                            house: $scope.user.regAddress.building,
                            building: $scope.user.regAddress.subBuilding,
                            apartment: $scope.user.regAddress.office
                        }
                    }

                    if ($scope.user.realAddress){
                        $scope.accred.document.orgInfo.realAddress = {
                            country: $scope.user.realAddress.oksmCode,
                            countryName:$scope.user.realAddress.oksmName,
                            postIndex: $scope.user.realAddress.zip,
                            region: $scope.user.realAddress.regionOKATOCode,
                            regionName: $scope.user.realAddress.regionOKATOName,
                            area: $scope.user.realAddress.area,
                            city: $scope.user.realAddress.settlement,
                            street: $scope.user.realAddress.street,
                            house: $scope.user.realAddress.building,
                            building: $scope.user.realAddress.subBuilding,
                            apartment: $scope.user.realAddress.office
                        }
                    }

    if ([2,3].includes($scope.active.type)){
        $scope.accred.document.orgInfo.individualPerson={
            fio:{
                lastName: $scope.user.lastName,
                firstName: $scope.user.firstName,
                middleName: $scope.user.middleName
            },

            OGRN:$scope.user.ogrn,
            OKPO:$scope.user.okpo,
            snils:$scope.user.snils,
            isIP: $scope.active.type===2,
            mspType: $scope.user.mspType,
            INN:$scope.user.inn,
            orgMail:$scope.user.orgMail,
            okved:$scope.user.okved,
            okvedOther:$scope.user.okvedOther
        };
        // Делаем хитрость для ИП
        if($scope.active.type===2 && $scope.user.fullName) {
            const arr = angular.copy($scope.user.fullName).split(' ');
            $scope.accred.document.orgInfo.individualPerson.fio.lastName = arr.shift();
            $scope.accred.document.orgInfo.individualPerson.fio.firstName = arr.shift();
            $scope.accred.document.orgInfo.individualPerson.fio.middleName = arr.length===0 ? '' : (arr.length>1 ? arr.join(' ') : arr[0]);
        }
        setTimeout(function(){//заплата - плагин
            if ($scope.form.registerForm && $scope.form.registerForm.snils) $scope.form.registerForm.snils.$setPristine();
            if ($scope.form.registerForm) $scope.form.registerForm.$setPristine();
            $scope.$digest();
        },1000);

    }
                    if ($scope.active.type === 1){
                        $scope.accred.document.orgInfo.legalEntity={
                            INN:$scope.user.inn,
                            KPP:$scope.user.kpp,
                            OGRN:$scope.user.ogrn,
                            OKPO:$scope.user.okpo,
                            fullName: $scope.user.fullName,
                            mspType: $scope.user.mspType,
                            orgMail:$scope.user.orgMail,
                            okved:$scope.user.okved,
                            okvedOther:$scope.user.okvedOther
                        }
                    }
                    if ($scope.active.type === 4){
                        $scope.accred.document.orgInfo.nonResident={
                            UIN:$scope.user.uin,
                            fullName: $scope.user.fullName,
                            orgMail:$scope.user.orgMail,
                            okved:$scope.user.okved,
                            okvedOther:$scope.user.okvedOther,
                            type: $scope.user.nrType ? $scope.user.nrType : 'L'
                        }
                    }
                    if (!isRenew && $scope.user.applications && $scope.user.applications.length>0){
                        $scope.accred.document.documents = {document : angular.copy($scope.user.applications)};
                        var fileArray = [];
                        $scope.user.applications.forEach(function(el){
                            fileArray.push({
                                id:el.id,
                                fileName:el.filename,
                                documentType:el.type,
                                fileSize:1
                            })
                        })
                        $scope.accred.document.documents.document = angular.copy(fileArray);
                        fillDocsByType($scope.accred.document.documents.document);
                    }
                    // UETP-2758 заполняем поле "Максимальная сумма одной сделки"
                    if ($scope.user.accreditationId) {
                        profileSrv.getAccreditationById($scope.user.accreditationId).then(function successCallback(response) {
                            if (response.data.success && response.data.result) {
                                if ($scope.active.type === 1)
                                    $scope.accred.document.orgInfo.legalEntity.maxSum = $scope.user.maxSum;
                                if ($scope.active.type === 4)
                                    $scope.accred.document.orgInfo.nonResident.maxSum = $scope.user.maxSum;
                            }
                        })
                    }

                }

                function _initTypeOrg(typeOrg) {
                    $scope.active.type = typeOrg === 'ul' ? 1 : typeOrg === 'nr' ? 4 : typeOrg === 'fl' ? 3 : 2;
                }

                function _initModelView(useCert) {
                    if (useCert) {
                        fillCertFn();
                    }
                    _initDict(['okcm', 'fias_region'], false);
                }

                function fillCertFn() {
                    $scope.cert = {};
                    $scope.certspin = {};
                    $scope.active.cert = 'yes';
                    if (!authSrv.getAuth().withoutCertEnabled) {
                        setTimeout(function() {
                            cryptography.initPluginWorking().then(function (plugin) {
                                plugin.initHtmlChecker();
                                if($scope.user.certId){
                                    plugin.findCertificate($scope.user.certId).then(function (data) {
                                        $scope.certObj = data;
                                        if (!data.SubjectName) {
                                            $scope.alertObjCert = alertSrv.getErrorMsg('Не установлен сертификат пользователя или отсутствует закрытый ключ.');
                                        } else {
                                            if (typeof data.SubjectName == "string") {
                                                $scope.cn = data.GetInfo(0);
                                                checkOgrnFilled($scope.active.type, getOrn(data.SubjectName));
                                                checkingOrgName();
                                            } else {
                                                data.GetInfo(0).then(function (value) {
                                                    $scope.cn = value;
                                                    checkingOrgName();
                                                })
                                                data.SubjectName.then(function (value) {
                                                    $scope.ogrnCert = value;
                                                    checkOgrnFilled($scope.active.type, getOrn(value));
                                                })
                                            }
                                        }
                                    });
                                }
                            });
                        }, 500);
                    }
                }
                function processChangeFn() {
                    $scope.reason.show = true;
                    $scope.changeMode  = true;
                }
                function changeAccredFn(accred_id, state) {
                    if ($scope.changeMode) {
                        $scope.reason.show = false;
                        $scope.changeMode = false;
					    processAccreditationFn(accred_id, state, $scope.reason.set.message);
                    } else if (state === 'accred') {
                        processAccreditationFn(accred_id, state);
                    } else if (state === 'notaccred') {
                        $scope.rejectObjects.setStatus(accred_id, state);
                    }
                }
                function processAccreditationFn(id, state, reasonTxt) {
                    var data = {
                        id: id,
                        state: state
                    };
                    if (state == 'notaccred' || (state == 'accred' && reasonTxt)){
                        data.rejectReason = reasonTxt;
                    } else if (state =='deferred'){
                        data.deferReason = reasonTxt;
                    } else if (state == 'processing'){
                        data.changeInspectorReason = reasonTxt;
                    }
                    reasonReset();
                    $scope.activateAccred = true;
                    httpSrv.http({
                        url: '/admin/processAccreditation',
                        data: data,
                        block: true
                    }).then(function successCallback(response) {
                        $scope.activateAccred = false;
                        if (response.data.success) {
                            if (state != 'processing'){
                                localStorage.setItem("accredState", state);
                                if ($scope.notApproved) {
                                    $state.go("accreditation",{notApproved: $scope.notApproved});
                                } else {
                                    $state.go("accreditation");
                                }
                            }
                            else {
                                $scope.accred.state = "processing";
                                $scope.accred.inspector = {id:$scope.authUser.userId, personName:authSrv.getAuth().userName};
                            }
                        } else {
                            dialogSrv.showDialogMsg($scope, response.data.message, function () {
                                $state.reload();
                            });
                        }
                    })
                }
                /**
                 * Раскрытие селекта для выбора типа МСП
                 */
                function checkSubjectMSPFn() {
                    $scope.msp = !$scope.msp;
                    $scope.modelViewRequisite.subjectMsp = !$scope.modelViewRequisite.subjectMsp
                    if (!$scope.modelViewRequisite.subjectMsp){
                        if ($scope.accred.document.orgInfo.legalEntity)$scope.accred.document.orgInfo.legalEntity.mspType = '';
                        if ($scope.accred.document.orgInfo.individualPerson)$scope.accred.document.orgInfo.individualPerson.mspType = '';
                    }
                }

                /**
                 * Заполнить реквизиты организации
                 */
                function fillRequisitesFn() {
                    $scope.mspCategory = dictionarySrv.getStaticDictByType('mspCategory');
                    $scope.modelViewRequisite.subjectMsp = !!$scope.user.mspType;
                    $scope.options.kppDisabled = !!$scope.user.kpp;
                    $scope.options.accountEnabled = $scope.authData.getAuth().accountEnabled == 'true';
                    $scope.isChecked = angular.equals($scope.user.regAddress, $scope.user.realAddress) || !$scope.user.realAddress || !$scope.user.realAddress.oksmCode;
                }

                function goBackFn() {
                    if ($scope.accred.type === 'requisites_renew') {
                        history.back();
                    } else if ($scope.cabType === 'admincab') {
                        $scope.notApproved ? $state.go("accreditation",{notApproved: true}) : $state.go("accreditation",{notApproved: false});
                    } else if ($scope.cabType === 'accountant' || $scope.cabType === 'admincab' || $scope.cabType === 'support') {
                        history.back();
                    } else {
                        $state.go('profile', {activeProfileTab: 'accreditation'});
                    }
                }

                function validateAccred(){
                    var deferred = $q.defer();
                    if ($scope.certError()) {
                        deferred.reject('Функционал подписи недоступен! Сертификат просрочен или не установлен в хранилище "Личные".');
                    }
                    else if ($scope.mchdError()) {
                        deferred.reject($scope.mchdError());
                    }
                    else if ($scope.accred.type === 'accreditation' && !($scope.user.approvalReglament || $scope.user.consentProcessingDate) && !$scope.isLicenseSuccess){
                        deferred.reject('Необходимо подтвердить согласие на соблюдение Регламента.');
                    }
                    else if ($scope.showTariffs && $scope.viewModel.accredCommer && !$scope.viewModel.accredTariffSelected.id) {
                        deferred.reject('Не выбран тарифный план в секции "Участие в закупках".');
                    }
                    else if ($scope.form && !$scope.form.registerForm.$valid) {
                        deferred.reject('Необходимо заполнить обязательные поля на форме.');
                    }
                    else if (!$scope.checkApplications()) {
                        deferred.reject('Добавьте необходимые документы в секции "Электронные копии документов".');
                    }
                    else if($scope.viewModel.accredCommer && ['Z02', 'Z12'].includes($scope.viewModel.accredTariffSelected.code) && !$scope.msp){
                        deferred.reject('Выбранный тарифный план "' + $scope.viewModel.accredTariffSelected.name + ' (' + $scope.viewModel.accredTariffSelected.code + ')" ' +
                            'предназначен для представителей малого и среднего предпринимательства (МСП) и недоступен для подключения вашей организации. ' +
                            'Выберите другой тарифный план или установите признак "Субъект малого и среднего предпринимательства" на форме заявления, если его установка доступна для формы собственности вашей организации.');
                    }
                    else if($scope.showOtherTariffs&&$scope.accred.otherTariffCodes.length>0){
                        tariffsSrv.checkTariffsInvoiceWait($scope.accred.otherTariffCodes).then(function successCallback(response) {
                            if (response.data.success) {
                                var errMsg="";
                                if(response.data.result.length>0){
                                    response.data.result.forEach(function(code){
                                        $scope.tariffsOther.forEach(function(item){
                                            if(code==item.code&&item.select){
                                                item.state='wait';
                                                errMsg+="Счет на "+(item.group !== 'accreditation'?('тарифный план '):'')+ "\""+item.name+"\" ("+item.code+") уже выставлен, повторное формирование невозможно.</br>"
                                            }
                                        });
                                    });
                                    deferred.reject(errMsg);
                                }
                                else if($scope.user.mchd_req) {
                                    checkMchd().then(function successCallback(response) {
                                        if (response.data.success) {
                                            deferred.resolve();
                                        } else
                                            deferred.reject(response.data.errorMessage);
                                    }, function errorCallback() {
                                        deferred.reject();
                                    })
                                }
                                else
                                    deferred.resolve();
                            }
                        }, function errorCallback(response) {
                            console.log(response)
                        });
                    }
                    else if($scope.user.mchd_req) {
                        checkMchd().then(function successCallback(response) {
                            if (response.data.success) {
                                deferred.resolve();
                            } else
                                deferred.reject(response.data.errorMessage);
                        }, function errorCallback() {
                            deferred.reject();
                        })
                    } else deferred.resolve();
                    return deferred.promise;

                    //добавляем проверку срока действия доверенности по полю user_mchds.expired > текущей даты и времени.
                    function checkMchd() {
                        return httpSrv.http({
                            method: 'GET',
                            url: '/auth/user/checkMchdExpired'
                        });
                    }
                }
                
                /**
                 * Обновление реквизитов организации
                 */
                function registerFn(user) {
                    $scope.submitted = true;
                    $scope.processing = true;
                    validateAccred().then(function(){
                        if (user.middleName === undefined || user.middleName === "undefined") {
                            user.middleName = '';
                        }
                        httpSrv.http({
                            url: '/auth/org/generateXmlForSign',
                            data: $scope.accred.document,
                            block: true
                        }).then(function successCallback(response) {
                            if (response.data.success) {
                                cryptography.generateSignature(response.data.message, $scope.user.certId).then(function (value) {
                                    $scope.accred.signature = value;
                                    httpSrv.http({
                                        url: '/auth/org/storeAccreditation',
                                        data: $scope.accred,
                                        block: true
                                    }).then(function successCallback(response) {
                                        if (response.data.success) {
                                            var resultMsg = "";
                                            if($scope.accred.type === 'requisites_renew')
                                                resultMsg+="Новые документы и информация направлены Оператору электронной площадки.";
                                            else{
                                                resultMsg+="Заявка на аккредитацию отправлена Оператору";
                                                if($scope.showTariffs && $scope.viewModel.accredCommer && $scope.viewModel.accredTariffSelected.id){
                                                    resultMsg+=", документы на подключение тарифного плана "
                                                    + '"' + $scope.viewModel.accredTariffSelected.name + '"' +  " будут высланы Вам по почте после положительного рассмотрения заявки на аккредитацию";
                                                }
                                                if($scope.accred.otherTariffCodes.length>0){
                                                    resultMsg+=".</br>Документы на дополнительный тарифный план/услугу высланы Вам на почту";
                                                }
                                                resultMsg+=".</br>\nОжидайте результатов аккредитации.";
                                            }
                                            const profileTab = $scope.accred.type === 'requisites_renew' ? "requisites" : "accreditation";
                                            if ($scope.showTariffs && $scope.viewModel.accredCommer) {
                                                tariffsSrv.activateTariffByEmail($scope.viewModel.accredTariffSelected.id, $scope.accred.document.orgInfo.contactInfo.email).then(function (response2) {
                                                    if (!response2.data.success &&
                                                        !(profileTab === "accreditation" && response2.data.errorCode === '599'))
                                                        $scope.alertObjRegistrationSave = alertSrv.getErrorMsg(response2.data.message || response2.data.errorMessage);
                                                });
                                            }
                                            dialogSrv.showDialogMsgHtml($scope, resultMsg, function () {
                                                updateOrgInfo().then(function(){
                                                    $state.go('profile', {activeProfileTab:profileTab});
                                                    $scope.processing = false;
                                                })
                                            });

                                        } else {
                                            $scope.alertObjRegistrationSave = alertSrv.getErrorMsg(response.data.message || response.data.errorMessage);
                                            $scope.processing = false;
                                        }

                                    });

                                }, function errorCallback(response) {
                                    $scope.processing = false;
                                    if (response.indexOf('0x800B010A') >= 0) {
                                        $scope.alertObjRegistrationSave = alertSrv.getErrorMsg('Нет доверия к этому корневому сертификату центра сертификации, поскольку он не найден в хранилище доверенных корневых сертификатов центров сертификации.');
                                    } else {
                                        $scope.alertObjRegistrationSave = alertSrv.getErrorMsg('Функционал подписи недоступен! Сертификат просрочен или не установлен в хранилище "Личные".');
                                    }

                                    console.log(response)
                                });
                            } else {
                                $scope.alertObjRegistrationSave = alertSrv.getErrorMsg(response.data.errorMessage);
                                $scope.processing = false;
                            }
                        })
                    },function(reason){
                        $scope.alertObjRegistrationSave = alertSrv.getErrorMsg(reason);
                        $scope.processing = false;
                    });
                }

                function updateOtherTariffCodesFn(row) {
                    if (row.select) {
                        if ($scope.accred.otherTariffCodes.indexOf(row.code) < 0) {
                            $scope.accred.otherTariffCodes.push(row.code);
                            $scope.otherTariffsSelected.push(row);
                        }
                    } else {
                        if ($scope.accred.otherTariffCodes.indexOf(row.code) > -1)
                        {
                            $scope.accred.otherTariffCodes.splice($scope.accred.otherTariffCodes.indexOf(row.code), 1);
                            $scope.otherTariffsSelected.splice($scope.otherTariffsSelected.indexOf(row), 1);
                        }
                    }
                }

                function updateOrgInfo(){
                    return profileSrv.loadOrganizationInfo().then(function successCallback(response) {
                        if (response.data.success) {
                            var userData = response.data.result;
                            if (userData.lastName) userData.lastName = userData.lastName.replace("null", "");
                            if (userData.firstName) userData.firstName = userData.firstName.replace("null", "");
                            if (userData.middleName) userData.middleName = userData.middleName.replace("null", "");
                            authSrv.setProfileSuccess(userData.approvalDate !== undefined);
                            authSrv.setReglamentSuccess(userData.approvalReglamentDate !== undefined);
                            authSrv.setUserValid(profileSrv.validateProfileModel(userData));
                            profileSrv.setMaxCountAttachments(userData);
                            authSrv.setUser(userData);
                        }
                    });
                }
                function reasonReset() {
                    $scope.reason = {
                        'show': "",
                        'id': "",
                        'set': {},
                        'state':""
                    };
                    $scope.reasonRevoke = {
                        'show': "",
                        'id': "",
                        'set': {},
                        'state':""
                    };
                    $scope.changeMode = false;
                }

                /**
                 * Совпадает ли адрес с Юр.адресом
                 * @param checked
                 */
                $scope.checkedAddress = function (checked) {
                    if (checked) {
                        $scope.prevRealAddress = angular.copy($scope.accred.document.orgInfo.realAddress);
                        delete $scope.accred.document.orgInfo.realAddress;
                    } else {
                        $scope.accred.document.orgInfo.realAddress = angular.copy($scope.prevRealAddress);
                    }
                    //$scope.isChecked = !$scope.isChecked;
                    $scope.form.registerForm.$setDirty();
                };

                function cancelFn() {
                    if($scope.form.registerForm.$dirty && !$scope.disableForm){
                        needConfirmSrv.call($scope.goBack,[],'Внесенные изменения сохранены не будут');
                    }else{
                        $scope.goBack();
                    }
                }
                function checkOrgNameFn(orgName){
                    if($scope.user.mchdReq) return;
                    if (!orgName) {
                        $scope.orgNameCheckError = true;
                        return;
                    }
                    var cn = $scope.cn?$scope.cn.toLowerCase().replace(/\s/g,""):"",
                        name = orgName.toLowerCase().replace(/\s/g,"");

                    $scope.orgNameCheckError = (cn && cn !== name);
                }
                function checkingOrgName(){
                    if($scope.user.mchdReq) return;
                    checkOrgNameFn($scope.active.type===1 && $scope.accred.document ?
                        $scope.accred.document.orgInfo.legalEntity.fullName :
                        ($scope.active.type === 4 && $scope.accred.document ? $scope.accred.document.orgInfo.nonResident.fullName : $scope.user.fullName));
                    $scope.$apply();
                }

                function checkOgrnFilled(type, ogrn) {
                    if (type === 1 && $scope.accred.document && !$scope.accred.document.orgInfo.legalEntity.OGRN) {
                        $scope.accred.document.orgInfo.legalEntity.OGRN = ogrn;
                    } else if (type === 2 && $scope.accred.document && !$scope.accred.document.orgInfo.individualPerson.OGRN) {
                        $scope.accred.document.orgInfo.individualPerson.OGRN = ogrn;
                    }
                }
                function getOrn(subjectName) {
                    return convertCertField(subjectName.match(/ОГРН=[0-9\s]{1,}/g) || subjectName.match(/ОГРНИП=[0-9\s]{1,}/g) || subjectName.match(/1.2.643.100.1=[0-9\s]{1,}/g) || subjectName.match(/1.2.643.100.5=[0-9\s]{1,}/g));
                }

                function fastAccreditationFn(owner) {
                    httpSrv.http({
                        url: '/admin/processAccreditationPayment/' + owner.id
                    }).then(function (response) {
                        if (response.data.success) {
                            owner.fastAccreditation = true;
                        }
                    }, function (reason) {
                        console.log(reason)
                    })
                }

                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 */
                function refreshDictFn(object, value, refreshScrolling) {
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling)
                }

                function changeNrTypeFn(){
                    if(!$scope.accred.document.orgInfo.nonResident) $scope.accred.document.orgInfo.nonResident = {};
                    if ($scope.accred.document.orgInfo.nonResident.type === 'P'){
                        $scope.accred.document.orgInfo.nonResident.fullName = $scope.user.lastName + ' ' + $scope.user.firstName + ' ' + $scope.user.middleName;
                    } else{
                        $scope.accred.document.orgInfo.nonResident.fullName = $scope.oldFullName;
                    }
                }
                $scope.getMchdLink = (accred) => {
                    return `${$scope.mchdUrl}full?poanum=${accred.mchdId}&principal=${accred.mchdPrincipal}&agent=${accred.mchdAgent}&check=${accred.mchdCheck}`;
                }
            }]
    });

