/**
 * @author Belyaev Andrey
 */
angular.module('uetp')
    .component('contract', {
        templateUrl: ['$state', function ($state) {
            return 'app/components/procedures/contract/' + $state.current.name + '.html'
        }],
        controller: ['$rootScope', '$scope', '$state', '$stateParams', '$q', 'desktopSrv', 'authSrv', 'contractSrv', 'alertSrv', 'constants',
            'timeout', 'httpSrv', 'permissionSrv', 'dialogSrv', 'titleSrv', 'proceduresSrv', 'dictionarySrv', 'profileSrv', 'propertiesService', '$filter',
            function ($rootScope, $scope, $state, $stateParams, $q, desktopSrv, authSrv, contractSrv, alertSrv, constants,
                      timeout, httpSrv, permissionSrv, dialogSrv, titleSrv, proceduresSrv, dictionarySrv, profileSrv, propertiesService, $filter) {

                var ctrl = this,
                    certId = authSrv.getAuth().certId;
                $scope.auth = authSrv.getAuth();
                $scope.user = authSrv.getUser();
                $scope.chatViewInit = false;
                $scope.ctrl = ctrl;
                $scope.initView = false;
                $scope.infoView = false;
                $scope.contractId = $stateParams.id;
                $scope.activePanel = $stateParams.setActivePanel;
                $scope.userId = authSrv.getUser().id;
                $scope.cabType = desktopSrv.getCabType;
                $scope.projectList = [];
                $scope.projectListNew = [];
                $scope.ctrl.error = null;
                $scope.constants = constants;
                $scope.operatorSupplyMsg = true;
                $scope.winnerPaysMsg = true;
                $scope.supplyInfoMessage = false;
                $scope.winnerPaysInfoMessage = false;
                $scope.isDownloadProcess = false;
                $scope.packetId = null;
                $scope.selectedIndex = -1;
                $scope.actionsBtn = {
                    initSendToParticipant: {view: false, disabled: false},
                    sendToParticipant: {view: false, disabled: false},
                    rejectByParticipant: {view: false, disabled: false},
                    signByParticipant: {view: false, disabled: false},
                    signByCustomer: {view: false, disabled: false},
                    stopDocumentProcess: {view: false, disabled: false},
                    stopAdditionalProcess: {view: false, disabled: false}
                };
                $scope.goBack = goBackFn;
                $scope.goBackToContracts = goBackToContractsFn;
                $scope.deleteProjectDocument = deleteProjectDocumentFn;
                $scope.concludeContract = concludeContractFn;
                $scope.stopContractProcess = stopContractProcessFn;
                $scope.stopAdditionalProcess = stopAdditionalProcessFn;
                $scope.uploadSuccess = uploadSuccessFn;
                $scope.downloadFile = downloadFileFn;
                $scope.cancel = cancelFn;
                $scope.cancelRelatedDoc = cancelRelatedDocFn;
                $scope.checkSignatureParticipantView = checkSignatureParticipantViewFn;
                $scope.checkSignatureCustomerView = checkSignatureCustomerViewFn;
                $scope.signingDocuments = signingDocumentsFn;
                $scope.addProtocol = addProtocolFn;
                $scope.addRelatedDoc = addRelatedDocFn;
                $scope.setProtocol = setProtocolFn;
                $scope.organizerSend = organizerSendFn;
                $scope.deletePacket = deletePacketFn;
                $scope.canDeletePacket = canDeletePacketFn;
                $scope.checkPacketType = checkPacketTypeFn;
                $scope.finishedPaperAction = finishedPaperActionFn;
                $scope.finishedRejectAction = finishedRejectActionFn;
                $scope.rejectTypeDict = [
                    {id: 'rejectByOrganizer', name: 'Отказ от заключения договора поставщиком'},
                    {id: 'rejectByCustomer', name: 'Отказ от заключения договора заказчиком'}
                ];
                $scope.model = {};
                $scope.isFinished = isFinishedFn;
                $scope.isFinishedWithdrawn = isFinishedWithdrawnFn;
                $scope.hideNonActive = hideNonActiveFn;
                $scope.isShowNonActiveAction = false;
                $scope.isHideNonActive = $stateParams.isHideNonActive;
                $scope.isEditableContractNumber = isEditableContractNumberFn;
                $scope.isReadOnlyContractNumber = isReadOnlyContractNumberFn;
                $scope.isReadOnlyContractPriceNoNDS = isReadOnlyContractPriceNoNDSFn;
                $scope.isReadOnlyContractPriceWithNDS = isReadOnlyContractPriceWithNDSFn;
                $scope.isProcedureCustomer = isProcedureCustomerFn;
                $scope.contractSummNoNDSError = '';
                $scope.contractSummWithNDSError = '';
                $scope.getCurrencyPatternByCode = (code) => dictionarySrv.getCurrencyPatternByCode(code);
                $scope.getMchdStateDesc = profileSrv.getMchdStateDesc;
                const mchdErrors = ["427", "429", "430_2"];

                this.$onInit = function () {
                    $scope.certError = authSrv.isCertificateError;
                    if ($stateParams.contract) {
                        $scope.contract = $stateParams.contract;
                        if($scope.contract.packets){
                            $scope.sortedPackets = $scope.contract.packets.sort(function(v1, v2) {
                                const hasBtn1 = hasButtons(v1), hasBtn2 = hasButtons(v2);
                                if(v1.stage && v2.stage) {
                                    if(v1.stage < v2.stage) {
                                        return (hasBtn1 && hasBtn2 || !hasBtn1 && !hasBtn2) ? ((v1.stage < v2.stage) ? -1 : 1) : (hasBtn1 && !hasBtn2 ? 1 : -1);
                                    }
                                } else if(v1.sended && v2.sended) {
                                    return (hasBtn1 && hasBtn2 || !hasBtn1 && !hasBtn2) ? ((v1.sended < v2.sended) ? -1 : 1) : (hasBtn1 && !hasBtn2 ? 1 : -1);
                                }
                            });
                        }
                        $scope.procedure = $stateParams.procedure;
                        $scope.lotNumber = $stateParams.contract.lotNumber;
                        _initHeaderInfo($scope.contract);
                        _initActionBtn($scope.contract.actions);
                        if ($scope.actionsBtn.initSendToParticipant.view) {
                            $scope.initView = true;
                            $scope.chatViewInit = false;
                        }

                        _initModelPaperRejectFirst();
                        
                        $scope.infoView = true;

                        if($stateParams.procedure.contract) {
                            $scope.procedure.daysWaitingForSignContract = $stateParams.procedure.contract.procedure.daysWaitingForSignContract;
                            $scope.procedure.signContractAbilityDate = $stateParams.procedure.contract.procedure.signContractAbilityDate;
                            $scope.procedure.signContractAbility = $stateParams.procedure.contract.procedure.signContractAbility;
                        }

                        propertiesService.getProperty("mchd.url").then((host) => {
                            if (!host.endsWith('/')) host += '/';
                            $scope.mchdUrl = host;
                        });
                        
                        showNonActiveAction($scope.contract);
                        initTitle();
                    } else {
                        propertiesService.getProperty("mchd.url").then((host) => {
                            if (!host.endsWith('/')) host += '/';
                            $scope.mchdUrl = host;
                            getData().then(initTitle);
                        });
                    }

                };

                $scope.hasContractPermission = permissionSrv.hasContractPermission;
                $scope.hasContractOrSignPermission = hasContractOrSignPermissionFn;
                $scope.hasSignContractPermission = permissionSrv.hasSignContractPermission;
                $scope.hasAnyPermission = permissionSrv.hasAnyPermission;
                $scope.signDocumentsButtonActive = function () {
                    return $scope.procedure.signContractAbility && !$scope.actionsBtn.signByCustomer.disabled && $scope.hasSignContractPermission()
                }
                $scope.hasNoCertificate = function () {
                    return ['participant', 'organizer'].includes($scope.cabType()) && (!certId || certId==='withoutCertEnabled');
                }

                $scope.checkAvailableCbz = function(){
                    return $scope.cabType() === 'cbz' && proceduresSrv.isRZDOrChildRZD($scope.procedure);
                }

                /**
                 * получение данных по контракту, процедуре, лоту, получение хешев
                 */

                function getData() {
                    return new Promise(function (resolve, reject) {
                        return contractSrv.getContractProcess($scope.contractId).then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.contract = response.data.result;
                                if($scope.contract.packets){
                                    $scope.sortedPackets = $scope.contract.packets.sort(function(v1, v2) {
                                        const hasBtn1 = hasButtons(v1), hasBtn2 = hasButtons(v2);
                                        if(v1.stage && v2.stage) {
                                            if(v1.stage < v2.stage) {
                                                return (hasBtn1 && hasBtn2 || !hasBtn1 && !hasBtn2) ? ((v1.stage < v2.stage) ? -1 : 1) : (hasBtn1 && !hasBtn2 ? 1 : -1);
                                            }
                                        }
                                        if(v1.sended && v2.sended) {
                                            return (hasBtn1 && hasBtn2 || !hasBtn1 && !hasBtn2) ? ((v1.sended < v2.sended) ? -1 : 1) : (hasBtn1 && !hasBtn2 ? 1 : -1);
                                        }
                                    });
                                }
                                _initActionBtn($scope.contract.actions);
                                _initHeaderInfo($scope.contract);
                                $scope.lotNumber = $scope.contract.lot.number;
                                $scope.procedure = $scope.contract.procedure;
                                $scope.infoView = true;
                                if(!['prequalification', 'unlimitedprequalification'].includes($scope.procedure.type.id)){
                                     _initModel($scope.contract);
                                } else {
                                    $scope.model.contractNumber = $scope.contract.contractNumber;
                                    $scope.model.contractSumNoNDS = $scope.contract.lot.contractSumNoNDS;
                                    $scope.model.contractSumWithNDS = $scope.contract.lot.contractSumWithNDS;
                                }
                                showNonActiveAction($scope.contract);
                                resolve(response.data.result)
                            } else {
                                goBackFn();
                                reject(response.data)
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                            reject(response)
                        });
                    })

                }

                function hasButtons(packet) {
                    return packet.state.id === 'active' && !packet.signedByParticipant && !packet.signedByCustomer && packet.type !== 'rejectByParticipant';
                }
                function initTitle(){
                    titleSrv.setTitle($scope.procedure.number + ' | ' + $scope.procedure.type.name);
                    $scope.initSupplyInfoMessage();
                    $scope.initWinnerPaysMessage();
                }


                /**
                 * Определение типа пакета документов
                 */
                function checkPacketTypeFn(type, stage, sended) {
                	   if(type === 'finishedPaper'){
                		   return 'Договор в бумажной форме';
                   	   }else if(type === 'finishedReject'){
                		   return 'Отказ от договора';
                	   }else if(type === 'rejectByParticipant'){
                		   return 'Протокол разногласий';
                	   }else if(stage && stage > 0){
                	       return 'Дополнительное соглашение от ' + $filter('date')(sended, 'dd.MM.yyyy HH:mm');
                	   }else {  
                		   return 'Договор';
                	   }
                }

                /**
                 * Отправить договор участнику
                 */
                function organizerSendFn() {
                    if(!$scope.checkContractSumm()) return;
                    checkBeforeSignFn().then( function () {
                        if ($scope.actionsBtn.initSendToParticipant.view) {
                            initSendFn()
                        } else {
                            subsequentSendFn();
                        }
                    }, function errorCallback(response) {
                        if(response)
                            console.log(response);
                    });
                }

                /**
                 * Удалить договор
                 */
                function deletePacketFn(packetId) {
                    contractSrv.deletePacket(packetId).then(function successCallback(response) {
                        if (response.data.success) {
                            reloadContract();
                        } else if (response.data.message) {
                            if (response.data.errorCode == "143") {
                                dialogSrv.showDialogMsg($scope, response.data.message, function () {
                                    reloadContract();
                                });
                            } else {
                                $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response.data.message);
                            }
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }


                /**
                 * Отображать ли кнопку удаления договора
                 */
                function canDeletePacketFn(packet, nextPacket) {
                    return $scope.cabType() === 'organizer' && this.isProcedureCustomer() && $scope.hasAnyPermission()
                    && packet.state?.id === 'inactive' && !packet.signedByParticipant
                        && ['initSendToParticipant', 'sendToParticipant'].includes(packet.type) && !isPacketRejectedByParticipant(packet, nextPacket);
                }

                /**
                 * Был ли направлен протокол разногласий
                 */
                function isPacketRejectedByParticipant(packet, nextPacket) {
                    return nextPacket && nextPacket.stage === packet.stage && nextPacket.type === 'rejectByParticipant';
                }

                /**
                 * Отправить дополнительный договор участнику
                 */
                function subsequentSendFn() {
                    contractSrv.subsequentSend($scope.contractId, $scope.packetId, conversionFiles('projectList')).then(function successCallback(response) {
                        if (response.data.success) {
                            reloadContract()
                        } else if (response.data.message) {
                            $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }

                /**
                 * Отправить новый договор участнику (первый)
                 */
                function initSendFn() {
                    contractSrv.initSend($scope.contract.lot && $scope.contract.lot.id ? $scope.contract.lot.id : $scope.contract.lotId, $scope.contract.customer.id, $scope.contract.participant.id, $scope.packetId, conversionFiles('projectList')).then(function successCallback(response) {
                        if (response.data.success) {
                            reloadContract(response.data.result);
                        } else if (response.data.message) {
                            $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response.data.message);
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }

                /**
                 * открытие блока "Проект договора"
                 */
                function concludeContractFn(packedId) {
                    restProjectList();
                    $scope.chatViewInit = true;
                    $scope.packetId = packedId === undefined ? 0 : packedId; //при создании новых договоров/доп соглашений заполняем 0 для корректной ссылки на бэк
                }

                /**
                 * открытие блока "Протокол разногласий", преобразование списка документов
                 */
                function addProtocolFn(packetId) {
                    restProjectList();
                    $scope.newProtocol = true;
                    $scope.packetId = packetId;
                }

                /**
                 * открытие блока "Сопутствующие документы", преобразование списка документов
                 */
                function addRelatedDocFn(packetId) {
                    restProjectList();
                    $scope.newRelatedDoc = true;
                    $scope.packetId = packetId;
                }

                /**
                 * удаление файла из списка
                 */
                function deleteProjectDocumentFn(list, index) {
                    const fileName = $scope[list][index].fileName;
                    $scope[list].splice(index, 1);
                    const duplicateList = (list==='projectList' ? 'projectListNew' : 'projectList');
                    const el = $scope[duplicateList].filter(data => data.fileName === fileName);
                    if(el.length > 0)
                        $scope[duplicateList].splice($scope[duplicateList].indexOf(el[0]), 1);
                }

                /**
                 * преобразование списка документов для отправки на сервер
                 */
                function conversionFiles(list, sendContractNumber = true) {
                    var data = {
                        files: []
                    };
                    angular.forEach($scope[list], function (item) {
                        data.files.push(item.id);
                    });
                    if(sendContractNumber) {
                        data.contractNumber = $scope.model.contractNumber;
                        data.contractSumNoNDS = $scope.model.contractSumNoNDS;
                        data.contractSumWithNDS = $scope.model.contractSumWithNDS;
                    }

                    return data
                }

                /**
                 * отмена изменений и скрытие блоков "Протокол разногласий" и "Проект договора"
                 */
                function cancelFn() {
                    restProjectList();
                    $scope.chatViewInit = false;
                    $scope.newProtocol = false;
                    $scope.packetId = undefined;
                }

                /**
                 * сброс списков документов
                 */
                function restProjectList() {
                    $scope.projectList = [];
                    $scope.projectListNew = [];
                }

                /**
                 * добавление документов в список после загрузки на сервер
                 */
                function uploadSuccessFn(result) {
                    let errorText="";
                    angular.forEach(result, function (doc) {
                        if(isExistFileName($scope.projectList, doc.fileName) || isExistFileName($scope.projectListNew, doc.fileName)) {
                            if(errorText.length > 0)
                                errorText +="<br/><br/>"
                            errorText +="Файл " + doc.fileName + " не добавлен. <br/>" +
                                "Файл с таким именем уже был добавлен ранее.";
                        } else {
                            $scope.projectList.push(doc);
                            $scope.projectListNew.push(doc);
                        }
                    });
                    if(errorText.length > 0)
                        $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(errorText);
                }

                function isExistFileName(dataArray, fileName){
                    if (!dataArray || !dataArray.length)
                        return false;
                    return dataArray.filter(data => data.fileName == fileName).length > 0;
                }

                /**
                 * отмена изменений и скрытие блока "Сопроводительные документы"
                 */
                function cancelRelatedDocFn() {
                    restProjectList();
                    $scope.newRelatedDoc = false;
                    $rootScope.$broadcast('setShowThrobber', false);
                }

                /**
                 * отправка протокола разногласий
                 */
                function setProtocolFn() {
                    if ($scope.cabType() === 'participant') {
                        contractSrv.rejectParticipant($scope.contractId, $scope.packetId, conversionFiles('projectListNew')).then(function successCallback(response) {
                            if (response.data.success) {
                                reloadContract();
                            } else if (response.data.message) {
                                if(response.data.errorCode == "143"){
                                    dialogSrv.showDialogMsg($scope, response.data.message, function(){
                                        reloadContract();
                                    });
                                }else{
                                    $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response.data.message);
                                }
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    } else {
                        contractSrv.subsequentSend($scope.contractId, $scope.packetId, conversionFiles('projectListNew')).then(function successCallback(response) {
                            if (response.data.success) {
                                reloadContract();
                            } else if (response.data.message) {
                                $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response.data.message);
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    }
                }
                /**
                 * Прекратить работу с договором
                 */
                function stopContractProcessFn() {
                    contractSrv.stopContractProcess($scope.contractId).then(function successCallback(response) {
                        if (response.data.success) {
                            reloadContract();
                        } else if (response.data.message) {
                            if (response.data.errorCode == "143") {
                                dialogSrv.showDialogMsg($scope, response.data.message, function () {
                                    reloadContract();
                                });
                            } else {
                                $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response.data.message);
                            }
                        }
                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }

                /**
                 * Прекратить работу с дополнительным соглашением
                 */
                function stopAdditionalProcessFn(packetId) {
                  contractSrv.stopAdditionalProcess($scope.contractId, packetId).then(function successCallback(response) {
                     if (response.data.success) {
                         reloadContract();
                     } else if (response.data.message) {
                         $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response.data.message);
                     }
                   }, function errorCallback(response) {
                         console.log(response);
                   });
                }

                /**
                 * подпись договора
                 */
                $scope.$on('setShowThrobber', function (event, data) {
                    if($scope.isDownloadProcess)
                        return;
                    $scope.showThrobber = data;
                    timeout($scope);
                });

                function signingDocumentsFn(packetId, index) {
                    if(!$scope.checkContractSumm()) return;
                    $rootScope.$broadcast('setShowThrobber', true);
                    $scope.selectedIndex = index;
                    checkBeforeSignFn().then( doSigningDocumentsFn(packetId), function () {
                        $rootScope.$broadcast('setShowThrobber', false);
                        $scope.selectedIndex = -1;
                    }, function errorCallback(response) {
                        if(response)
                            console.log(response);
                    });
                }

                async function checkBeforeSignFn() {
                    if(!['prequalification', 'unlimitedprequalification'].includes($scope.procedure.type.id) && (!$scope.contract?.state || ['signByParticipant', 'finished', 'finishedWithdrawn'].includes($scope.contract?.state?.id))){

                        if ($scope.contract.form.$invalid) {
                            $scope.submitted = true;
                            $scope.ctrl.error = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', $scope.focusFirstInvalid, null);
                            throw new Error();

                        } else if ($scope.model.contractSumNoNDS > $scope.model.contractSumWithNDS && $scope.contract.procedure.section !== '44') {
                            $scope.ctrl.error = alertSrv.getErrorMsgWithBtn('Сумма договора без НДС не может быть больше Суммы договора с НДС');
                            throw new Error();
                        }

                        if('simplecontest'!==$scope.procedure.type.id) {
                            return;
                        }

                        const response = await contractSrv.getContractSumByClaim($scope.contract.lotId||$scope.contract.lot.id, $scope.contract.participant.id);
                        if (response?.data?.success && response?.data?.result) {

                            const contractSumNoNDS = response.data.result.contractSumNoNDS, contractSumWithNDS = response.data.result.contractSumWithNDS;
                            const is44Section = $scope.contract.procedure.section === '44', msgNoNDS = !is44Section ? "без НДС " : "", msgWithNDS = !is44Section ? "с НДС " : "";

                            if (contractSumNoNDS && contractSumNoNDS < $scope.model.contractSumNoNDS) {
                                $scope.ctrl.error = alertSrv.getErrorMsgWithBtn("Сумма договора " + msgNoNDS + "не может быть больше цены заявки " + msgNoNDS + "из протокола комиссии");
                                throw new Error();
                            }

                            if (contractSumWithNDS && contractSumWithNDS < $scope.model.contractSumWithNDS) {
                                $scope.ctrl.error = alertSrv.getErrorMsgWithBtn("Сумма договора " + msgWithNDS + "не может быть больше цены заявки " + msgWithNDS + "из протокола комиссии");
                                throw new Error();
                            }

                        } else {
                            console.log(response);
                            $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response?.data?.message);
                            throw new Error(response?.data?.message);
                        }
                    }
                }

                function doSigningDocumentsFn(packetId) {
                    if(certId) {
                        contractSrv.getDocumentsDataForSign(certId, $scope.contractId, packetId === undefined ? $scope.packetId : packetId, $scope.cabType() === 'participant' ? conversionFiles('projectListNew', false) : null).then(
                            function (response) {
                                if (response.data && response.data.success) {
                                    if (response.data.result.length > 0) {
                                        sendAndSign(response.data.result, packetId === undefined ? $scope.packetId : packetId)
                                    } else {
                                        $scope.ctrl.error = alertSrv.getErrorMsgWithBtn("Нет документов для подписи!");
                                        $rootScope.$broadcast('setShowThrobber', false);
                                    }
                                } else if (response.data.message) {
                                    if (["143", "148"].includes(response.data.errorCode)) {
                                        dialogSrv.showDialogMsg($scope, response.data.message, function () {
                                            reloadContract();
                                        });
                                    } else if(mchdErrors.includes(response.data.errorCode)) {
                                        $scope.errorMsg = response.data.errorMessage.replace('\n','<br/>');
                                        setTimeout(() => delete $scope.errorMsg, 7000);
                                    } else {
                                        $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(mchdErrors.includes(response.data.errorCode) && response.data.errorMessage ? response.data.errorMessage : response.data.message);
                                        $rootScope.$broadcast('setShowThrobber', false);
                                    }
                                    $rootScope.$broadcast('setShowThrobber', false);
                                }
                            },
                            function (response) {
                                if(mchdErrors.includes(response.data.errorCode)) {
                                    $scope.errorMsg = response.data.errorMessage.replace('\n','<br/>');
                                    setTimeout(() => delete $scope.errorMsg, 7000);
                                } else {
                                    $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(mchdErrors.includes(response.data.errorCode) && response.data.errorMessage ? response.data.errorMessage : response.data.message);
                                    $rootScope.$broadcast('setShowThrobber', false);
                                }
                            }
                        );
                    } else {
                        $scope.ctrl.error = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                        $rootScope.$broadcast('setShowThrobber', false);
                    }
                }

                function sendAndSign(data, packetId) {
                    contractSrv.signDocument(data, certId).then(
                        function (signData) {
                            if ($scope.cabType() === 'participant') {
                                contractSrv.signingDocumentsParticipant($scope.contractId, packetId, signData).then(function successCallback(response) {
                                    if (response.data.success) {
                                        reloadContract();
                                    } else if (response.data.message) {
                                        if (response.data.errorCode == "143") {
                                            dialogSrv.showDialogMsg($scope, response.data.message, function () {
                                                reloadContract();
                                            });
                                        } else {
                                            $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response.data.message);
                                            $rootScope.$broadcast('setShowThrobber', false);
                                        }
                                    }
                                }, function errorCallback(response) {
                                    console.log(response);
                                });
                            } else {
                                var contractData = {
                                    hashes: signData,
                                    contractSumNoNDS: $scope.model.contractSumNoNDS,
                                    contractSumWithNDS: $scope.model.contractSumWithNDS,
                                    contractNumber: $scope.model.contractNumber
                                };
                                contractSrv.signingDocumentsOrganizer($scope.contractId, packetId, contractData).then(function successCallback(response) {
                                    if (!response.data.success) {
                                        $scope.ctrl.error = alertSrv.getErrorMsg("Ошибка подписи документа. " + (response.data.errorMessage ? response.data.errorMessage : response.data.message));
                                        $rootScope.$broadcast('setShowThrobber', false);
                                    } else {
                                        reloadContract();
                                    }
                                }, function errorCallback(response) {
                                    console.log(response);
                                });
                            }
                        },
                        function (reason) {
                            if (typeof reason === 'string') {
                                $scope.ctrl.error = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason);
                            } else {
                                $scope.ctrl.error = alertSrv.getErrorMsg("Ошибка подписи документа. " + reason.name + ': ' + reason.message);
                            }
                            $rootScope.$broadcast('setShowThrobber', false);
                        }
                    );
                }

                /**
                 * отображение данных в зависимости от роли
                 */
                function checkSignatureParticipantViewFn(item) {
                    return (item.signParticipantFileId && item.certParticipantFileId)
                }

                function checkSignatureCustomerViewFn(item) {
                    return (item.signCustomerFileId && item.certCustomerFileId)
                }

                function goBackFn() {
                    history.back();
                }

                /**
                 * возврат к договорам
                 */
                function goBackToContractsFn() {
                    $state.go('openProcedure', {
                        procType: $scope.procedure.type.id,
                        procId: $scope.procedure.id,
                        setActivePanel: 'contracts'
                    }, {reload: true});
                }

                /**
                 * Обновляем state
                 * @param id - если создается договор с нуля, то надо сменить id состояния для перезагрузки
                 */
                function reloadContract(id) {
                    var params = id ? {id: id} : $stateParams;
                    params.isHideNonActive = $scope.isHideNonActive;
                    $state.transitionTo($state.current, params, {
                        reload: true, inherit: false, notify: true, location: 'replace'
                    });
                }

                /**
                 * Выставлени видимости кнопок
                 */
                function _initActionBtn(actions) {
                    if (actions && actions.length > 0) {
                        actions.forEach(function (action) {
                            $scope.actionsBtn[action.id] = {view: true, disabled: action.disabled}
                        })
                    }
                }

                /**
                 * Инициализация шапки договора
                 */
                function _initHeaderInfo(contract) {
                    if (contract.participant) {
                        var participantName = "";
                        if (contract.participant.inn) {
                            participantName = "ИНН " + contract.participant.inn + ", ";
                        } else if (contract.participant.uin) {
                            participantName = "УИН " + contract.participant.uin + ", ";
                        }
                        $scope.participantName = participantName + contract.participant.name;
                    }
                    if (contract.customer) {
                        var customerName = "";
                        if (contract.customer.inn) {
                            customerName = "ИНН " + contract.customer.inn + ", ";
                        } else if (contract.customer.uin) {
                            customerName = "УИН " + contract.customer.uin + ", ";
                        }
                        $scope.customerName = customerName + contract.customer.name;
                    }
                }
                
                function _initModelPaperRejectFirst() {
                    if(['contract','contractPaper'].includes($state.current.name)) {
                        $scope.model.contractSumNoNDS = $scope.contract.contractSumNoNDS;
                        $scope.model.contractSumWithNDS = $scope.contract.contractSumWithNDS;
                        $scope.contract.procedure = $scope.procedure;
                    } else if($state.current.name === 'contractReject'){
                		 $scope.model.rejectType = 'rejectByOrganizer';
                	}
                }
                
                function _initModel(contract) {
                    $scope.model.contractNumber = contract.contractNumber;
                    if(contract.lot){
                      $scope.model.contractSumNoNDS = contract.lot.contractSumNoNDS;
                      $scope.model.contractSumWithNDS = contract.lot.contractSumWithNDS;
                    } else {
                        $scope.model.contractSumNoNDS = contract.contractSumNoNDS;
                        $scope.model.contractSumWithNDS = contract.contractSumWithNDS;
                    }
                   if(isFinishedFn()){
                	   $scope.model.conclusionDate = contract.conclusionDate;
                	   $scope.model.rejectReason = contract.rejectReason;
                	   $scope.model.rejectType = contract.rejectType;
                   }
                   
                   if($state.current.name === 'contractReject' && !isFinishedFn()){
                	   $scope.model.rejectType = 'rejectByOrganizer';
                   }
                }
                
                function finishedPaperActionFn() {
                    if(!$scope.checkContractSumm(true)) return;

                	if($stateParams.contract){
                		finishedPaperInitSendFn();
                	}else{
                		finishedPaperSendFn();
                	}
                }
                
                function finishedRejectActionFn() {
                	if($stateParams.contract){
                		finishedRejectInitSendFn();
                	}else{
                		finishedRejectSendFn();
                	}
                }

                async function checkPaperContract() {
                    if ($scope.contract.form.$invalid) {
                        $scope.submitted = true;
                        $scope.ctrl.error = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', $scope.focusFirstInvalid, null);
                        throw new Error();

                    } else if($scope.model.contractSumNoNDS > $scope.model.contractSumWithNDS && $scope.contract.procedure.section !== '44'){
                        $scope.ctrl.error = alertSrv.getErrorMsgWithBtn('Сумма договора без НДС не может быть больше Суммы договора с НДС');
                        throw new Error();
                    }

                    if('simplecontest'!==$scope.procedure.type.id) {
                        return;
                    }

                    const response = await contractSrv.getContractSumByClaim($scope.contract.lotId||$scope.contract.lot.id, $scope.contract.participant.id);
                    if(response?.data?.success && response?.data?.result){
                        const contractSumNoNDS = response.data.result.contractSumNoNDS,
                            contractSumWithNDS = response.data.result.contractSumWithNDS;
                        const is44Section = $scope.contract.procedure.section === '44',
                            msgNoNDS = !is44Section ? "без НДС " : "",
                            msgWithNDS = !is44Section ? "с НДС " : "";

                        if (contractSumNoNDS && contractSumNoNDS < $scope.model.contractSumNoNDS) {
                            $scope.ctrl.error = alertSrv.getErrorMsgWithBtn("Сумма договора " + msgNoNDS + "не может быть больше цены заявки " + msgNoNDS + "из протокола комиссии");
                            throw new Error();
                        }

                        if (contractSumWithNDS && contractSumWithNDS < $scope.model.contractSumWithNDS) {
                            $scope.ctrl.error = alertSrv.getErrorMsgWithBtn("Сумма договора " + msgWithNDS + "не может быть больше цены заявки " + msgWithNDS + "из протокола комиссии");
                            throw new Error();
                        }

                    } else {
                        console.log(response);
                        $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response?.data?.message);
                        throw new Error();
                    }
                }

                function finishedPaperInitSendFn() {
                	
                    checkPaperContract().then(function() {
                        const contractData =  {files: conversionFiles('projectList').files,
                            contractSumNoNDS: $scope.model.contractSumNoNDS,
                            contractSumWithNDS: $scope.model.contractSumWithNDS,
                            contractNumber: $scope.model.contractNumber,
                            conclusionDate: $scope.model.conclusionDate};

                        contractSrv.finishedPaperInitSend($scope.contract.lotId, $scope.contract.customer.id, $scope.contract.participant.id, contractData).then(function successCallback(response) {
                              if (response.data.success) {
                                  reloadContract(response.data.result);
                              } else if (response.data.message) {
                                  $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response.data.message);
                              }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    }, function errorCallback(response) {
                        if(response)
                            console.log(response);
                    });
                }
                
                function finishedRejectInitSendFn() {
                	var contractData =  {files: conversionFiles('projectList').files, 
                			             rejectReason: $scope.model.rejectReason,
                			             rejectType: $scope.model.rejectType};
                	contractSrv.finishedRejectInitSend($scope.contract.lotId, $scope.contract.customer.id, $scope.contract.participant.id, contractData).then(function successCallback(response) {
                         if (response.data.success) {
                             reloadContract(response.data.result);
                         } else if (response.data.message) {
                             $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response.data.message);
                         }
                    }, function errorCallback(response) {
                         console.log(response);
                    });
                }
                
                function finishedPaperSendFn() {
                	
                    checkPaperContract().then(function() {
                        const contractData = {
                            files: conversionFiles('projectList').files,
                            contractSumNoNDS: $scope.model.contractSumNoNDS,
                            contractSumWithNDS: $scope.model.contractSumWithNDS,
                            contractNumber: $scope.model.contractNumber,
                            conclusionDate: $scope.model.conclusionDate
                        };

                        contractSrv.finishedPaperSend($scope.contractId, contractData).then(function successCallback(response) {
                            if (response.data.success) {
                                reloadContract();
                            } else if (response.data.message) {
                                $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response.data.message);
                            }
                        }, function errorCallback(response) {
                            console.log(response);
                        });
                    }, function errorCallback(response) {
                        if(response)
                            console.log(response);
                    });
                }
                
                function finishedRejectSendFn() {
            	 	var contractData =  {files: conversionFiles('projectList').files, 
   			            rejectReason: $scope.model.rejectReason,
   			            rejectType: $scope.model.rejectType};
 
                   contractSrv.finishedRejectSend($scope.contractId, contractData).then(function successCallback(response) {
                       if (response.data.success) {
                           reloadContract();
                       } else if (response.data.message) {
                           $scope.ctrl.error = alertSrv.getErrorMsgWithBtn(response.data.message);
                       }
                   }, function errorCallback(response) {
                       console.log(response);
                   });
                }
               
                function isFinishedFn() {
                    if(isFinishedWithdrawnFn() && isProcedureCustomerFn()) {
                        return false;
                    }
            	    return $scope.contract && $scope.contract.state && $scope.contract.state.id && $scope.contract.state.id.indexOf('finished') >= 0;
                }

                $scope.isContractSigned = function() {
                    if($scope.contract && $scope.contract.packets){
                        return $scope.contract.packets.find(p => p.state?.id === 'active' && p.stage === 0 && p.signedByParticipant && p.signedByCustomer);
                    }
                    return false;
                }

                $scope.isContractOnSign = function(contract) {
                    if(contract && contract.packets){
                       return contract.packets.find(p => p.state?.id === 'active' && p.stage === 0 && p.type!=='rejectByParticipant' && (!p.signedByParticipant && !p.signedByCustomer  || p.signedByParticipant && !p.signedByCustomer));
                    }
                    return false;
                }

                $scope.isAdditionalContractOnSign = function() {
                    if($scope.contract && $scope.contract.packets){
                        return $scope.contract.packets.find(p => p.state?.id === 'active' && p.stage !== 0
                            && !p.signedByParticipant && !p.signedByCustomer && p.type!=='rejectByParticipant');
                    }
                    return false;
                }

                $scope.getContractState = function() {
                    // если основной контракт подписан, смотрим допники
                    if($scope.isContractSigned()){
                        if($scope.contract && $scope.contract.packets){
                            if($scope.contract.packets.find(p => p.state?.id === 'active' && p.stage !== 0
                                && !p.signedByParticipant && !p.signedByCustomer && p.type!=='rejectByParticipant'))
                                return "Проект дополнительного соглашения направлен участнику";
                            else if($scope.contract.packets.find(p => p.state?.id === 'active' && p.stage !== 0
                                && p.signedByParticipant && !p.signedByCustomer && p.type!=='rejectByParticipant'))
                                return "Дополнительное соглашение подписано участником";
                        }
                    }
                    return $scope.contract?.state?.name || 'Договор не заключен';
                }

                function isFinishedWithdrawnFn() {
                    return $scope.contract && $scope.contract.state && $scope.contract.state.id && $scope.contract.state.id==='finishedWithdrawn';
                }

                function hasContractOrSignPermissionFn(){
                    return permissionSrv.hasWorkflowOrContractPermission() || permissionSrv.hasSignContractPermission();
                }
               
                function hideNonActiveFn() {
            	    $scope.isHideNonActive = !$scope.isHideNonActive;
                }
               
                function showNonActiveAction(contract) {
                    if(contract && contract.packets){
                      angular.forEach(contract.packets, function (item) {
                         if(item.state?.id !== 'active'){
                            $scope.isShowNonActiveAction = true;
                         }
                      });
                    }
                }

                function isReadOnlyContractNumberFn(isSC) {
                    const res = !isEditableContractNumberFn(isSC) && $scope.procedure && $scope.model.contractNumber;
                    return isSC ? res && $scope.procedure.type.id === 'simplecontest' : res && $scope.procedure.type.id !== 'simplecontest';
                }
                function isReadOnlyContractPriceNoNDSFn(isSC) {
                    const res = !isEditableContractNumberFn(isSC) && $scope.procedure && $scope.model.contractSumNoNDS;
                    return isSC ? res && $scope.procedure.type.id === 'simplecontest' : res && $scope.procedure.type.id !== 'simplecontest';
                }
                function isReadOnlyContractPriceWithNDSFn(isSC) {
                    const res = !isEditableContractNumberFn(isSC) && $scope.procedure && $scope.model.contractSumWithNDS;
                    return isSC ? res && $scope.procedure.type.id === 'simplecontest' : res && $scope.procedure.type.id !== 'simplecontest';
                }
                function isEditableContractNumberFn(isSC) {
                    const res = $scope.procedure && $scope.cabType() ==='organizer' &&
                            $scope.contract.customer.inn === $scope.user.inn && !($scope.actionsBtn.stopContractProcess && $scope.actionsBtn.stopContractProcess.view || $scope.actionsBtn.stopAdditionalProcess && $scope.actionsBtn.stopAdditionalProcess.view) &&
                            !($scope.contract.state && $scope.contract.state.id === 'finished' || $scope.contract.stage > 0);
                    return isSC ? res && $scope.procedure.type.id === 'simplecontest' : res && $scope.procedure.type.id !== 'simplecontest';
                }

                function getActivePacketId(packetId){
                    if($scope.contract && $scope.contract.packets) {
                        const packet= $scope.contract.packets.find(item => item.state?.id === 'active' && $scope.packetId === item.id);
                        return packet ? packet.id : undefined;
                    }
                }

                function isProcedureCustomerFn(){
                    return $scope.contract?.customer?.inn === $scope.user?.inn;
                }

                function downloadFileFn(url, filename) {
                    $scope.isDownloadProcess = true;
                    httpSrv.downloadFile(url, filename, filename);
                    $scope.isDownloadProcess = false;
                }

                $scope.emptyMoney = [0, '0','0,','0.','0.0','0,0','0.00','0,00','0.000','0,000','0.0000','0,0000','',' ',undefined];

                $scope.checkContractSumm = function (mandatory) {
                    const mand = mandatory || $scope.procedure.type.id === 'simplecontest';
                    $scope.contractSummWithNDSError = '';
                    if($scope.procedure.type.id === 'simplecontest' && $scope.procedure.section === '44') {
                        if ($scope.emptyMoney.includes($scope.model.contractSumWithNDS)) $scope.contractSummWithNDSError = "Сумма договора должна быть больше 0"
                    } else {
                        $scope.contractSummNoNDSError = '';

                        if($state.current.name === 'contractPaper' && $scope.procedure.type.id !== 'simplecontest' && $scope.model.contractSumNoNDS === undefined && $scope.model.contractSumWithNDS === undefined) {
                            // Сбрасываем ошибки для связанных полей contractSumNoNDS и contractSumWithNDS
                            $scope.contract.form['contractSumNoNDS'].$error = {};
                            $scope.contract.form['contractSumWithNDS'].$error = {};
                            $scope.contract.form['contractSumNoNDS'].$setValidity('contractSumNoNDS', true);
                            $scope.contract.form['contractSumWithNDS'].$setValidity('contractSumWithNDS', true);
                            if($scope.contract.form.$error.parse)
                                $scope.contract.form.$error.parse = $scope.contract.form.$error?.parse?.filter(errEl => !['contractSumNoNDS','contractSumWithNDS',undefined].includes(errEl.$name));
                            if($scope.contract.form.$error?.parse?.length === 0)
                                delete $scope.contract.form.$error.parse;
                            $scope.contract.form.$setPristine();
                            return true;
                        }
                        if (!mand && $scope.model.contractSumNoNDS === undefined && $scope.model.contractSumWithNDS === undefined) {
                            $scope.contract.form.$setPristine();
                            return true;
                        }
                        if ($scope.emptyMoney.includes($scope.model.contractSumNoNDS)) {
                            $scope.contractSummNoNDSError = "Сумма договора без НДС должна быть больше 0";
                            if($scope.contract.form['contractSumNoNDS']) {
                                $scope.contract.form['contractSumNoNDS'].$setValidity('contractSumNoNDS', false);
                                $scope.contract.form['contractSumNoNDS'].$setDirty();
                            }
                        }
                        if ($scope.emptyMoney.includes($scope.model.contractSumWithNDS)) {
                            $scope.contractSummWithNDSError = "Сумма договора с НДС должна быть больше 0";
                            if($scope.contract.form['contractSumWithNDS']) {
                                $scope.contract.form['contractSumWithNDS'].$setValidity('contractSumWithNDS', false);
                                $scope.contract.form['contractSumWithNDS'].$setDirty();
                            }
                        }
                        const summNoNds = !$scope.model.contractSumNoNDS ? 0 : $scope.model.contractSumNoNDS,
                            summWithNds = !$scope.model.contractSumWithNDS ? 0 : $scope.model.contractSumWithNDS;
                        if (summNoNds > 0 && summWithNds > 0 && summNoNds > summWithNds) {
                            $scope.contractSummWithNDSError = "Сумма договора без НДС должна быть меньше или равна Сумме договора с НДС";
                        }
                    }
                    return !$scope.contractSummNoNDSError && !$scope.contractSummWithNDSError;
                }

                $scope.getCurrencyCode = function() {
                    return $scope.contract?.lot?.currencyCode || $scope.contract?.currencyCode;
                }

                $scope.getDictName = function(dict, val) {
                    return (dict.find(r=>r.id===val)||[]).name;
                }
                $scope.closeSupplyMsg = function () {
                    $scope.operatorSupplyMsg = false;
                }
                $scope.closeWinnerPaysMsg = function () {
                    $scope.winnerPaysMsg = false;
                }
                $scope.initSupplyInfoMessage = function(forceShowInfo = false) {
                    if($scope.contract.stage > 0 || !(!['prequalification', 'unlimitedprequalification'].includes($scope.procedure.type.id) &&
                            $scope.operatorSupplyMsg && (!$scope.contract.lot || ($scope.contract.lot?.applicationDepositNeeded || $scope.contract?.applicationDepositNeeded)==='operatorSupply')) ||
                        $scope.cabType() !== 'organizer' || $scope.contract.customer.inn !== $scope.user.inn || $scope.contract.applicationDepositNeeded === 'customerSupply') {
                        return;
                    }

                    if(forceShowInfo) {
                        $scope.operatorSupplyMsg = true;
                    }

                    const currentLot = $scope.procedure?.lots?.find(lot => lot.id === $scope.contract.lotId);
                    if(!$scope.contract.lot && $scope.contract.lotInfoState?.id === 'contract_not_started' && (!currentLot?.applicationSupplyNeeded || currentLot?.applicationSupplyNeeded === false) ) {
                        return;
                    }

                    if($scope.contract?.participant?.providedBankGuarantee) {
                        return;
                    }

                    if( $state.current.name==='contract' && !$scope.chatViewInit) {
                        if($scope.canBeSignByOrganizer()) {
                            $scope.supplyInfoMessage = 'При подписании договора, для участника, с которым заключается договор, будет прекращено блокирование денежных средств, заблокированных на счете Оператора КОМИТА ЭТП для обеспечения заявки.';
                        }
                    } else if($state.current.name==='contractPaper'&& (!$scope.contract.lot || !$scope.isFinished()) &&
                        $scope.hasSignContractPermission() && !$scope.contract.alertMessage){
                        $scope.supplyInfoMessage = 'При подтверждении заключения договора в бумажной форме, для участника, с которым заключается договор, будет прекращено блокирование денежных средств, заблокированных на счете Оператора КОМИТА ЭТП для обеспечения заявки.';
                    } else if($state.current.name==='contractReject'&& !$scope.isFinished() && $scope.hasSignContractPermission() &&
                         !$scope.contract.alertMessage){
                        $scope.supplyInfoMessage = ($scope.model.rejectType==='rejectByOrganizer' ?
                            'При подтверждении отказа от заключения договора поставщиком, его денежные средства, заблокированные на счете Оператора КОМИТА ЭТП для обеспечения заявки, будут перечислены в пользу заказчика на расчетный счет заказчика, указанный при публикации извещения.' :
                            $scope.model.rejectType==='rejectByCustomer' ? 'При подтверждении отказа от заключения договора заказчиком, для участника, с которым должен был быть заключен договор, будет прекращено блокирование денежных средств, заблокированных на счете Оператора КОМИТА ЭТП для обеспечения заявки.' : '');
                    }
                }

                $scope.initWinnerPaysMessage = function(forceShowInfo = false) {

                    const lot = !$scope.contract?.lot ? $scope.procedure.lots.find(l=>l.ordinalNumber===$scope.lotNumber) : $scope.contract?.lot;

                    if(forceShowInfo) {
                        $scope.winnerPaysMsg = true;
                    }
                    if( $scope.cabType() !== 'organizer' || !$scope.winnerPaysMsg || $scope.contract.stage > 0
                        || !(lot.winnerPayment > 0) || $scope.contract.customer.inn !== $scope.user.inn) {
                        return;
                    }

                    if( $state.current.name==='contract' && !$scope.chatViewInit) {
                        if($scope.canBeSignByOrganizer()) {
                            $scope.winnerPaysInfoMessage = 'При подписании договора, денежные средства участника, с которым заключается договор, заблокированные на счете Оператора КОМИТА ЭТП для обеспечения вознаграждения оператора, взимаемого с победителя, будут перечислены в пользу Оператора.';
                        }
                    } else if($state.current.name==='contractPaper'&& (!lot || !$scope.isFinished()) &&
                        $scope.hasSignContractPermission() && !$scope.contract.alertMessage){
                        $scope.winnerPaysInfoMessage = 'При подтверждении заключения договора в бумажной форме, денежные средства участника, с которым заключается договор, заблокированные на счете Оператора КОМИТА ЭТП для обеспечения вознаграждения оператора, взимаемого с победителя, будут перечислены в пользу Оператора.';
                    } else if($state.current.name==='contractReject'&& !$scope.isFinished() && $scope.hasSignContractPermission() &&
                        !$scope.contract.alertMessage){
                        $scope.winnerPaysInfoMessage = ($scope.model.rejectType==='rejectByOrganizer' ?
                            'При подтверждении отказа от заключения договора поставщиком, его денежные средства, заблокированные на счете Оператора КОМИТА ЭТП для обеспечения вознаграждения оператора, взимаемого с победителя, будут перечислены в пользу Оператора.' :
                            $scope.model.rejectType==='rejectByCustomer' ? 'При подтверждении отказа от заключения договора заказчиком, для участника, с которым должен был быть заключен договор, будет прекращено блокирование денежных средств, заблокированных на счете Оператора КОМИТА ЭТП для обеспечения вознаграждения оператора, взимаемого с победителя.' : '');
                    }
                }

                $scope.canBeSignByOrganizer = function(){
                    return 'signByParticipant'===$scope.contract?.state?.id && $scope.actionsBtn.signByCustomer.view && !$scope.showThrobber &&
                        !(!$scope.signDocumentsButtonActive() || $scope.hasNoCertificate() || $scope.contractSummNoNDSError || $scope.contractSummWithNDSError) &&
                        !$scope.contract.alertMessage;
                }

                $scope.onRejectTypeChange = function(){
                    $scope.initSupplyInfoMessage(true);
                    $scope.initWinnerPaysMessage(true);
                }

                $scope.getMchdLink = (subject, item) =>
                    `${$scope.mchdUrl}full?poanum=${item[subject+'MchdId']}&principal=${item[subject+'MchdPrincipalInn']}&agent=${item[subject+'MchdAgentInn']}&check=${item[subject+'MchdCheck']}`

                $scope.isOrganizerOrParticipant = () => ($scope.cabType() === 'organizer' && $scope.isProcedureCustomer()) || $scope.cabType() === 'participant'
            }]
    });
