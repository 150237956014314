/**
 * @author: sergeyu
 * Стартовые настройки приложения
 */
angular.module('uetp', [
    'angular-jwt',
    'ui.router',
    'angular.filter',
    'filters',
    'ngDialog',
    'ui.select',
    'lr.upload',
    'ngSanitize',
    'ngLocale',
    'infinite-scroll',
    'angular-loading-bar',
    'angularjs-dropdown-multiselect',
    'ngAnimate',
    //'sticky',
    'ngTouch',
    'ngMask',
    'ui.bootstrap',
    'rzModule',
    'textAngular',
    'chart.js',
    ...(__sentryUrl ? ['ngSentry'] : []),
]).config(['$httpProvider', function ($httpProvider) {
    if (!$httpProvider.defaults.headers.get) {
        $httpProvider.defaults.headers.get = {};
    }
    $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
    $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
    $httpProvider.interceptors.push('sessionInterceptor');
    $httpProvider.interceptors.push('scInterceptor');
    $httpProvider.interceptors.push('requestErrorFactory');
    $httpProvider.interceptors.push('fileBlockScreenFactory');
}]).config(['uiSelectConfig', function (uiSelectConfig) {
    uiSelectConfig.theme = 'bootstrap';
    uiSelectConfig.appendToBody = false;
    uiSelectConfig.backspaceReset = false;
}]).config(["$locationProvider", function ($locationProvider) {
    $locationProvider.hashPrefix('');
    $locationProvider.html5Mode({
        enabled: true
    })
}]).config(['ChartJsProvider', function (ChartJsProvider) {
    // Configure all charts
    ChartJsProvider.setOptions({
        responsive: true,
    });
}]).config(['$provide', function ($provide) {
    $provide.decorator('taOptions', ['taRegisterTool', '$delegate', function (taRegisterTool, taOptions) {
        taOptions.toolbar = [
            ['h1', 'h2', 'h3', 'p', 'insertLink'],
            ['bold', 'quote', 'italics', 'underline', 'ul', 'ol'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
            ['charcount']
        ];
        return taOptions;
    }]);
    $provide.decorator('uibDatepickerPopupDirective', [ '$delegate', '$controller',function($delegate, $controller) {
        const directive = $delegate[0];
        const controllerName = directive.controller;
        directive.scope.isMsk = "=?";
        directive.scope.zeroTime = "=?";
        directive.controller = ['$scope', '$element', '$attrs', '$compile', '$log', '$parse', '$window', '$document', '$rootScope', '$uibPosition', 'dateFilter', 'uibDateParser', 'uibDatepickerPopupConfig', '$timeout', 'uibDatepickerConfig',
            function($scope, $element, $attrs, $compile, $log, $parse, $window, $document, $rootScope, $position, dateFilter, dateParser, datepickerPopupConfig, $timeout, datepickerConfig) {
            const controller = $controller(controllerName, {
                $scope: $scope,
                $element: $element,
                $attrs: $attrs,
                $compile: $compile,
                $parse: $parse,
                $document: $document,
                $rootScope: $rootScope,
                $uibPosition: $position,
                dateFilter: dateFilter,
                dateParser: dateParser,
                uibDatepickerPopupConfig: datepickerPopupConfig,
                $timeout: $timeout,
                uibDatepickerConfig: datepickerConfig
            });
            $scope.select = function(date, evt) {
                evt.stopPropagation();
                if (date === 'today') {
                    const today = new Date();
                    if($scope.isMsk) {
                        date = getServerTimeMsk();
                    } else {
                        if (angular.isDate($scope.date)) date = new Date($scope.date);
                        else date = new Date();
                        date.setFullYear(today.getFullYear(), today.getMonth(), today.getDate());
                    }
                    if (!$scope.zeroTime) date.setHours(date.getHours(), date.getMinutes(), 0, 0);
                    else date.setHours(0, 0, 0, 0)
                }
                $scope.dateSelection(date);
            };
            return controller;
        }];
        return $delegate;
    }]);
}]).run(['$anchorScroll', function($anchorScroll) {
    $anchorScroll.yOffset = 330;
}]).value('constants', {
    configProject:  {
        type      : __project,
        reqType   : __project,
        yaMetrika : Number(__yandexMetrika),
        metaDesc :  __metaDescription,
        title     : __projectTitle,
    },
    altInputFormatsDT: ['dd.MM.yyyy HH:mm', 'dd-MM-yyyy HH:mm'],
    altInputFormats: ['dd.MM.yyyy', 'dd-MM-yyyy'],
    altInputFormatsYMD: ['yyyy-MM-dd']
});
