/**
 * Форма заполнения лота - вызывается по кнопке "Добавить лот"
 * @author dmitrys
 */
angular.module('uetp')
    .component('lot', {
        templateUrl: 'app/components/notice/lot/lot.html',
        bindings: {},
        controller: ['$rootScope', '$scope', '$stateParams', 'noticeSrv', 'dictionarySrv', 'dialogSrv', 'alertSrv', 'desktopSrv','vfSrv','constants','needConfirmSrv', '$state','extendFieldSrv','$filter', 'authSrv',
            function ($rootScope, $scope, $stateParams, noticeSrv, dictionarySrv, dialogSrv, alertSrv, desktopSrv, vfSrv,constants,needConfirmSrv,$state,extendFieldSrv,$filter, authSrv) {
                $scope.delRow = delRowFn;
                $scope.clickRow = clickRowFn;
                $scope.goBack = goBackFn;
                $scope.validateTable = validateTableFn;
                $scope.save = saveFn;
                $scope.autoFillByTypeDict = autoFillByTypeDictFn;
                $scope.refreshAlternativeOffers = refreshAlternativeOffersFn;
                $scope.refreshDict = refreshDictFn;
                $scope.fillLotFromPlanInfo = fillLotFromPlanInfoFn;
                $scope.checkDate = checkDateFn;
                $scope.englishSymbol = englishSymbolFn;
                $scope.isNew223 = noticeSrv.isNew223;
                $scope.isNew44 = noticeSrv.isNew44;
                $scope.isNewCommercial = noticeSrv.isNewCommercial;
                $scope.isChangedNotice = isChangedNoticeFn;
                $scope.isDifferentPlace = isDifferentPlaceFn;
                $scope.skipPriceSetLotVariants = skipPriceSetLotVariantsFn;
                $scope.changeLotDeliveryPlaceIndication = changeLotDeliveryPlaceIndicationFn;
                $scope.getActivePanel = noticeSrv.getShowPanel;
                $scope.methodPriceChange = methodPriceChangeFn;
                $scope.ignoredPurchaseChange = ignoredPurchaseChangeFn;
                $scope.priceForItemChange = priceForItemChangeFn;
                $scope.applicationSupplyNeededChange = applicationSupplyNeededChangeFn;
                $scope.completingSupplyNeededChange = completingSupplyNeededChangeFn;
                $scope.methodPriceSetFilter = methodPriceSetFilterFn;
                $scope.checkOnProcType = checkOnProcTypeFn;
                $scope.showApplicationSupply = showApplicationSupplyFn;
                $scope.updateLotSum = updateLotSumFn;
                $scope.updateWinnerTariff = updateWinnerTariffFn;
                $scope.getWinnerPay = noticeSrv.getWinnerPay;
                $scope.jointPurchaseEnabled = false;
                $scope.nonCompetitive = false;
                $scope.isNoticeIntegrationEIS = noticeSrv.isNoticeIntegrationEIS;
                $scope.isInitNoticeIntegrationEIS = noticeSrv.isInitNoticeIntegrationEIS;
                $scope.isOpenPublicOffer = noticeSrv.isOpenPublicOffer;
                $scope.isSimpleContest = noticeSrv.isSimpleContest;
                $scope.isRequestPrices = noticeSrv.isRequestPrices;
                $scope.isPercentPriceMode = noticeSrv.isPercentPriceMode;
                $scope.procType = $stateParams.type;
                $scope.customerDict = [];
                $scope.supplyDict = dictionarySrv.getStaticDictByType('applicationSupply');
                $scope.depositDict = dictionarySrv.getStaticDictByType('applicationDeposit');
                $scope.dateOptions = {
                        minDate: getServerTimeMsk()
                };
                $scope.getForm = getFormFn;
                /**
                 * Пагинатор
                 */
                $scope.totalPages = 0;
                $scope.paginator = {
                    itemsPerPage: 50,
                    page: 0
                };
                $scope.submitted = false;
                $scope.alternativeOffersSelect = 0;
                $scope.modelView = {};
                $scope.modelView.alertNomenclImport={};
                $scope.invalidNomenclatureItem = {};

                this.$onInit = function () {
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    $scope.currencyDict = dictionarySrv.getStaticDictByType('currency');
                    $scope.currencyDictLot = dictionarySrv.getStaticDictByType('currencyLot');
                    $scope.lf = vfSrv.getVisibleLotFields($stateParams.type);
                    $scope.isReadOnly = noticeSrv.getReadOnlyForm();
                    $scope.bikChangeHandler = bikChangeHandlerFn;
                    $scope.alternativeOffersDict = [
                        {value: "0", name: 'Без АП'},
                        {value: "1", name: 'Ограниченное количество АП'},
                        {value: "-1", name: 'Неограниченное количество АП'}
                    ];
                    $scope.lots = {};
                    $scope.notice = noticeSrv.getNoticeInfo();
                    $scope.constants = constants;

                    $scope.dictionary = dictionarySrv.getRemoteData();

                    $scope.methodPriceSetLotVariants = dictionarySrv.getStaticDictByType('methodPriceSetLotVariants');
                    $scope.method3ChoiceLotVariants = dictionarySrv.getStaticDictByType('method3ChoiceLotVariants');
                    $scope.lotPlanPositionVariants = dictionarySrv.getStaticDictByType('lotPlanPositionVariants');
                    $scope.deliveryPlaceIndicationLotVariants = dictionarySrv.getStaticDictByType('deliveryPlaceIndicationLotVariants');
                    $scope.percentModes = dictionarySrv.getStaticDictByType('percentModes');
                };
                /**
                 * Получение данных для изменения или создания
                 */
                $scope.$on('uetp.notice', function (event, data) {
                    if (data.dataType === 'lot' && data.type === 'createOrEdit') {
                        $scope.model = data.data;
                        $scope.jointPurchaseEnabled = data.params ? data.params.jointPurchaseEnabled : false;
                        $scope.nonCompetitive = data.params ? data.params.nonCompetitive : false;
                        $scope.hasTariffType = data.params.tariffType === 'winnerPays';
                        updatePaginatorFn();
                        refreshAlternativeOffersFn(false);
                        initLotFields(data.data);
                        initExtendField();

                        if(data.dict) {
                            $scope.customerDict = data.dict.customer;
                        }

                        //есть "Номер позиции плана"
                        if ($scope.model.lotPlanInfo && $scope.model.lotPlanInfo.positionGuid) {
                            loadPlanInfo()
                        } else {
                            $scope.modelView.positionNumberOld = null;
                        }

                        updateLotSumFn();
                        if($scope.hasTariffType)
                            updateWinnerTariffFn();

                        $scope.modelView.alertNomenclImport = {};
                    }
                    if (data.dataType === 'nomenclature' && data.type === 'update') {
                        //обнвить данные в модели
                        if (data.data != null)
                            updateDateFn(data.data);
                    }
                    if (data.dataType === 'lot' && data.type === 'getLotRequired') {
                        $scope.$parent.$parent.$emit('getLotsRequiredFinished', getLotRequiredFields(getFormFn()));
                    }
                });

                /*
                * Грузим "Номер позиции плана"
                * */
                function loadPlanInfo() {
                    var param = {searchByField:{}};
                    param.searchByField['lots.id'] = $scope.model.lotPlanInfo.positionGuid;
                    noticeSrv.loadPlanInfo($scope, param).then(function (response) {
                        if (response.data.success) {
                            if(response.data.items.length>0) {
                                $scope.model.lotPlanInfo.deff__positionNumber = response.data.items[0];
                                //запомнинаем выбраную позицию
                                $scope.modelView.positionNumberOld = response.data.items[0];
                            } else if($scope.model.lotPlanInfo && $scope.model.lotPlanInfo.positionNumber){
                                // Похоже, в эластике уже кто-то постарался и всё удалил. Ставим заглушку
                                $scope.model.lotPlanInfo.deff__positionNumber = {lots:[{lot_name:'', positionNumber:$scope.model.lotPlanInfo.positionNumber}]};
                                $scope.modelView.positionNumberOld = $scope.model.lotPlanInfo.deff__positionNumber;
                            }
                        }
                    }, function (reason) {
                        console.log(reason);
                        $scope.modelView.positionNumberOld = null;
                    });
                }
                //формируем массив обязательных полей лота, с полными путями в модели, для проверки на форме процедуры
                function getLotRequiredFields(form){
                    var fields = [];
                    if(form.$error && form.$error.required){
                        angular.forEach(form.$error.required, function (item) {
                        	if(item.$$attr.required){
                                fields.push(item.$$attr.ngModel==='model.value' ? item.$name : item.$$attr.ngModel.replace(/^(model\.)/,""));
                        	}
                        });
                    }
                    if(form.$$success && form.$$success.required){
                        angular.forEach(form.$$success.required, function (item) {
                        	if(item.$$attr.required){
                                fields.push(item.$$attr.ngModel==='model.value' ? item.$name : item.$$attr.ngModel.replace(/^(model\.)/,""));
                        	}
                        });
                    }
                    return fields;
                }

                /**
                 * Удалить строку из таблицы
                 * @param index - номер строки для удаления
                 */
                function delRowFn(index) {
                    var indexByPage = ($scope.paginator.page)*$scope.paginator.itemsPerPage + index;
                    $scope.model.lotItems.lotItem.splice(indexByPage, 1);
                    for (var i = indexByPage; i < $scope.model.lotItems.lotItem.length; i++) {
                        $scope.model.lotItems.lotItem[i].ordinalNumber = i + 1;
                    }
                    $scope.totalPages = Math.ceil($scope.model.lotItems.lotItem.length / $scope.paginator.itemsPerPage);
                    $scope.paginator.count = $scope.model.lotItems.lotItem.length;

                    if($scope.isSimpleContest() && $scope.model.lotData && $scope.model.lotData.methodPriceSet==='method3'){
                        updateLotSumFn();
                    }
                }

                /**
                 * Назад к процедуре
                 */
                function goBackFn() {
                    if ($scope.isReadOnly) {
                        noticeSrv.setState('procedure')
                    } else {
                        needConfirmSrv.call(noticeSrv.setState,['procedure'],'Внесенные изменения сохранены не будут');
                    }

                }

                /**
                 * Сохранить лот
                 */
                function saveFn() {
                    var model = angular.copy($scope.model);
                    cleanNomenclatureFieldsFn(model);
                    if(model.lotPlanInfo && !model.lotPlanInfo.deff__positionNumber){
                        delete model.lotPlanInfo.positionNumber;
                        delete model.lotPlanInfo.positionGuid;
                        delete model.lotPlanInfo.planGuid;
                    }
                    desktopSrv.removeKeys(model, 'deff__');
                    if ($scope.lots.form.$invalid) {
                    	if ($scope.lots.form.$error && $scope.lots.form.$error.required){
                          	$scope.alertObjSaveLot = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', $scope.focusFirstInvalid, $scope.lots.form);
                        } else {
                        	$scope.alertObjSaveLot = alertSrv.getErrorMsgWithBtn('Введены некорректные значения на форме. ');
                        }
                        $scope.submitted = true;
                        return;
                    }
                    if (validateTableFn()) {
                        $scope.alertObjSaveLot = alertSrv.getErrorMsgWithBtn('Необходимо заполнить таблицу "Номенклатура". ', 'Перейти', $scope.clickRow, $scope.invalidNomenclatureItem);
                        return;
                    }

                    if(!($stateParams.type === 'prequalification' || $stateParams.type === 'unlimitedprequalification' || $scope.isSimpleContest44()) && Number(model.contractSumNoNDS)>Number(model.contractSum)){
                        $scope.alertObjSaveLot = alertSrv.getErrorMsg('Начальная цена договора по лоту без НДС не должна превышать начальную цену договора по лоту с НДС.');
                        return;
                    }
                    if($scope.isPercentPriceMode() && Number(model.wholeLotPriceNoNDS)>Number(model.wholeLotPriceWithNDS)){
                        $scope.alertObjSaveLot = alertSrv.getErrorMsg('Начальная цена договора по лоту без НДС не должна превышать начальную цену договора по лоту с НДС.');
                        return;
                    }
                    if(['openpublicoffer', 'openauction', 'limitedauction', 'saleauction'].includes($stateParams.type)){
                        if(!$scope.isPercentPriceMode() && (!model.contractSum || !model.contractSumNoNDS)){
                            $scope.alertObjSaveLot = alertSrv.getErrorMsg('Цена должна быть больше 0.');
                            return;
                        } else if($scope.isPercentPriceMode() && !model.lotData.startPercent){
                            $scope.alertObjSaveLot = alertSrv.getErrorMsg('Начальный процент/коэффициент должен быть больше 0.');
                            return;
                        }
                    }

                    if($scope.isSimpleContest44()){
                        model.contractSumNoNDS = model.contractSum;
                    }

                    if($scope.model?.lotData?.applicationDepositNeeded != 'notRequired'
                        && ($scope.isNewCommercial() && $scope.model.deff__priceType=='MIN_PRICE' && $scope.model?.lotData?.applicationDepositSumm
                            || noticeSrv.showApplicationSupply() && $scope.model?.lotData?.applicationSupplySumm)){
                    	$rootScope.$broadcast('uetp.notice.checkDepositSumm', {data: true});
                    }
                    
                    //проверяем номенклатуру
                    if (($scope.isNew44() || $scope.isNew223() || $scope.isNewCommercial()) && model.lotItems.lotItem && model.lotItems.lotItem != null) {
	                    var nomCheckResult = getNomenclCheckResult(model.lotItems.lotItem);
	                    var errors = [];
	                    if(nomCheckResult && nomCheckResult.length > 0){
	                        if(nomCheckResult.length > 1){
	                            errors.push("В позициях номенклатуры №" + nomCheckResult.join(', ') + " заполнены не все обязательные поля.");
	                        } else {
	                            errors.push("В позиции номенклатуры №" + nomCheckResult[0]+ " заполнены не все обязательные поля.");
	                        }    
	                    }
	                    if (errors.length > 0) {
	                        $scope.alertObjSaveLot = alertSrv.getErrorMsg(errors.join('<br/>'));
	                        return;
	                    }
                    }
                    //сохраняем расширеные поля
                    var extendFields = extendFieldSrv.getExtendFieldForSave($scope.extendFieldTemplate);
                    model.extendFields = extendFields;
                    //через $rootScope тк слушаем в двух местах на разном уровне
                    $rootScope.$broadcast('uetp.notice', {data: model, dataType: 'lot', type: 'update'});
                    noticeSrv.setState('procedure');
                }

                /**
                 * Открыть Таблицу "Номенклатура"
                 * @param row
                 */
                function clickRowFn(row) {
                    let formVerifying = false;
                    const isNewPosition = (row == null || Object.keys(row).length === 0);
                    if (isNewPosition) {
                        row = noticeSrv.defaultNomenclature();
                        row.ordinalNumber = getOriginalNumber();
                        if($scope.isNew223()){
                            if($scope.model.deliveryPlaceIndication==='GL') {
                                row.deliveryPlace = [{
                                    address: $scope.deliveryPlace && $scope.model.lotData.deliveryPlace.address ? $scope.model.lotData.deliveryPlace.address : "",
                                    region: $scope.deliveryPlace && $scope.model.lotData.deliveryPlace.region ? $scope.model.lotData.deliveryPlace.region : "",
                                    state: $scope.deliveryPlace && $scope.lotData.deliveryPlace.state ? $scope.lotData.deliveryPlace.state : "",
                                    regionOkato: $scope.deliveryPlace && $scope.lotData.deliveryPlace.regionOkato ? $scope.lotData.deliveryPlace.regionOkato : ""
                                }];
                                row.showDeliveryPlace="hide";
                            } else
                                row.showDeliveryPlace=isDifferentPlaceFn() ? "show" : "hide";
                        }
                    }
                    else {
                        formVerifying = true;
                        row.showDeliveryPlace=isDifferentPlaceFn() && $scope.model.deliveryPlaceIndication!=='GL' ? "show" : "hide";
                    }

                    row.deff__currency = $scope.model.currency;
                    row.deff__methodPriceSet = $scope.model.lotData.methodPriceSet;
                    row.deff__priceForItem = $scope.model.lotData.priceForItem;

                    $scope.$parent.$parent.$broadcast('uetp.notice', {
                        data: angular.copy(row),
                        dataType: 'nomenclature',
                        type: 'createOrEdit',
                        isNewPosition: isNewPosition,
                        formVerifying: formVerifying,
                        nonCompetitive: $scope.nonCompetitive
                    });
                    noticeSrv.setState('nomenclature');
                }

                /**
                 *
                 */
                function getOriginalNumber() {
                    var numberRow = 0;
                    //ищем последний номер
                    angular.forEach($scope.model.lotItems.lotItem, function (item) {
                        if (item.ordinalNumber > numberRow) {
                            numberRow = item.ordinalNumber;
                        }
                    });
                    numberRow++;
                    return numberRow;
                }

                /**
                 * Обновляем данные в модели
                 */
                function updateDateFn(data) {
                    var model, update = false;
                    if ($scope.model.lotItems.lotItem && $scope.model.lotItems.lotItem.length > 0)
                        for (var i = 0; i < $scope.model.lotItems.lotItem.length; i++) {
                            model = $scope.model.lotItems.lotItem[i];
                            if (model.ordinalNumber == data.ordinalNumber) {
                                $scope.model.lotItems.lotItem[i] = data;
                                update = true;
                            }
                        }
                    //Добавить, если не нашел данные в модели
                    if (!update) {
                        if (!$scope.model.lotItems.lotItem) {
                            $scope.model.lotItems.lotItem = [];
                        }
                        $scope.model.lotItems.lotItem.push(data);
                    }
                    updatePaginatorFn();
                    updateLotSumFn();
                }

                /**
                 * Проверка таблицы Номенклатура
                 */
                function validateTableFn() {
                    if ($scope.model) {
                        if ($scope.model.lotItems.lotItem != null && $scope.model.lotItems.lotItem.length > 0) {
                            $scope.invalidNomenclatureItem = null;
                            if($scope.model.deliveryPlaceIndication === 'ELP'){
                                angular.forEach($scope.model.lotItems.lotItem, function (item) {
                                    if(!item.deliveryPlace) $scope.invalidNomenclatureItem = item;
                                });
                                if($scope.invalidNomenclatureItem) return true;
                            }
                        } else return true;
                    }
                    return false;
                }
                function getNomenclCheckResult(items){
                    const result = [];
                    angular.forEach(items, function (item) {
                        const nonComp = !$scope.isPrequalification();  // неконкурентная закупка с невыставленной галкой Невозможно определить количество - должны быть ед.измерения
                        const checkOkei = !$scope.isNewCommercial() && (($scope.isNew44() || ($scope.isNew223() && !($scope.model.lotData.priceForItem || !nonComp))|| $scope.model.lotData.methodPriceSet==='method3')
                                        && (!item['okei'] || item['okei'].code==null )), // Ед.измерения
                            checkQty =  ($scope.isNew223() && !($scope.model.lotData.priceForItem || !nonComp)) && !$scope.isSimpleContest() && item['qty'] == null ||
                                ($scope.isNew44() || ($scope.isNew223() && $scope.isSimpleContest()) || (!$scope.isNewCommercial() && $scope.model.lotData.methodPriceSet==='method3')) && !item['qty'], // Количество
                            checkPrices = ($scope.model.lotData.methodPriceSet==='method3' || ($scope.isNew223() && $scope.model.lotData.priceForItem))
                                        && (!item['commodityItemPriceNoNDS'] || !item['commodityItemPriceWithNDS']); // Цены
                        if(checkOkei || checkQty || checkPrices || !item['okpd2'] || !item['name'])
                            result.push(item['ordinalNumber']);
                    });
                    return result;
                }
                function cleanNomenclatureFieldsFn(model) {
                    if (model && model.lotItems?.lotItem?.length > 0) {
                        angular.forEach(model.lotItems.lotItem, function (item) {
                            if(model.lotData.methodPriceSet!=='method3' && !((noticeSrv.isNew223() || noticeSrv.isNewCommercial()) && model.lotData.priceForItem)){
                                delete item.commodityItemPriceWithNDS;
                                delete item.commodityItemPriceNoNDS;
                            }
                        });
                    }

                    return false;
                }

                /**
                 * Обновление пагинатора
                 */
                function updatePaginatorFn() {
                    $scope.totalPages = ($scope.model.lotItems.lotItem) ? Math.ceil($scope.model.lotItems.lotItem.length / $scope.paginator.itemsPerPage) : 1;
                }

                /**
                 * Автозаполнение полей
                 * @param model - модель
                 * @param object - объект в модели
                 * @param select - выбранное значение
                 */
                function autoFillByTypeDictFn(model, object, select) {
                    dictionarySrv.autoFillByTypeDict($scope, model, object, select);
                    if(object==='currency' && select.code===643 && $scope.model.lotData.exchangeInfo){
                        $scope.model.lotData.exchangeInfo.exchangeRate = null;
                        $scope.model.lotData.exchangeInfo.exchangeRateDate = null;
                    }
                }

                /**
                 * Обновление поля alternativeOffers
                 * если alternativeOffers = -1 - Неограниченное количество АП
                 * alternativeOffers = 0 - Без АП
                 * alternativeOffers > 0 - Ограниченное количество АП, заполняется значением из поля "Количество АП"
                 * @param update
                 */
                function refreshAlternativeOffersFn(update) {
                    if (update) {
                        if ($scope.alternativeOffersSelect == "1") {
                            $scope.model.alternativeOffers = "";
                        } else {
                            $scope.model.alternativeOffers = $scope.alternativeOffersSelect;
                        }

                    } else {
                        $scope.alternativeOffersSelect = ($scope.model.alternativeOffers > 1) ? "1" : "" + $scope.model.alternativeOffers
                    }
                }

                $scope.focusFirstInvalid = function (form) {
                    $scope.submitted = true;
                    if (form.$error && form.$error.required && form.$error.required[0]
                        && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                        desktopSrv.scollTop();
                        form.$error.required[0].$$element[0].focus();
                    }
                };
                /*
                * Заполняем лот из плана
                * в плане всегда одна позиция = одному лоту
                * */
                function fillLotFromPlanInfoFn(planInfo) {
                    if(planInfo){
                        needConfirmSrv.callVariants(doFillLotFromPlanInfo,clearPositionNumber, [], 'После выбора новой позиции плана информация по лоту и номенклатуре будет обновлена. Продолжить?');
                    }else{
                        $scope.modelView.positionNumberOld = planInfo;
                    }
                    //Заполняем лот
                    function doFillLotFromPlanInfo() {
                        $scope.modelView.positionNumberOld = $scope.model.lotPlanInfo.deff__positionNumber;
                        const oldPriceType = $scope.model.deff__priceType;
                        var lot = angular.copy(planInfo.lots[0]),
                            ordinalNumber = $scope.model.ordinalNumber, lotId = $scope.model.id;
                        $scope.model = noticeSrv.defaultLot();
                        $scope.model.ordinalNumber = ordinalNumber;
                        $scope.model.deff__priceType = oldPriceType;
                        $scope.model.lotPlanInfo = {};
                        $scope.model.lotPlanInfo.deff__positionNumber = $scope.modelView.positionNumberOld;
                        //заполняем информацию по лоту
                        $scope.model.id = lotId;
                        $scope.model.subject = lot.lot_name;

                        $scope.model.lotData = {};
                        if($scope.isPercentPriceMode()) {
                            $scope.model.lotData.percentMode = getFormFn().percentMode.$modelValue;
                        }
                        $scope.model.lotData.applicationSupplyCurrency = lot.applicationSupplyCurrency;
                        $scope.model.lotData.applicationSupplySumm = lot.applicationSupplySumm;
                        $scope.model.lotData.methodPriceSet = $scope.methodPriceSetLotVariants[0].id;
                        $scope.model.lotData.applicationSupplySumm = lot.applicationSupplySumm;

                        var customer = noticeSrv.getSelectedCustomer();
                        var eisnds = customer ? customer.eisnds : true;

                        if(eisnds)
                            $scope.isPercentPriceMode() ? $scope.model.wholeLotPriceWithNDS = lot.startprice : $scope.model.contractSum = lot.startprice;
                        else
                            $scope.isPercentPriceMode() ? $scope.model.wholeLotPriceNoNDS = lot.startprice : $scope.model.contractSumNoNDS = lot.startprice;
                        
                        if(lot.startprice_cur){
                        	$scope.model.lotData.startingContractPriceRub = lot.startprice_cur;
                        }
                        
                        $scope.model.lotPlanInfo.planRegistrationNumber = planInfo.numberPlan;
                        $scope.model.lotPlanInfo.planGuid = planInfo.positionPlanGuid;
                        $scope.model.lotPlanInfo.positionNumber = lot.lot_number;
                        $scope.model.lotPlanInfo.lotPlanPosition = "COMMODITY";
                        $scope.model.lotPlanInfo.positionGuid = lot.id;

                        $scope.model.deliveryPlaceIndication = "GL";

                        angular.forEach($scope.dictionary.fias_region, function (item) {
                            if (item.okato === lot.deliveryPlace_OKATO) {
                                var obj = {};
                                obj.state = item.federalokrug;
                                obj.regionOkato = item.okato;
                                obj.region = item.code;
                                $scope.model.lotData.deliveryPlace = obj;
                            }
                        });
                         
                        angular.forEach($scope.currencyDict, function (item) {
                            if (item.pattern === lot.currency_code) {
                                $scope.model.currency = {digitalCode: item.code, name: item.name, code: lot.currency_code};
                            }
                        });
                        //заполняем информацию по номерклатуре
                        if (!$scope.model.lotItems) $scope.model.lotItems = {};
                        $scope.model.lotItems.lotItem = [];
                        angular.forEach(planInfo.lots[0].lotItems, function (item) {
                            var obj = {};
                            obj.ordinalNumber = getOriginalNumber();
                            obj.okei = {code: item.okeiCode, name: item.okeiName};
                            obj.okpd2 = {code: item.okpd2_code, name: item.okpd2_name};
                            obj.okved2 = {code: item.okved2_code, name: item.okved2_name};
                            obj.qty = item.qty;
                            obj.name = item.okpd2_name;
                            $scope.model.lotItems.lotItem.push(obj)
                        });

                        updateWinnerTariffFn();

                        updatePaginatorFn();
                    }
                    //возвращаем поле "Номер позиции плана" к пред значениею
                    function clearPositionNumber() {
                        $scope.model.lotPlanInfo.deff__positionNumber = $scope.modelView.positionNumberOld;
                    }
                }
                function getFormFn() {
                    return $scope.lots.form;
                }
                function checkDateFn() {
                    noticeSrv.checkDate($scope.lots.form,$scope.notice.document.claimEnds,[{auctionDateStart:$scope.model.auctionDateStart}],'auctionDateStart',noticeSrv.form.procedure,'claimEnds');
                }
                function englishSymbolFn(field) {
                    noticeSrv.englishSymbol($scope.lots.form,field);
                }
                function isDifferentPlaceFn(){
                    return $scope.notice.document && $scope.notice.document.deliveryPlaceIndication && $scope.notice.document.deliveryPlaceIndication === 'DIFFERENT_PLACE';
                }
                function skipPriceSetLotVariantsFn(value) {
                    return $scope.notice.document && $scope.notice.document.priceType === 'NO_PRICE' && value.id==='method2' || $scope.notice.document.priceType !== 'NO_PRICE' && value.id!=='method2';
                }
                function initLotFields(data){
                    if(!$scope.model.lotData)
                        $scope.model.lotData = {};
                    $scope.model.lotData.methodPriceSet = data.lotData && data.lotData.methodPriceSet ? data.lotData.methodPriceSet : $scope.methodPriceSetLotVariants[0].id;

                    if((isDifferentPlaceFn() && !$scope.model.deliveryPlaceIndication) || $scope.isSimpleContest() )
                        $scope.model.deliveryPlaceIndication = 'GL';
                    // clean unnecessary fields
                    methodPriceChangeFn();
                    
                    if($scope.isPercentPriceMode() && !$scope.model.lotData.percentMode) {
                        if(['openauction', 'limitedauction'].includes($stateParams.type))
                            $scope.model.lotData.percentMode = 'MAX_PERCENT';
                        else if(['saleauction', 'openpublicoffer'].includes($stateParams.type))
                            $scope.model.lotData.percentMode = 'MIN_PERCENT';
                        else
                            $scope.model.lotData.percentMode = $scope.model.deff__priceType === 'MIN_PRICE'? 'MIN_PERCENT' :'MAX_PERCENT';
                    }

                    if($scope.model.lotData.applicationSupplyNeeded && !$scope.model.lotData.applicationDepositNeeded){
                        $scope.model.lotData.applicationDepositNeeded = 'customerSupply';
                    }
                }
                /*
                * Загружаем и оторбажем расширеные поля
                * */
                function initExtendField(){
                    /*
                    * Получаем шаблон полей уровня лот
                    * */
                    $scope.extendFieldTemplate = angular.copy(extendFieldSrv.getNoticeExtFieldTemplate('LOT'));
                    if(!$scope.model.extendFields)$scope.model.extendFields = {};
                    /*
                     * Получаем данные
                     * Поля: integrCode, description, type, value
                     * */
                    var model = extendFieldSrv.getExtendFieldForModel($scope.model.extendFields);
                    //проставляем полученые данные в поля шаблона
                    extendFieldSrv.setModelToTemplate($scope.extendFieldTemplate, model);

                    $scope.lf.showExtendFields = $scope.extendFieldTemplate && $scope.extendFieldTemplate.length>0 && noticeSrv.isNoticeIntegrationEIS();
                }
                function methodPriceSetFilterFn(el){
                    if($stateParams.type === 'simplecontest')
                        return el.id === 'method1' || el.id === 'method3';
                    else
                        return el.id === 'method1';
                }
                function changeLotDeliveryPlaceIndicationFn(){
                    if($scope.model.deliveryPlaceIndication==='ELP') {
                        delete $scope.model.lotData.deliveryPlace;
                        for (var i = 0; i < $scope.model.lotItems.lotItem.length; i++) {
                            delete $scope.model.lotItems.lotItem[i].deliveryPlace;
                        }
                    } else {
                        $scope.model.lotData.deliveryPlace = {};
                    }
                }
                function methodPriceChangeFn(){
                    if($scope.model.lotData.methodPriceSet==='method1'){
                        $scope.model.lotData.orderPricing = '';
                        $scope.model.lotData.priceFormula = '';
                        $scope.model.lotData.commodityPrice = null;
                        $scope.model.lotData.maxContractPrice = null;
                    } else if($scope.model.lotData.methodPriceSet==='method2'){
                        $scope.model.contractSumNoNDS = null;
                        $scope.model.contractSum = null;
                    } else if($scope.model.lotData.methodPriceSet==='method3'){
                        $scope.model.contractSumNoNDS = null;
                        $scope.model.contractSum = null;
                        $scope.model.lotData.orderPricing = '';
                        $scope.model.lotData.priceFormula = '';
                        $scope.model.lotData.commodityPrice = null;
                        $scope.model.lotData.maxContractPrice = null;
                        
                        $scope.model.lotData.priceForItem = false;
                        $scope.model.wholeLotPriceNoNDS = null;
                        $scope.model.wholeLotPriceWithNDS = null;
                        $scope.model.lotData.method3Choice = null;

                        updateLotSumFn();
                    }

                }
                function priceForItemChangeFn(){
                    if(!$scope.model.priceForItem){
                        $scope.model.wholeLotPriceNoNDS = null;
                        $scope.model.wholeLotPriceWithNDS = null;
                    }
                    updateWinnerTariffFn();
                }
                function applicationSupplyNeededChangeFn(){
                    if(!$scope.model.lotData.applicationSupplyNeeded){
                        $scope.model.lotData.applicationDepositNeeded = null;
                        $scope.model.lotData.applicationSupplySumm = null;
                        $scope.model.lotData.applicationSupplyCurrency = null;
                        $scope.model.lotData.applicationSupplyExtra = null;
                    } else{
                        $scope.model.lotData.applicationDepositNeeded = $scope.notice?.document?.jointPurchase ? 'customerSupply' : 'operatorSupply';
                    }
                    $scope.supplyChangeHandler();
                }
                function completingSupplyNeededChangeFn(){
                    if(!$scope.model.lotData.completingSupplyNeeded){
                        $scope.model.lotData.completingSupplySumm = null;
                        $scope.model.lotData.completingSupplyCurrency = null;
                        $scope.model.lotData.completingSupplyExtra = null;
                    } else
                        $scope.model.lotData.completingSupplyCurrency = $scope.currencyDictLot.find(c=>c.digitalCode === 643);
                }
                function ignoredPurchaseChangeFn(){
                    if(!$scope.model.lotData.ignoredPurchase){
                        delete $scope.model.lotData.purchaseCategory;
                    }
                }
                function checkOnProcTypeFn() {
                    return $stateParams.type !== 'unlimitedprequalification' && $stateParams.type !== 'prequalification';
                }

                /**
                 * Отображать ли раздел "Требование к наличию обеспечения заявки"
                 */
                function showApplicationSupplyFn() {
                    return noticeSrv.showApplicationSupply();
                }

                function isChangedNoticeFn(){
                    return $scope.notice.document && $scope.notice.document.version>1;
                }
                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 */
                function refreshDictFn(object, value, refreshScrolling, searchByField, searchValue) {
                    if(object==='positionPlanNumbers') {
                        var customer = noticeSrv.getSelectedCustomer();
                        if(customer && customer.customerType==='division')
                            customer = customer.parent;
                        if (customer && (customer.inn || customer.kpp || customer.ogrn)) {
                            if (!searchByField) searchByField = {};
                            searchByField.year = $filter('date')(getServerTimeMsk(), 'yyyy');
                            searchByField.section = '223';
                            searchByField.state = 'PLANNED';
                            if(value){
                               searchByField['lots.lot_name_positionNumber'] = value;
                            }
                            if((noticeSrv.isNew223() || noticeSrv.isInitedNotice()) && $scope.notice && $scope.notice.document && $scope.notice.document.purchaseMethodCode){
                                searchByField.method_code = $scope.notice.document.purchaseMethodCode;
                            } else return;

                            var customerObj = {};
                            if (customer.inn) customerObj.inn = customer.inn;
                            if (customer.kpp) customerObj.kpp = customer.kpp;
                            if (customer.ogrn) customerObj.ogrn = customer.ogrn;
                            searchByField.searchByGroup = {customer: [customerObj]};
                        } else return;
                    }
                    if (!value) {
                        if (object === 'rBIK') {
                            value = searchValue;
                        }
                    }
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling, searchByField)
                }
                                
                $scope.clearRelatedFields = function (model) {
                    model.cancellation.cancelDate="";
                    model.cancellation.cancelInfo="";
                };

                /**
                 * Поля "Начальная (максимальная) цена договора по лоту без НДС" (contractSumNoNDS) и
                 * "Начальная (максимальная) цена договора по лоту с НДС" (contractSum)
                 * Правее каждого поля разместить кнопку "Пересчитать" - как в форме извещения. При нажатии на кнопку "Пересчитать" значения полей заполняются
                 * суммами по всем строкам номенклатуры значений (commodityItemPriceNoNDS * qty) и (commodityItemPriceWithNDS * qty), соответственно.
                 * Перед суммированием значение (цена * количество) должно округляться до 2х знаков.
                 */
                function updateLotSumFn() {
                    if ($scope.isSimpleContest() && $scope.model.lotData.methodPriceSet==='method3') {
                        if ($scope.model.lotItems && $scope.model.lotItems.lotItem && $scope.model.lotItems.lotItem.length > 0) {
                            var csNoNDS=0,cs=0;
                            angular.forEach($scope.model.lotItems.lotItem, function (item) {
                                csNoNDS+=Number((item.qty*item.commodityItemPriceNoNDS).toFixed(2));
                                cs+=Number((item.qty*item.commodityItemPriceWithNDS).toFixed(2));
                            });
                            $scope.model.contractSumNoNDS = Number(csNoNDS).toFixed(2);
                            $scope.model.contractSum = Number(cs).toFixed(2);
                        } else {
                            $scope.model.contractSumNoNDS = '';
                            $scope.model.contractSum = '';
                        }
                    }
                }

                /**
                 * Пересчет поля winnerPayment
                 *
                 * для закупок с ценой, в т.ч. для лотов за единичную расценку и процент/коэффициент - аналогично lots.rubprice - цена договора по лоту в рублях с НДС (не ед.расценка):
                 * лот с ценой за рубли: contractSum;
                 * лот с ценой за валюту: contractSum * exchangeRate;
                 * лот за единичную расценку или процент/коэффициент за рубли: WholeLotPriceWithNDS;
                 * лот за единичную расценку или процент/коэффициент за валюту: WholeLotPriceWithNDS * exchangeRate;
                 * для закупок без цены - по ставке для самой большой цены из тарифной сетки.
                 */
                function updateWinnerTariffFn() {
                    if($scope.isReadOnly) return;
                    if ($scope.hasTariffType) {
                        let rubprice;
                        if('NO_PRICE' !== $scope.notice.document.priceType) {
                            if($scope.model.lotData.methodPriceSet==='method3' || ($scope.priceForItemProc() && $scope.model.lotData.priceForItem) || $scope.isPercentPriceMode()){
                                if($scope.model.wholeLotPriceWithNDS !== null && Number($scope.model.wholeLotPriceWithNDS)===0) {
                                    noPriceWinnerPayment();
                                } else if($scope.model.lotData.exchangeInfo?.exchangeRate) {
                                    //лот за единичную расценку или процент/коэффициент за валюту: WholeLotPriceWithNDS * exchangeRate;
                                    //$scope.isPercentPriceMode() && !model.lotData.startPercent
                                    rubprice = Number($scope.model.wholeLotPriceWithNDS) * Number($scope.model.lotData.exchangeInfo.exchangeRate);
                                } else {
                                    //лот за единичную расценку или процент/коэффициент за рубли: WholeLotPriceWithNDS;
                                    rubprice = Number($scope.model.wholeLotPriceWithNDS)
                                }
                            } else {
                                if($scope.model.contractSum !== null && Number($scope.model.contractSum)===0) {
                                    noPriceWinnerPayment();
                                } else if($scope.model.lotData.exchangeInfo?.exchangeRate) {
                                    //лот с ценой за валюту: contractSum * exchangeRate;
                                    rubprice = Number($scope.model.contractSum) * Number($scope.model.lotData.exchangeInfo.exchangeRate);
                                } else {
                                    //лот с ценой за рубли: contractSum;
                                    rubprice = Number($scope.model.contractSum)
                                }
                            }
                        }
                        if(rubprice && rubprice!==0 || 'NO_PRICE' === $scope.notice.document.priceType) {
                            noPriceWinnerPayment(rubprice);
                        } else
                            delete $scope.model.winnerPayment;
                    } else
                        delete $scope.model.winnerPayment;

                    function noPriceWinnerPayment(rubprice) {
                        $scope.getWinnerPay(rubprice, rubprice ? 'NO_PRICE' !== $scope.notice.document.priceType : undefined).then(function (response) {
                            if (response.data.success) {
                                $scope.model.winnerPayment = response.data.result;
                            } else
                                delete $scope.model.winnerPayment;
                        });
                    }
                }

                $scope.nomenclatureImport = function(data){
                    if(data.result) {
                        importNomencFn();
                    }

                    function importNomencFn(){
                        if($scope.model.lotData.methodPriceSet!=='method3'){
                            angular.forEach(data.result, function (item) {
                                delete item.commodityItemPriceWithNDS;
                                delete item.commodityItemPriceNoNDS;
                            });
                        }
                        if($scope.model.deliveryPlaceIndication==='GL') {
                            angular.forEach(data.result, function (item) {
                                item.deliveryPlace = [{
                                    address: "",
                                    region: "",
                                    state: "",
                                    regionOkato: ""
                                }];
                            });
                        }
                        $scope.model.lotItems.lotItem = data.result;
                        if(data.success)
                            $scope.modelView.alertNomenclImport = alertSrv.getSuccessMsg(data.message);

                        updatePaginatorFn();
                        if($scope.model.lotData.methodPriceSet==='method3')
                            updateLotSumFn();
                    }
                };

                
                /**
                 * Обработка фильтрованного значения количества
                 * @param num
                 * @returns {*}
                 */
                $scope.getNum = function (num) {
                    if (num !== null) {
                        var str = num.toString();
                        var processed = parseFloat(str.replace(/\s/g, "").replace(/,/g, '.')).toString();
                        if (processed.indexOf('.') !== -1) {
                            return (str.substr(0, str.indexOf(',')) + '' + processed.substr(processed.indexOf('.'))).replace('.', ',');
                        } else return str.substr(0, str.indexOf(',')).replace('.', ',');
                    }
                    return num;
                };

                $scope.onSizeImageError = function(files, maxfilesize){
                    angular.forEach(files, function (file) {
                        var errorMsg = 'Файл ' + file.name + ' не добавлен.<br />' +
                            'Превышен максимально разрешенный размер файла ('
                            + (maxfilesize /1048576 ).toFixed(2) + ' Мб)!<br />' +
                            'Размер файла ' + file.name + ': ' + (file.size / 1048576).toFixed(2) + ' Мб.';
                        $scope.modelView.alertNomenclImport = alertSrv.getErrorMsg(errorMsg)
                    });
                };
                $scope.onSizeEmptyImageError = function(files){
                    angular.forEach(files, function (file) {
                        if (file.size === 0) {
                            var errorMsg = 'Файл ' + file.name + ' не добавлен.<br />' +
                                'Невозможно добавить пустой файл.';
                            $scope.modelView.alertNomenclImport = alertSrv.getErrorMsg(errorMsg)
                        }
                    });
                };
                $scope.onFileNameImageError = function(files){
                    angular.forEach(files, function (file) {
                        var errorMsg = 'Импорт из файла  ' + file.name + ' невозможен.<br />' +
                            'Для импорта используйте файлы формата Microsoft Excel (xls или xlsx).';
                        $scope.modelView.alertNomenclImport = alertSrv.getErrorMsg(errorMsg)
                    });
                };
                $scope.preCondition = function (callback, changeEvent) {
                    if($scope.model) {
                        if ($scope.model.lotItems.lotItem && $scope.model.lotItems.lotItem.length > 0)
                            needConfirmSrv.callVariants(function () {
                                callback();
                                changeEvent.target.value = '';
                            }, function () {
                                changeEvent.target.value = '';
                            }, [], 'При импорте позиций из файла ранее введенные позиции номенклатуры будут удалены. Продолжить импорт?');
                        else {
                            callback();
                            changeEvent.target.value = '';
                        }
                    }
                };
                $scope.hasMultipleContract = function() {
                    return ($scope.isNewCommercial() || $scope.isNew223()) && !['simplecontest', 'offerplacement', 'prequalification',
                        'unlimitedprequalification', 'requestprices'].includes($scope.procType);
                }
                $scope.isMultipleContractSale = function() {
                    return $scope.isNewCommercial() && (['saleauction', 'openpublicoffer'].includes($scope.procType) || $scope.model?.deff__priceType==='MIN_PRICE');
                }

                $scope.isSimpleContestConfines = function () {
                    return $scope.isSimpleContest() && (noticeSrv.isNew44() || noticeSrv.isNewCommercial());
                };
                $scope.isSimpleContest44 = function () {
                    return $scope.isSimpleContest() && noticeSrv.isNew44();
                };
                $scope.getSection = function () {
                    return noticeSrv.isNew44() ? '44' : (noticeSrv.isNewCommercial() ? 'commercial' : '223');
                }
                /**
                 * Заполнить Номер корреспондентского счета банка из выбранного банка
                 */
                function bikChangeHandlerFn(selectObj, dataObj) {
                    var selected = selectObj.$select.selected;

                    dataObj.bankName = selected ? selected.participant : "";
                    dataObj.correspondentAccount = selected ? selected.accounts : "";
                }
                
                $scope.depositByCustomer = function () {
                    if(!(noticeSrv.getSelectedCustomer()||{}).id){
                    	return;
                    }
                    setDepositPaymentDetails('customer', noticeSrv.getSelectedCustomer().id);
                }
                
                $scope.depositByOrganizer = function () {
                	setDepositPaymentDetails('organizer', authSrv.getUser().id);
                }
                
                $scope.isVisibleDepositByCustomer = function () {
                	 return !!(noticeSrv.getSelectedCustomer()||{}).id;
                }
                $scope.isVisibleDepositByOrganizer = function () {
              	     return !(noticeSrv.getSelectedCustomer()||{}).id || 
              	     noticeSrv.getSelectedCustomer().customerType == 'division' && noticeSrv.getSelectedCustomer().parent && noticeSrv.getSelectedCustomer().parent.inn !== authSrv.getUser().inn ||
              	     noticeSrv.getSelectedCustomer().customerType != 'division' && noticeSrv.getSelectedCustomer().inn !== authSrv.getUser().inn;
                }
                
                function setDepositPaymentDetails(typeOrg, idOrg) {
                    noticeSrv.getOrgRequisites(typeOrg, idOrg).then(function (response) {
                        if (response.data.success) {
                        	var result = response.data.result;
                        	if(!$scope.model.lotData.depositPaymentDetails){
                        		$scope.model.lotData.depositPaymentDetails = {};
                        	}
                        	$scope.model.lotData.depositPaymentDetails.INN = result.inn;
                        	$scope.model.lotData.depositPaymentDetails.KPP = result.kpp;
                        	$scope.model.lotData.depositPaymentDetails.name = result.fullName;
                        	if(result.requisites){
                        		$scope.model.lotData.depositPaymentDetails.account = result.requisites.acc;
                        		$scope.model.lotData.depositPaymentDetails.bankBIC = result.requisites.bicBank;
                        		$scope.model.lotData.depositPaymentDetails.bankName = result.requisites.bank;
                        		$scope.model.lotData.depositPaymentDetails.correspondentAccount = result.requisites.bankAcc;
                        		refreshDictFn('rBIK', null, false, null, $scope.model.lotData.depositPaymentDetails.bankBIC);
                        	}else{
                        		delete $scope.model.lotData.depositPaymentDetails.account;
                        		delete $scope.model.lotData.depositPaymentDetails.bankBIC;
                        		delete $scope.model.lotData.depositPaymentDetails.bankName;
                        		delete $scope.model.lotData.depositPaymentDetails.correspondentAccount;
                        	}
                        	$scope.lots.form.dpINN.$setViewValue($scope.model.lotData.depositPaymentDetails.INN);
                        	
                        }else{
                        	console.log(response.data);
                        }
                    }, function (reason) {
                        console.log(reason);
                    });
                }
                $scope.depositChangeHandler = function (selectObj) {
                	var selected = selectObj.$select.selected;
                	
                	if(selected && selected.code === 'operator'){
                		if ($scope.model.lotData.deff__depositPaymentDetails){
                           $scope.model.lotData.depositPaymentDetails = angular.copy($scope.model.lotData.deff__depositPaymentDetails);
                        }else{
                    	   $scope.depositByCustomer();
                        }
                	}else{
                		delete $scope.model.lotData.depositPaymentDetails;
                		if(!selected || selected.code === 'notRequired'){
                		  delete $scope.model.lotData.applicationDepositSumm;
                		  delete $scope.model.lotData.applicationDepositExtra;
                		}
                	}
                }
                $scope.checkExcludePurchaseFromPlan = function() {
                    let cond = $scope.isNew223() && !$scope.isSimpleContest() &&
                            $scope.model && $scope.model.lotData && !$scope.model.lotData.excludePurchaseFromPlan;
                    return $scope.isReadOnly ? cond && $scope.isInitNoticeIntegrationEIS() : cond && $scope.isNoticeIntegrationEIS();
                }
                $scope.isAuction = function(){
                    return ['openauction', 'saleauction', 'limitedauction', 'openpublicoffer'].includes($stateParams.type);
                }

                $scope.supplyChangeHandler = function () {
                    const selected = $scope.model.lotData.applicationDepositNeeded;

                    if($scope.notice?.document?.jointPurchase || selected === 'operatorSupply'){
                        $scope.autoFillByTypeDict('model','applicationSupplyCurrency', $scope.currencyDict.find(c=>c.code === 643));
                        if ($scope.model.lotData.deff__depositPaymentDetails){
                            $scope.model.lotData.depositPaymentDetails = angular.copy($scope.model.lotData.deff__depositPaymentDetails);
                        } else {
                            $scope.depositByCustomer();
                        }
                    } else {
                        delete $scope.model.lotData.depositPaymentDetails;
                    }
                }

                $scope.filterApplicationDepositNeeded = function(el) {
                    return $scope.notice?.document?.jointPurchase ? !['operator', 'operatorSupply'].includes(el.code) : true;
                }
                $scope.hasCompletingSupplyNeeded = function() {
                    return ($scope.isNewCommercial() || $scope.isNew223()) && !['simplecontest', 'saleauction', 'openpublicoffer', 'prequalification', 'unlimitedprequalification', 'requestprices'].includes($scope.procType) &&
                        ['MAX_PRICE','NO_PRICE'].includes($scope.notice.document.priceType);
                }
                $scope.priceForItemProc = () => { return ($scope.isNewCommercial() || $scope.isNew223()) && !['simplecontest', 'prequalification', 'unlimitedprequalification'].includes($scope.procType);}

                $scope.isPrequalification = () => {
                    return ['prequalification', 'unlimitedprequalification'].includes($scope.procType);
                }
            }]
    });