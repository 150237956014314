/**
 * @author dmitrys
 */
angular.module('uetp')
    .component('documentation', {
        templateUrl: 'app/components/notice/documentation/documentation.html',
        controller: ['$scope', '$rootScope', '$state', 'noticeSrv', 'alertSrv', 'dialogSrv', 'authSrv', 'constants',
            function ($scope, $rootScope, $state, noticeSrv, alertSrv, dialogSrv, authSrv, constants) {

                var ctrl = this;
                $scope.ctrl = ctrl;
                /**
                 * Скачать все файлы
                 */
                $scope.downloadAll = downloadAllFn;
                /**
                 * Скачать файл
                 */
                $scope.downloadDoc = downloadDocFn;
                /**
                 * Удалить все файлы
                 */
                $scope.deleteAll = deleteAllFn;
                /**
                 * Удалить файл
                 */
                $scope.deleteFile = deleteFileFn;

                /**
                 * Добавить файл
                 */
                $scope.addFile = addFileFn;
                // $scope.publishProc = publishProcFn;
                $scope.isNew223 = noticeSrv.isNew223;


                $scope.isNoticeIntegrationEIS = noticeSrv.isNoticeIntegrationEIS;

                /**
                 * Пагинатор
                 */
                $scope.totalPages = 0;
                $scope.paginator = {
                    itemsPerPage: 10,
                    page: 0
                };
                this.$onInit = function () {
                    $scope.auth = authSrv.getAuth();
                    $scope.model = {};
                    $scope.isAttachEdit = noticeSrv.getAttachEdit;
                    $scope.notice = noticeSrv.getNoticeInfo();
                    $scope.isReadOnly = noticeSrv.getReadOnlyForm();
                    $scope.constants = constants;
                    if(noticeSrv.getNoticeInfo().document)
                        $scope.model.attachment = noticeSrv.getNoticeInfo().document.attachment;
                    if($scope.model.attachment==null){
                        $scope.model.attachment = [];
                        noticeSrv.setAttachments($scope.model.attachment);
                    }
                    setNumberFn($scope.model.attachment);
                    updatePaginatorFn();
                };
                this.$postLink = function () {
                    //проставляем форму для валидации
                    noticeSrv.setForm('documentation', $scope.documentationForm);
                };
                function setNumberFn(attachment) {
                    for(var i=0;i<attachment.length;i++){
                        attachment[i].deff__number = i+1;
                    }
                }
                function downloadAllFn() {
                    noticeSrv.downloadDocArchive($scope.notice.type.id, $scope.notice.id);

                }
                function downloadDocFn(file) {
                    noticeSrv.downloadDoc($scope.notice.type.id,$scope.notice.id, file.id,file.fileName);
                }

                function deleteAllFn() {
                    $scope.model.attachment = [];
                }
                function addFileFn(data) {
                    if(!$scope.model.attachment) $scope.model.attachment = [];
                    if(data) {
                        angular.forEach(data, function (file) {
                            if (getIdxByFileName( $scope.model.attachment, file.fileName)==-1) {
                                noticeSrv.setAttachEdit(true);
                                $scope.model.attachment.push({
                                    fileName: file.fileName,
                                    hash: file.hash,
                                    id: file.id,
                                    size: file.size,
                                    open: file.open,
                                    type: file.type
                                });
                            }
                            else
                                dialogSrv.showDialogMsg($scope, "Файл уже присутствует в списке");
                        })

                    }
                    setNumberFn($scope.model.attachment);
                    updatePaginatorFn();
                }
                function getIdxByFileName(dataArray, fileName){
                    var idx = -1;
                    if (!dataArray || !dataArray.length) return idx;
                    for (var i=0; i<dataArray.length;i++){
                        if (dataArray[i].fileName == fileName){
                            return i;
                        }
                    }
                    return idx;

                }
                function deleteFileFn(item) {
                    for(var i=0;i<$scope.model.attachment.length;i++){
                        if($scope.model.attachment[i].id == item.id){
                            $scope.model.attachment.splice(i,1);
                            noticeSrv.setAttachEdit(true);
                            break;
                        }
                    }
                    setNumberFn($scope.model.attachment);
                    if($scope.model.attachment.length<=$scope.paginator.itemsPerPage)
                        $scope.paginator.page = 0;

                    updatePaginatorFn();
                }
                /**
                 * Обновление пагинатора
                 */
                function updatePaginatorFn() {
                    $scope.totalPages = ($scope.model.attachment.length) ? Math.ceil($scope.model.attachment.length / $scope.paginator.itemsPerPage) : null;
                }
                $scope.isSimpleContest = function () {
                    return $scope.notice.document && $scope.notice.document.typeCode ==='simplecontest';
                };
            }]
    });
