/**
 * Уведомление об отмене закупки
 */
angular.module('uetp')
    .component('additionalProtocol', {
        templateUrl: 'app/components/procedures/protocol/additional/additionalProtocol.html',
        bindings: {
            procedureData: '<',
            protocolData: '<'
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv', 'proceduresSrv', '$uibModal', 'authSrv', 'httpSrv', 'storageSrv', 'vfSrv', 'needConfirmSrv', 'alertSrv','dictionarySrv', 'dialogSrv', 'protocolSrv', 'permissionSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, proceduresSrv, $uibModal, authSrv, httpSrv, storageSrv, vfSrv, needConfirmSrv, alertSrv, dictionarySrv, dialogSrv, protocolSrv, permissionSrv) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                
                $scope.actionBtns = {
                        isManualIncorrectProtocol: false,
                        isManualCancelProtocol: false,
                        isManualPublishProtocol: false
                };
                
                this.$onInit = function () {

                	$scope.ajaxActive = true;
                    $scope.isViewOnly = $stateParams.isViewOnly;
                    $scope.maxCountAttachments = 1;
                    $scope.auth = authSrv.getAuth();

                    $scope.procedure = $scope.ctrl.procedureData.data.result;
                    if ($scope.ctrl.protocolData.data.success) {
                        $scope.protocol = $scope.ctrl.protocolData.data.result;
                        $scope.cabType = desktopSrv.getCabType;

                        $scope.protocolTypes = _getProtocolTypes();

                        if (!$scope.isViewOnly) {
                            $scope.rejectionDateOptions =  {
                                maxDate: getServerTimeMsk()
                            };
                            $scope.protocol.document.attachment = [];
                        }
                        
                        _getBtnVisible($scope.protocol.actions);
                        
                    } else {
                        dialogSrv.showDialogMsgHtml($scope, $scope.ctrl.protocolData.data.errorMessage, function () {
                            _openProcedure()
                        });
                    }
                };

                $scope.deleteAttach = deleteAttachFn;
                $scope.signSendData = signSendDataFn;
                $scope.uploadSuccess = uploadSuccessFn;
                $scope.downloadFile = downloadFileFn;
                $scope.cancel = cancelFn;
                $scope.changeState = changeStateFn;
                $scope.hasPermission = permissionSrv.hasPermission;
                
                function uploadSuccessFn(result) {
                    angular.forEach(result, function (item) {
                        $scope.protocol.document.attachment.push({
                            fileName: item.fileName,
                            id: item.id
                        });
                    })
                }

                function deleteAttachFn(index) {
                    $scope.protocol.document.attachment.splice(index, 1);
                }

                function cancelFn() {
                    if (_getForm().$dirty) {
                        needConfirmSrv.call(_openProcedure, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        _openProcedure();
                    }
                }

                function _focusFirstInvalid(form) {
                    $scope.submitted = true;
                    if (form.$error && form.$error.required && form.$error.required[0]
                        && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                        desktopSrv.scollTop();
                        form.$error.required[0].$$element[0].focus();
                    }
                }

                function _openProcedure() {
                    $state.go('openProcedure', {procType: $scope.procedure.type.id.toLowerCase(), procId: $scope.procedure.id},{location :'replace'});
                }

                function _getProtocolTypes() {
                    var types = [];
                    if($scope.isViewOnly)
                        types.push({id: 'changeContract', name: 'Протокол изменений условий договора'});
                    if (!['prequalification','unlimitedprequalification'].includes($scope.procedure.type.id)) { //для всех процедур, кроме ПКО и ПКОсНС
                        types.push({id: 'cancelContract', name: 'Протокол отказа от заключения договора'});
                        types.push({id: 'participantEvadedConclusionContract', name: 'Протокол признания участника уклонившимся от заключения договора'});
                    }
                    if (['openpublicoffer','auctionsmb','EAP44','limitedauction','openauction','saleauction'].includes($scope.procedure.type.id)) { //только для аукционов
                        types.push({id: 'considerationDumpingPrice', name: 'Протокол рассмотрения демпинговой цены договора'});
                    }
                    return types;
                }

                function _getForm() {
                    return $scope.form.additionalForm;
                }

                /**
                 * Подпись и отправка
                 */
                function signSendDataFn() {
                    if ($scope.auth.certId || $scope.auth.withoutCertEnabled) {
                        if (validateForm()) {
                            $stateParams.protocolId = $scope.protocol.id;
                            protocolSrv.saveProtocolFn($stateParams, $scope.protocol, 'ADDITIONAL').then(function (response) {
                                if (response.data.success) {
                                    protocolSrv.signAndSend($stateParams, $scope.protocol, $scope.auth.certId).then(function (response) {
                                        if (response.data.success) {
                                            setTimeout(function () {
                                                _openProcedure();
                                            }, 1000);

                                        } else {
                                            $scope.alertObjAdditionalProtocol = alertSrv.getAlertObj(response);
                                        }
                                        $scope.ajaxActive = false;
                                    }, function (reason) {
                                        if (typeof reason === 'string') {
                                            $scope.alertObjAdditionalProtocol = alertSrv.getErrorMsgWithBtn(reason);
                                        } else {
                                            $scope.alertObjAdditionalProtocol = alertSrv.getAlertObj(reason);
                                        }
                                        $scope.ajaxActive = false;
                                    });
                                } else {
                                    $scope.alertObjAdditionalProtocol = alertSrv.getAlertObj(response);
                                }
                            })
                        }
                    } else {
                        $scope.ajaxActive = false;
                        $scope.alertObjAdditionalProtocol = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                    }
                }

                function changeStateFn(state) {
                    needConfirmSrv.call(doChangeStateFn,[state],'Выполнить смену редакции дополнительного протокола?');
                }
                function doChangeStateFn(state) {
                    $stateParams.protocolId = $scope.protocol.id;
                    protocolSrv.changeStateProtocolFn($stateParams, state).then(function (response) {
                           if (response.data.success) {
                           	$scope.alertObjAdditionalProtocol = alertSrv.getSuccessMsg('Статус протокола изменён');
                           	setTimeout(function () {
                                   _openProcedure();
                               }, 3000);
                           	$scope.ajaxActive = false;
                           } else {
                               $scope.alertObjAdditionalProtocol = alertSrv.getAlertObj(response);
                           }
                       })
                }
                
                function downloadFileFn(file) {
                    var url = '/fs/file/' + $scope.procedure.type.id.toLowerCase() + '/' + $scope.procedure.id + '/files/' + file.id;
                    httpSrv.downloadFile(url, file.filename);
                }

                function validateForm() {
                    if (_getForm().$invalid) {
                        $scope.submitted = true;
                        if (_getForm().$error.required) {
                            $scope.alertObjAdditionalProtocol = alertSrv.getErrorMsgWithBtn('Необходимо заполнить обязательные поля на форме. ', 'Перейти', _focusFirstInvalid, _getForm());
                        }
                        else {
                            var errorArr = Object.keys(_getForm().$error).length > 0 ? _getForm().$error[Object.keys($scope.protocolForm.form.$error)[0]] : [];
                            console.log(errorArr);
                            if (errorArr.length > 0 && errorArr[0].$$element && errorArr[0].$$element[0]) {
                                $scope.alertObjAdditionalProtocol = alertSrv.getErrorMsg(errorArr[0].$$element[0].title);
                            }
                        }
                        return false;
                    }
                    if (!$scope.protocol.document.attachment || $scope.protocol.document.attachment.length === 0) {
                        $scope.alertObjAdditionalProtocol = alertSrv.getErrorMsgWithBtn("Обратите внимание! Документ должен содержать вложения.");
                        return false;
                    }

                    return true;
                }
                
                /**
                 * Активация кнопок в зависимости от разрешнных действий сервера
                 * @param actions - действия которые можно выпонить с документом
                 * @private
                 */
                function _getBtnVisible(actions) {
                   if (actions && actions.length > 0) {
                        angular.forEach(actions, function (action) {
                            if (action.id.indexOf('manualIncorrectProtocol') >= 0) {
                                $scope.actionBtns.isManualIncorrectProtocol = true;
                            } else if (action.id.indexOf('manualCancelProtocol') >= 0) {
                                $scope.actionBtns.isManualCancelProtocol = true;
                            } else if (action.id.indexOf('manualPublishProtocol') >= 0) {
                                $scope.actionBtns.isManualPublishProtocol = true;
                            }
                        })
                    } 
                }
            }
        ]
    });

